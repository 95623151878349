import { useEffect, useMemo } from 'react';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '@stores/authentication.js';
import { Role } from '@system/acl';
import { PagePath } from '../pages-config.js';
import PageHeader from '@components/page-header/index.jsx';
import AnalyticsService from '@services/analytics/analytics.js';
import events from '@services/analytics/events.js';

// eslint-disable-next-line react-refresh/only-export-components
export const REPORTS_TYPES = {
  CHARTER_RESERVATIONS: {
    id: 'charter-reservations',
    name: 'Reservas de Fretamento',
    height: 800,
  },
  CHARTER_AVAILABILITY: {
    id: 'charter-availability',
    name: 'Disponibilidade de Fretamentos',
    height: 1300,
  },
};

export default function Relatorios() {
  const navigate = useNavigate();
  const { session } = useAuthentication();

  const isAdminOrFinance = [
    Role.Administrador,
    Role.Gestao,
    Role.Financeiro,
  ].includes(session.role);

  const AdminOnly = [Role.Administrador].includes(session.role);

  function seeReport(report) {
    AnalyticsService.trackEvent(events.REPORT_ROOMING_LIST_BUTTON_CLICKED);

    const { params, ...config } = report;

    const configQuery = Object.entries(config)
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    const paramsQuery = Object.entries(params || {})
      .map(([key, value]) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    let url = `${PagePath.Relatorio}?${configQuery}`;
    if (paramsQuery) url = `${url}&${paramsQuery}`;

    return navigate(encodeURI(url));
  }

  const reportSelector = useMemo(
    () => ({
      development: {
        finance: 37,
        buyer: 36,
        admin: 43,
      },
      staging: {
        finance: 37,
        buyer: 36,
        admin: 40,
      },
      production: {
        finance: 35,
        buyer: 34,
        admin: 41,
      },
      demo: {
        finance: 39,
        buyer: 38,
        admin: 43,
      },
    }),
    [],
  );

  const dashboardsConfig = useMemo(() => {
    if (!session?.role) return [];

    const reportEnv = reportSelector[import.meta.env.VITE_REPORT_ENV];

    const reports = {
      [REPORTS_TYPES.CHARTER_RESERVATIONS.id]: {
        id: isAdminOrFinance ? reportEnv?.finance : reportEnv?.buyer,
        type: 'dashboard',
        name: REPORTS_TYPES.CHARTER_RESERVATIONS.name,
        params: isAdminOrFinance ? {} : { contratante: session.agency.id },
      },
      [REPORTS_TYPES.CHARTER_AVAILABILITY.id]: {
        id: reportEnv?.admin,
        type: 'dashboard',
        name: REPORTS_TYPES.CHARTER_AVAILABILITY.name,
        params: AdminOnly ? {} : { contratante: session.agency.id },
      },
    };

    const dashboards = [reports[REPORTS_TYPES.CHARTER_RESERVATIONS.id]];

    if (AdminOnly) {
      dashboards.push(reports[REPORTS_TYPES.CHARTER_AVAILABILITY.id]);
    }

    return dashboards;
  }, [session, reportSelector, isAdminOrFinance, AdminOnly]);

  useEffect(() => {
    AnalyticsService.trackEvent(events.REPORT_ROOMING_LIST_PAGE_VIEWED);
  }, []);

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Análises - Relatórios', link: PagePath.Relatorios },
        ]}
        title="Relatórios"
      />
      <div className="contentAll">
        <div className="row">
          <div className="table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <th scope="col" colSpan={2}>
                    Nome
                  </th>
                </tr>
              </thead>
              <tbody>
                {dashboardsConfig.map((data) => (
                  <tr key={data.name} onClick={() => seeReport(data)}>
                    <td>{data.name}</td>
                    <td>
                      <BsEyeFill />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
