import { useLocation, useNavigate } from 'react-router-dom';
import { CheckoutBilling } from '@components/checkout/payment-method/billing';
import { CheckoutCreditCard } from '@components/checkout/payment-method/credit-card';
import { InputRadio } from '@components/input';
import { ButtonV2 } from '@components/new-components/button-v2';
import Tag from '@components/Tag';
import { useBookings } from '@stores/bookings';
import { useCreditAllotment } from '@stores/credit-allotment';
import { PaymentMethod } from '@system/enums';
import { PagePath } from '../pages-config';
import { useEffect } from 'react';

export default function AereoReservaPagamento() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { booking } = useBookings();
  const { insufficientCredit, paymentMethodsAllowed } = useCreditAllotment();

  useEffect(() => {
    useCreditAllotment.getState().methods.getPaymentMethods();
  }, []);

  const handlePaymentMethodChange = (e) => {
    useBookings.setState({ booking: { paymentMethod: e.target.value } });
  };

  const goToVPCard = () => {
    window.open('https://conteudo.viagenspromo.com/vp-pay', '_blank');
  };
  const onSubmit = (formData) => {
    if (
      booking?.paymentMethod == PaymentMethod.Faturamento &&
      insufficientCredit
    )
      return;
    let bookingInfo = useBookings.getState().booking;
    if (booking?.paymentMethod === PaymentMethod['Cartão de Crédito']) {
      bookingInfo = {
        ...bookingInfo,
        brandId: formData.brand,
        brandName: formData.brandInfo.name,
        installment: formData.installment,
        payerEmail: formData.payerEmail,
      };
    }
    useBookings.setState({ booking: bookingInfo });

    navigate(PagePath.AereoReservasConfirmar, {
      state,
    });
  };

  const goBack = () => {
    useBookings.setState({
      booking: {
        paymentMethod: paymentMethodsAllowed.creditCard
          ? PaymentMethod['Cartão de Crédito']
          : paymentMethodsAllowed.invoicing
            ? PaymentMethod.Faturamento
            : PaymentMethod['Cartão de Crédito'],
      },
    });
    navigate(-1);
  };
  const buttonSubmitProps = {
    [PaymentMethod['Cartão de Crédito']]: {
      form: 'form-credit-card',
      type: 'submit',
    },
    [PaymentMethod.Faturamento]: {
      type: 'button',
      onClick: onSubmit.bind(this, null),
    },
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col rounded-lg bg-white   sm:p-4">
        <div className=" flex w-full flex-col ">
          <span className="text-heading-2 text-primary">
            Como será o pagamento?
          </span>
          <span className="mt-1.5 text-body font-normal">
            Escolha abaixo a forma de pagamento desejada.
          </span>
        </div>
        <div className="mt-4 w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Formas de pagamento
          </div>
          <div className="mt-2 flex flex-col pt-3">
            <div className="flex shrink-0 flex-col items-start gap-x-3 border-0 border-b border-solid border-gray-300 pb-4">
              <InputRadio
                label="Cartão de Crédito cliente"
                name="billing"
                value={PaymentMethod['Cartão de Crédito']}
                checked={
                  booking?.paymentMethod === PaymentMethod['Cartão de Crédito']
                }
                onChange={handlePaymentMethodChange}
                disabled={!paymentMethodsAllowed.creditCard}
              />
              <small className="ml-8 text-small font-normal text-neutral-600">
                Via link de pagamento
              </small>
            </div>
            <div className="flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
              <InputRadio
                label="Faturamento"
                name="billing"
                value={PaymentMethod.Faturamento}
                checked={booking?.paymentMethod === PaymentMethod.Faturamento}
                onChange={handlePaymentMethodChange}
                disabled={!paymentMethodsAllowed.invoicing}
              />
            </div>

            <div className=" flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
              <div className="flex flex-row items-center justify-center gap-x-3">
                <InputRadio
                  label="VP Card"
                  name="billing"
                  value={PaymentMethod.VPCard}
                  disabled
                />
                <Tag value="Em breve" type="soon" />
              </div>
              <div className="relative ml-4 inline-flex">
                <ButtonV2 ghost onClick={goToVPCard}>
                  <ButtonV2.Text>Solicite agora o seu</ButtonV2.Text>
                </ButtonV2>
              </div>
            </div>
            <div className="flex shrink-0 flex-row items-center gap-x-3 border-0 border-b border-solid border-gray-300 py-4">
              <InputRadio
                label="Pix"
                name="billing"
                value={PaymentMethod.Pix}
                disabled
              />
              <Tag value="Em breve" type="soon" />
            </div>
            <div className="flex shrink-0 flex-row items-center gap-x-3 pt-4">
              <InputRadio
                label="Pix Agendado"
                name="billing"
                value={PaymentMethod['Pix Agendado']}
                disabled
              />
              <Tag value="Em breve" type="soon" />
            </div>
          </div>
        </div>
        {booking?.paymentMethod === PaymentMethod.Faturamento && (
          <CheckoutBilling />
        )}
        {booking?.paymentMethod === PaymentMethod['Cartão de Crédito'] && (
          <CheckoutCreditCard onSubmit={onSubmit} />
        )}
      </div>
      <div className="mt-4 flex w-full flex-col ">
        <div className="flex flex-row justify-between">
          <ButtonV2
            type="button"
            ghost
            size="large"
            className="w-2/12"
            onClick={goBack}
          >
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>
          <ButtonV2
            size="large"
            className="w-2/12"
            disabled={!booking?.paymentMethod}
            {...buttonSubmitProps[booking?.paymentMethod]}
          >
            <ButtonV2.Text>Continuar</ButtonV2.Text>
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
