import { Button } from '@components/button';
import { Modal } from '@components/modal';
import { PagePath } from '../../pages-config';
import { useNavigate } from 'react-router-dom';

const modalContentConfirmation = {
  cancelableWithoutFeeAndRefund: (bookingCode) => ({
    title: `Cancelamento da reserva ${bookingCode} confirmado!`,
    message:
      'A solicitação de cancelamento foi realizada e a confirmação foi enviada para o e-mail cadastrado',
    buttonLabel: 'Ir para Multas e Reembolsos',
  }),
  cancelableWithFee: (bookingCode) => ({
    title: `Cancelamento da reserva ${bookingCode} confirmado!`,
    message:
      'A análise do valor adicional foi para o nosso setor responsável e será enviado um e-mail assim que concluída. Você também pode acompanhar o processo na página de <b style="font-weight:600">Multas e Reembolsos</b>.',
    buttonLabel: 'Ir para Multas e Reembolsos',
  }),
  cancelableWithRefund: (bookingCode) => ({
    title: `Cancelamento da reserva ${bookingCode} confirmado!`,
    message:
      'A análise dos valores foi para o nosso setor responsável e será enviado um e-mail assim que concluída. Você também pode acompanhar o processo na página de <b style="font-weight:600">Multas e Reembolsos</b>.',
    buttonLabel: 'Ir para Multas e Reembolsos',
  }),
  cancelableWithFeeAndRefund: (bookingCode) => ({
    title: `Cancelamento da reserva ${bookingCode} confirmado!`,
    message: 'Deseja continuar com o cancelamento?',
    buttonLabel: 'Ir para Multas e Reembolsos',
  }),
  default: {
    title: 'Erro',
    message: 'Status desconhecido.',
    closeButtonLabel: 'Fechar',
  },
};

export function ModalConfirmCancelReservation({
  open,
  handleClose,
  status,
  bookingCode,
}) {
  const navigate = useNavigate();
  const modalDataConfirmation =
    typeof modalContentConfirmation[status] === 'function'
      ? modalContentConfirmation[status](bookingCode)
      : modalContentConfirmation[status] || modalContentConfirmation.default;

  const {
    title,
    message,
    buttonLabel,
    closeButtonLabel = 'Fechar',
  } = modalDataConfirmation;

  const handleFeeAndRefund = () => {
    navigate(PagePath.FinanceiroMultasReembolsos);
    handleClose();
  };

  return (
    <Modal
      customPadding="py-[60px] px-[60px]"
      customGap="gap-4"
      open={open}
      handleClose={handleClose}
    >
      <div className="flex flex-col items-center gap-4 text-center">
        <h1
          className={`mb-0 text-success-500`}
          style={{ fontSize: '24px', lineHeight: '30px', fontWeight: 600 }}
        >
          {title}
        </h1>
      </div>
      <p
        className="m-0 w-1/2 text-center text-neutral-800"
        style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400 }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>

      <div className="mt-7 flex items-center gap-4">
        <Button
          label={closeButtonLabel}
          variant="dangerOutline"
          onClick={handleClose}
        />
        {buttonLabel && (
          <Button
            label={buttonLabel}
            variant="danger"
            onClick={handleFeeAndRefund}
          />
        )}
      </div>
    </Modal>
  );
}
