import { differenceInDays } from 'date-fns';
import { Button } from '@components/button';
import { formatToBRTz } from '@system/utils';
import { CalendarType } from './calendar-type.enum';

export const CalendarFooter = ({
  interval,
  months,
  resumeType = CalendarType.Monthly,
  disableResume,
  disabled,
  onReset,
  onApply,
}) => {
  function formatDate(date) {
    return formatToBRTz(date, `EEEE, d 'de' MMMM`);
  }

  function makeResume() {
    if (resumeType === CalendarType.Monthly && disableResume === false)
      return makeIntervalResume();
    return makeMonthsResume();
  }

  function makeIntervalResume() {
    const totalDays = differenceInDays(interval.end, interval.start) || 0;
    const totalDaysText = `${totalDays} noites`;

    let intervalText = '';
    if (interval.start) intervalText += ` - ${formatDate(interval.start)}`;
    if (interval.end) intervalText += ` à ${formatDate(interval.end)}`;

    return (
      <>
        <span className="font-semibold">{totalDaysText}</span>
        <span>{intervalText}</span>
      </>
    );
  }

  function makeMonthsResume() {
    return months.map((m) => formatToBRTz(m, 'MMMM/yyyy')).join(', ');
  }

  return (
    <div className="px-5">
      <div className="flex justify-between border-0 border-t border-solid border-neutral-300 py-4">
        <div className="inline-flex items-center">
          <p className="m-0 text-small">{!disableResume && makeResume()}</p>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            variant="smallLink"
            label="Apagar"
            disabled={disabled}
            onClick={onReset}
          />
          <Button
            variant="small"
            label="Aplicar"
            disabled={disabled}
            onClick={onApply}
          />
        </div>
      </div>
    </div>
  );
};
