import { Link } from 'react-router-dom';

import { PagePath } from '@/pages-config';
import Logo from '@components/Logo';

const HeaderCheckout = () => {
  return (
    <header className="border-0 border-b-2 border-solid border-[#DCDCDC] px-6 py-4">
      <Link to={PagePath.Home}>
        <div className="max-w-[170px]">
          <Logo />
        </div>
      </Link>
    </header>
  );
};

export default HeaderCheckout;
