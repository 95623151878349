import { tv } from 'tailwind-variants';

export const styles = {
  container: tv({
    base: `
      w-full
      h-[calc(100vh-40px)]
      [overflow:overlay]
      bg-white
      absolute
      top-[40px]
      left-0
      z-10
      hidden
      lg:block
      lg:relative
      lg:top-0
      lg:h-auto
      lg:[overflow:visible]
    `,
    variants: {
      isMenuOpen: {
        true: `
          block
        `,
      },
    },
  }),
  userInfoContainer: tv({
    base: `
      lg:hidden
    `,
  }),
  searchContainer: tv({
    base: `
      w-full
      flex
      justify-end
      items-center
      p-4
      border-b
      border-t-[0px]
      border-l-[0px]
      border-r-[0px]
      border-solid
      border-neutral-300
      lg:hidden
    `,
  }),
  menuContainer: tv({
    base: `
      w-full
      lg:flex
      lg:flex-row
      lg:h-[60px]
      lg:items-center
      lg:border-t
      lg:border-l-[0px]
      lg:border-r-[0px]
      lg:border-b-[0px]
      lg:border-solid
      lg:border-neutral-300
      lg:flex-reverse
      lg:px-4
    `,
  }),
  menuButtonAndOptions: tv({
    base: `
      lg:flex
      lg:flex-row
      lg:gap-5
    `,
  }),
  menuButtonContainer: tv({
    base: `
      px-5
      pt-5
      lg:p-0
      lg:order-1
    `,
  }),
  menuOptionsContainer: tv({
    base: `
      flex
      flex-col
      gap-[22px]
      pb-[26px]
      border-b
      border-t-[0px]
      border-l-[0px]
      border-r-[0px]
      border-solid
      border-neutral-300
      lg:flex-row
      lg:border-b-0
      lg:pb-0
      lg:order-0
    `,
  }),
  menuOption: tv({
    base: `
      text-neutral-800
      font-bold
      text-sm
      [list-style-type:none]
      px-5
      m-0
      flex
      flex-col
      lg:flex-row
      lg:px-0
      lg:gap-5
    `,
  }),
  menuOptionItem: tv({
    base: `
      pt-[22px]
      flex
      gap-2
      items-center
      lg:pt-0
      [user-select:none]
    `,
    variants: {
      isActive: {
        true: `
          text-[#E41047]
        `,
      },
    },
  }),
  menuSubOption: tv({
    base: `
      bg-white
      [list-style-type:none]
      pl-[20px]
      pt-[2px]
      flex
      flex-col
      lg:absolute
      lg:top-[40px]
      lg:left-0
      lg:px-[10px]
      lg:py-5
      lg:gap-3
      lg:min-w-[300px]
      lg:rounded-md
      lg:shadow-header
    `,
    variants: {
      hasSubItems: {
        true: `
          gap-5
          lg:flex-row
          lg:gap-[24px]
        `,
      },
    },
  }),
  menuSubOptionItem: tv({
    base: `
      text-neutral-800
      font-normal
      text-sm
      [text-align:left]
    `,
    variants: {
      hasSubItems: {
        true: `
          font-bold
          lg:min-w-[190px]
          lg:pl-[10px]
          lg:pb-[10px]
        `,
      },
    },
  }),
  menuSubOptionItemLi: tv({
    base: `
      pt-[10px]
      lg:pt-0
      lg:h-[32px]
      lg:hover:bg-secondary-100
      lg:rounded-md
      lg:px-[10px]
      lg:flex
      lg:items-center
    `,
  }),
  menuSubSubOption: tv({
    base: `
      [list-style-type:none]
      pl-[0px]
    `,
  }),
  menuSubSubOptionItem: tv({
    base: `
      text-neutral-800
      text-sm
      font-normal
      [text-align:left]
      pt-[10px]
    `,
  }),
  menuSubSubOptionItemLi: tv({
    base: `
      pt-[10px]
      lg:pt-0
      lg:h-[32px]
      lg:hover:bg-secondary-100
      lg:rounded-md
      lg:px-[10px]
      lg:flex
      lg:items-center
    `,
  }),
  menuUserDataContainer: tv({
    base: `
      lg:hidden
    `,
  }),
};
