import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  differenceInMonths,
  isBefore,
  isValid,
  parse,
  parseISO,
  subMonths,
  subYears,
} from 'date-fns';
import { format } from 'date-fns-tz';
import { produce } from 'immer';
import { mask, unMask } from 'remask';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { useBookings } from '@stores/bookings';
import { PassengerType } from '@system/enums';
import { SeatDetail } from './seat-detail';
yup.setLocale(pt);

const defaultValues = {
  name: null,
  lastName: null,
  gender: null,
  birthdate: null,
  type: null,
  document: null,
  phone: null,
  email: null,
  note: null,
};

export const FormPassenger = ({ onSubmit }) => {
  const { passengerType, traveler } = useBookings();

  const validation = yup.object().shape({
    name: yup.string().required().label('Nome do passageiro'),
    lastName: yup.string().required().label('Sobrenome do passageiro'),
    gender: yup.string().required().label('Sexo'),
    birthdate: yup
      .string()
      .required()
      .label('Data de nascimento')
      .test(
        'is-older-than-23-months',
        function () {
          return passengerType === PassengerType.PassengerBaby
            ? 'Esse passageiro não é um bebê'
            : 'Esse passageiro não pode ser um bebê';
        },
        function (value) {
          const birthdate = parseISO(value);
          if (!isValid(birthdate)) return false;

          const now = new Date();
          const ageInMonths = differenceInMonths(now, birthdate);

          if (passengerType === PassengerType.PassengerBaby) {
            return ageInMonths < 23;
          } else {
            return ageInMonths > 23;
          }
        },
      )
      .test(
        'is-less-than-100-years',
        'Por favor, selecione uma data dentro dos últimos 100 anos.',
        function (value) {
          const birthdate = parseISO(value);
          if (!isValid(birthdate)) {
            return false;
          }
          const now = new Date();
          const hundredYearsAgo = subYears(now, 100);
          return birthdate >= hundredYearsAgo;
        },
      )
      .test(
        'is-before-today',
        'A data deve ser menor ou igual a hoje',
        function (value) {
          const birthdate = parseISO(value);
          if (!isValid(birthdate)) {
            return false;
          }
          const now = new Date();
          return (
            isBefore(birthdate, now) ||
            (isValid(birthdate) &&
              now.getFullYear() === birthdate.getFullYear())
          );
        },
      ),
    type: yup.string().required().label('Tipo'),
    document: yup.string().required().label('Número de CPF'),
    phone: yup.string().required().label('Telefone'),
    email: yup
      .string()
      .required()
      .email()
      .label('E-mail')
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'E-mail deve ser no formato exemplo@exemplo.com',
      ),
    note: yup.string().optional().nullable().label('Observações'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validation),
    defaultValues,
  });

  const type = watch('type');

  const today =
    passengerType === PassengerType.PassengerBaby
      ? format(new Date(), 'yyyy-MM-dd')
      : format(subMonths(new Date(), 23), 'yyyy-MM-dd');
  const hundredYearsAgo = subYears(new Date(), 100);

  const changeBirthdate = (e) => {
    const d2 = parse(e.target.value, 'yyyy-MM-dd', new Date());
    const d1 = new Date();

    const diffMonths = differenceInMonths(d1, d2);
    let type = '';
    if (diffMonths < 23) {
      type = 'INF';
    } else if (diffMonths >= 23 && diffMonths < 133) {
      type = 'CHD';
    } else if (diffMonths >= 134) {
      type = 'ADT';
    }

    if (passengerType === PassengerType.PassengerBaby) {
      setValue('type', 'INF');
    } else {
      setValue('type', type);
    }
    setValue('birthdate', e.target.value, { shouldValidate: true });
  };

  const formatDocument = (e) => {
    const value = e.target.value;
    let clearMask = unMask(value);
    e.target.value = mask(clearMask, ['999.999.999-99']);
  };

  const formatPhone = (e) => {
    const value = e.target.value;
    let clearMask = unMask(value);
    e.target.value = mask(clearMask, ['', '(', '(99', '(99) 99999-9999']);
  };

  const changeState = (draft) => {
    if (traveler.type === 'ADT' && traveler.children !== null) {
      const nextAvailableAdultIndex = draft.travelers.findIndex((t) => {
        return t.type === 'ADT' && !t.children && t.id !== traveler.id;
      });

      if (nextAvailableAdultIndex !== -1) {
        const nextAvailableAdult = draft.travelers[nextAvailableAdultIndex];
        const travelerToUpdate = draft.travelers.find((t) => {
          return t.id === traveler.children.id;
        });
        travelerToUpdate.parentId = nextAvailableAdult.id;
        nextAvailableAdult.children = travelerToUpdate;
      } else {
        draft.travelers = draft.travelers.filter((t) => {
          return t.id !== traveler.id && t.id !== traveler.children.id;
        });
      }
    }

    if (traveler.type === 'INF') {
      const travelerToUpdate = draft.travelers.find((t) => {
        return t.id === traveler.parentId;
      });
      travelerToUpdate.children = null;
    }

    draft.travelers = draft.travelers.filter((t) => t.id !== traveler.id);
    draft.modal.createTravelerVisible = false;
    draft.seat = null;
    draft.traveler = null;
  };

  const deleteTravelerById = () => {
    if (!traveler) return;
    useBookings.setState(produce(changeState));
  };

  useEffect(() => {
    if (passengerType === PassengerType.PassengerBaby) setValue('type', 'INF');
  }, [passengerType, setValue]);

  useEffect(() => {
    if (traveler) {
      reset(traveler);
    }
  }, [traveler, reset]);

  return (
    <>
      <Form
        className="grid w-full grid-cols-1 gap-x-4 sm:grid-cols-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-span-12 grid w-full grid-cols-1 gap-4 lg:grid-cols-12 notebook:col-span-9 desktop:col-span-8">
          <Form.Group className="col-span-12 lg:col-span-6">
            <Form.Label>Nome</Form.Label>
            <InputV2
              {...register('name')}
              placeholder="Insira o nome do passageiro"
            />
            <Form.Message error={errors?.name} />
          </Form.Group>
          <Form.Group className="col-span-12 lg:col-span-6">
            <Form.Label>Último sobrenome</Form.Label>
            <InputV2
              {...register('lastName')}
              placeholder="Insira o sobrenome do passageiro"
            />
            <Form.Message error={errors?.lastName} />
          </Form.Group>

          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>Sexo</Form.Label>
            <select
              label="Sexo"
              className="flex h-12 w-full items-center justify-between rounded-md border border-neutral-600 bg-transparent px-2 py-0  text-sm font-normal placeholder:italic"
              {...register('gender')}
              placeholder="Escolha"
            >
              <option value="">Selecione</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
            <Form.Message error={errors?.gender} />
          </Form.Group>
          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>Data de Nascimento</Form.Label>
            <InputV2
              {...register('birthdate')}
              type="date"
              placeholder="Insira o sobrenome do passageiro"
              max={today}
              min={format(hundredYearsAgo, 'yyyy-MM-dd')}
              onChange={changeBirthdate}
            />
            <Form.Message error={errors?.birthdate} />
          </Form.Group>
          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>Tipo</Form.Label>
            <InputV2 {...register('type')} disabled />
            <Form.Message error={errors?.type} />
          </Form.Group>

          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>Número do CPF</Form.Label>
            <InputV2
              {...register('document')}
              placeholder="Insira o número do CPF"
              onChange={formatDocument}
            />
            <Form.Message error={errors?.document} />
          </Form.Group>

          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>Telefone</Form.Label>
            <InputV2
              {...register('phone')}
              placeholder="Insira o telefone"
              onChange={formatPhone}
            />
            <Form.Message error={errors?.phone} />
          </Form.Group>

          <Form.Group className="col-span-12 lg:col-span-4">
            <Form.Label>E-mail</Form.Label>
            <InputV2
              {...register('email')}
              placeholder="Insira o e-mail do passageiro"
            />
            <Form.Message error={errors?.email} />
          </Form.Group>

          <Form.Group className="col-span-12 lg:col-span-12">
            <Form.Label>Observações</Form.Label>
            <InputV2
              {...register('note')}
              placeholder="Caso queira deixar alguma observação, insira aqui"
            />
            <Form.Message error={errors?.note} />
          </Form.Group>

          <Form.Group
            className={`col-span-12 flex flex-row items-center ${traveler ? 'justify-between' : 'justify-center'} lg:col-span-12`}
          >
            {traveler && (
              <ButtonV2
                size="large"
                className="desktop:w-6/8 w-4/6 tablet:w-3/6 notebook:w-2/6"
                ghost
                onClick={deleteTravelerById}
                type="button"
              >
                <ButtonV2.Text>Excluir Passageiro</ButtonV2.Text>
              </ButtonV2>
            )}
            <ButtonV2 size="large" className="w-1/4">
              <ButtonV2.Text>Salvar</ButtonV2.Text>
            </ButtonV2>
          </Form.Group>
        </div>
        <SeatDetail type={type} />
      </Form>
    </>
  );
};
