export const SELECT_OPTIONS_ADMIN = [
  { value: 0, label: 'Operacional' },
  { value: 1, label: 'Financeiro' },
  { value: 2, label: 'Produtos' },
  { value: 3, label: 'Comprador' },
  { value: 9, label: 'Gestão' },
  { value: 99, label: 'Administrador' },
  { value: 100, label: 'Comprador Administrador' },
];

export const SELECT_OPTIONS_BUYER_ADMIN = [
  { value: 3, label: 'Comprador' },
  { value: 100, label: 'Comprador Administrador' },
];

export const ROLES = {
  0: 'operacional',
  1: 'financeiro',
  2: 'produtos',
  3: 'comprador',
  9: 'gestao',
  99: 'administrador',
  100: 'comprador-administrador',
};
