import { CancellationRequestStatus } from '@system/enums';
export const convertCancellationRequestStatusColor = (key) => {
  const converter = {
    [CancellationRequestStatus.canceled]: 'bg-[#FFEBE8] text-[#A43424]',
    [CancellationRequestStatus.confirmed]: 'bg-[#E6FBF4] text-[#007D6D]',
    [CancellationRequestStatus.review]: 'bg-[#FFFAE6] text-[#A08B00]',
    [CancellationRequestStatus.pending]: 'bg-warning-100 text-warning-500',
    [CancellationRequestStatus.awaiting]: 'bg-warning-100 text-warning-500',
  };
  return converter[key] ?? '';
};
