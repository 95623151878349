import { tv } from 'tailwind-variants';

const buttonText = tv({
  base: 'text-body font-semibold text-center text-white relative z-[2] !m-0 !p-0',
  variants: {
    size: {
      medium: 'text-sm',
      large: 'text-heading-3',
    },
    ghost: {
      true: '!text-secondary',
    },
    link: {
      true: 'text-secondary hover:text-secondary-900',
    },
    disabled: {
      true: '!text-neutral-600',
    },
  },
  compoundVariants: [
    {
      ghost: true,
      disabled: true,
      link: true,
      size: ['medium', 'large'],
      class: 'text-neutral-600 !text-secondary',
    },
    {
      ghost: true,
      disabled: false,
      link: false,
      size: ['medium', 'large'],
      class: '!text-secondary',
    },
    {
      ghost: false,
      disabled: false,
      link: true,
      size: ['medium', 'large'],
      class: 'text-secondary',
    },
    {
      ghost: false,
      disabled: false,
      link: false,
      size: ['medium', 'large'],
      class: 'text-white',
    },
  ],
});

export const ButtonText = ({
  children,
  disabled = false,
  ghost = false,
  size = 'medium',
  link = false,
  ...props
}) => {
  return (
    <div
      className={buttonText({
        disabled,
        ghost,
        size,
        link,
        class: props?.className,
      })}
    >
      {children}
    </div>
  );
};
