import { useData } from '@stores/checkout-context';
import { formatCurrency } from '@system/utils';

export const FineTotalizer = () => {
  const { data } = useData();

  let { totalWithoutFee = 0, totalWithFee = 0 } = data;
  totalWithFee = totalWithFee - totalWithoutFee;
  const total = totalWithoutFee + totalWithFee;

  return (
    <div className="mt-4 w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
      <ul className="list-none space-y-1.5 px-0">
        <li className="pb-3 text-body font-normal text-neutral-800">
          Multa de cancelamento:
          <span className="ml-0.5 text-body font-semibold text-price">
            {formatCurrency(totalWithoutFee, true)}
          </span>
        </li>

        <li className="pb-3 text-body font-normal text-neutral-800">
          Acréscimos do juros do parcelamento:
          <span className="ml-0.5 text-body font-semibold text-price">
            {formatCurrency(totalWithFee, true)}
          </span>
        </li>

        <li className="border-0 border-t border-solid border-neutral-600 pt-3 text-heading-3 font-semibold text-neutral-800">
          Valor total da multa de cancelamento:
          <span className="ml-0.5 font-semibold text-price">
            {formatCurrency(total, true)}
          </span>
        </li>
      </ul>
    </div>
  );
};
