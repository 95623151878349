import { GoCheckCircle } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/button';
import { useAuthentication } from '@stores/authentication';
import { Modal } from '.';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

export function ModalPerfilConfirm() {
  const { methods } = useAuthentication();
  const navigate = useNavigate();

  let open = true;
  const onClose = () => {
    AnalyticsService.trackEvent(events.MY_PROFILE_PAGE_VIEWED);

    open = false;
    methods.signOut();
    navigate('/', {
      state: {
        from: window.location.href,
      },
    });
  };

  return (
    <Modal open={open}>
      <div className="m-0 flex flex-col items-center gap-3 py-[30px]">
        <GoCheckCircle size={40} className="text-price" />
        <span className="m-0 text-center text-heading-1 text-price">
          Dados atualizados com sucesso!
        </span>
        <p className="m-0 mb-[30px] mt-[15px] text-center">
          Suas informações foram atualizadas. <br /> Para continuar, faça login
          novamente no sistema.
        </p>
        <Button onClick={onClose} variant="ghost" label="Ir para login" />
      </div>
    </Modal>
  );
}
