import { useState } from 'react';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { useBookings } from '@stores/bookings';
import { convertFlightClassCategory } from '@system/converters/convertFlightClassCategory';
import { formatCurrency } from '@system/utils';

const FlightPriceDetailListItem = ({ data }) => {
  return (
    <li className="w-full rounded-md !border border-solid border-neutral-300 p-3">
      <span className="flex w-full flex-col border-0 border-b border-solid border-neutral-300 pb-2 text-small font-semibold text-primary">
        {convertFlightClassCategory(data.type)}
      </span>
      <div className="flex justify-between pt-2">
        <span className="text-small font-semibold text-neutral-800">
          ADT:{' '}
          <span className="font-medium text-price">
            {formatCurrency(data?.adt ?? 0, true)}
          </span>
        </span>
        <span className="text-small font-semibold text-neutral-800">
          CHD:{' '}
          <span className="font-medium text-price">
            {formatCurrency(data?.chd ?? 0, true)}
          </span>
        </span>
        <span className="text-small font-semibold text-neutral-800">
          INF:{' '}
          <span className="font-medium text-price">
            {formatCurrency(data?.inf ?? 0, true)}
          </span>
        </span>
      </div>
    </li>
  );
};

const FlightPriceDetailList = ({ data = [] }) => {
  return (
    <ul className="m-0 flex w-full list-none flex-col content-start  items-start justify-start gap-y-4 p-0 pt-4">
      {data?.map((item) => (
        <FlightPriceDetailListItem data={item} key={item.type} />
      ))}
    </ul>
  );
};

export const FlightPriceDetail = () => {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);
  const { tablePrice } = useBookings();

  function transformData(data) {
    const transformed = [];

    for (const [type, prices] of Object.entries(data)) {
      const entry = {
        adt: parseFloat(prices.adt) || 0,
        chd: parseFloat(prices.chd) || 0,
        inf: parseFloat(prices.inf) || 0,
        type: type,
      };
      transformed.push(entry);
    }

    return transformed;
  }

  const result = tablePrice ? transformData(tablePrice) : [];

  return (
    <div className="flex flex-col rounded-lg bg-white p-4">
      <div
        className="flex cursor-pointer select-none flex-row items-center justify-between border-0 !border-b border-solid border-neutral-300 pb-3"
        onClick={toggle}
      >
        <span className="text-heading-3 text-primary">Preços</span>
        {collapse ? (
          <PiCaretDownBold className="text-primary" size="1.125rem" />
        ) : (
          <PiCaretUpBold className="text-primary" size="1.125rem" />
        )}
      </div>

      {!collapse && <FlightPriceDetailList data={result} />}
    </div>
  );
};
