import {
  MdOutlineConnectingAirports,
  MdOutlineAirlineSeatReclineNormal,
  MdOutlineHotelClass,
  MdOutlineEmojiFoodBeverage,
} from 'react-icons/md';
import { PiUsers, PiBuildingsBold } from 'react-icons/pi';
import { IoBusinessSharp } from 'react-icons/io5';
import { AiOutlineFlag } from 'react-icons/ai';
import { TbReportMoney } from 'react-icons/tb';
import { Menu } from '../components/menu/menu';
import { PagePath } from '../pages-config';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import PageHeader from '@components/page-header';

export default function Admin() {
  const { session } = useAuthentication();

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
        ]}
      />
      {session.role === Role.Administrador && (
        <Menu submenu className={'pt-4'}>
          <Menu.Item
            to={PagePath.AdminRegrasTarifarias}
            title="Base Regras"
            icon={<TbReportMoney className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminEmpresas}
            title="Empresas"
            icon={<IoBusinessSharp className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminCompanhias}
            title="Cias. Aéreas"
            icon={<AiOutlineFlag className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminAeroportos}
            title="Aeroportos"
            icon={<MdOutlineConnectingAirports className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminAssentos}
            title="Caract. Assentos"
            icon={
              <MdOutlineAirlineSeatReclineNormal className={'self-start'} />
            }
          />
          <Menu.Item
            to={PagePath.AdminClasses}
            title="Caract. Classes"
            icon={<MdOutlineHotelClass className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminFabricantes}
            title="Fabricantes"
            icon={<PiBuildingsBold className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminServicosBordo}
            title="Serviço de Bordo"
            icon={<MdOutlineEmojiFoodBeverage className={'self-start'} />}
          />
          <Menu.Item
            to={PagePath.AdminUsuarios}
            title="Usuários"
            icon={<PiUsers className={'self-start'} />}
          />
        </Menu>
      )}
      {session.role === Role.CompradorAdministrador && (
        <Menu submenu className={'pt-4'}>
          <Menu.Item
            to={PagePath.AdminUsuarios}
            title="Usuários"
            icon={<PiUsers className={'self-start'} />}
          />
        </Menu>
      )}
    </>
  );
}
