import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsSuitcase2Fill } from 'react-icons/bs';
import { IoAirplaneOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@components/button';
import { ModalCancelChangeRules } from '@components/modal/ModalCancelChangeRules';
import Tag from '@components/Tag';
import {
  formatBaggage,
  formatDate,
  formatTime,
  formatCityAirport,
  formatAirportScale,
  formatScale,
} from '@system/utils';
import { PagePath } from '../../../pages-config';

export function FlightDetailsCard({
  airports,
  onClick,
  className,
  showPriceButton,
  showEditButton,
  collapsible,
  id,
  departureData,
  returnData,
  baggage,
  cancelRules,
  changeRules,
}) {
  const [contentToggle, setContentToggle] = useState(true);
  const [modal, setModal] = useState(false);

  return (
    <>
      <ModalCancelChangeRules
        isOpen={modal}
        onClose={() => setModal(false)}
        cancelRules={cancelRules}
        changeRules={changeRules}
      />
      <div
        className={`flex w-full flex-col gap-5 rounded-md border border-solid border-[#EDEDED] bg-white p-5 shadow-md ${className} ${onClick && 'cursor-pointer'}`}
        onClick={onClick}
      >
        <header className="flex justify-between border border-x-0 border-y-0 border-b border-solid border-[#EBEBEB] pb-4">
          <div className="flex flex-col gap-[1px]">
            <span className="text-base font-semibold text-primary">
              {formatCityAirport(departureData.origin, airports)} -{' '}
              {formatCityAirport(departureData.destination, airports)}
            </span>
            <span className="text-sm font-normal text-neutral-700">
              {formatDate(departureData.originDate)}{' '}
              {returnData.origin && `a ${formatDate(returnData.originDate)}`}
            </span>
          </div>
          {collapsible ? (
            <div
              className="h-8 w-8 cursor-pointer"
              onClick={() => setContentToggle(!contentToggle)}
            >
              {contentToggle ? (
                <BiChevronUp color="black" size={24} />
              ) : (
                <BiChevronDown color="black" size={24} />
              )}
            </div>
          ) : (
            <div className="flex gap-4">
              {showEditButton && (
                <Link to={PagePath.AereoSaidasEditar.replace(':id', id)}>
                  <Button label="Editar" variant="ghost" />
                </Link>
              )}

              {showPriceButton && (
                <Link to={PagePath.AereoSaidasPrecificar.replace(':id', id)}>
                  <Button label="Precificar" variant="ghost" />
                </Link>
              )}
            </div>
          )}
        </header>

        <div
          className={`flex max-sm:flex-col max-sm:items-start ${contentToggle ? ' ' : ' hidden'}`}
        >
          <div className="grid w-full grid-cols-2 gap-3 max-sm:flex max-sm:flex-col">
            <div className="flex w-full flex-col gap-[11px]">
              <Tag
                value={
                  <div className="flex items-center gap-1">
                    <IoAirplaneOutline />{' '}
                    {returnData.origin ? 'Ida: ' : 'Trecho'}{' '}
                    {returnData.origin
                      ? moment(departureData.originDate).format('DD/MM/YY')
                      : ''}
                  </div>
                }
              />

              <div className="flex flex-col gap-1">
                <div className="flex w-full items-center gap-2">
                  <img
                    className="w-[31px]"
                    src={departureData.companyLogo}
                    alt=""
                  />
                  {departureData.operator && (
                    <span className="text-small text-neutral-800">
                      Operado por {departureData.operator} - Voo{' '}
                      {departureData.flight}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex w-full gap-4">
                    <div className="flex flex-col">
                      <span className="text-base">
                        <b className="font-semibold text-primary">
                          {departureData.origin}
                        </b>{' '}
                        {formatTime(departureData.originTime)?.replace('h', '')}
                      </span>
                      <span className="text-xs font-normal text-gray-700">
                        {formatCityAirport(departureData.origin, airports)}
                      </span>
                    </div>
                    <div className="h-full">
                      <div className="">
                        <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
                          {formatScale(
                            departureData.layovers?.length ?? 0,
                            departureData.connections?.length ?? 0,
                          )}
                          <hr className="mt-1 w-[60px] border-neutral-700" />
                        </span>
                        <div className="flex items-center justify-center gap-1">
                          {Array.from({
                            length: formatAirportScale(
                              0, // r1conexaoaeroporto
                              0, // r1escalaorigem,
                              0, // r1escaladestino,
                              departureData.connections?.length ?? 0,
                              departureData.layovers?.length ?? 0,
                            ),
                          }).map((_, index) => (
                            <div
                              key={index}
                              className="z-0 -mt-[33px] h-2 w-2 rounded-full !border !border-solid !border-neutral-700 bg-white"
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-base text-primary">
                        <b className="font-semibold">
                          {departureData.destination}
                        </b>{' '}
                        {formatTime(departureData.destinationTime)?.replace(
                          'h',
                          '',
                        )}
                      </span>
                      <span className="text-xs font-normal text-gray-700">
                        {formatCityAirport(departureData.destination, airports)}
                      </span>
                    </div>
                  </div>
                  <div className="mt-[11px] flex items-center gap-1 text-primary">
                    <BsSuitcase2Fill height={17} />
                    <span className="text-xs font-normal text-neutral-700">
                      Bagagem para despachar: {formatBaggage(baggage)}
                    </span>
                  </div>
                  {(!!cancelRules || !!changeRules) && (
                    <div className="mt-5">
                      <span
                        role="button"
                        className="select-none text-sm font-semibold text-secondary"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Ver regras de alteração e cancelamento
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {returnData.origin && (
              <div className="flex w-max flex-col gap-[11px]">
                <Tag
                  value={
                    <div className="flex items-center gap-1">
                      <IoAirplaneOutline className="rotate-180" /> Volta:{' '}
                      {moment(returnData.originDate).format('DD/MM/YY')}
                    </div>
                  }
                />

                <div className="flex flex-col gap-1">
                  <div className="flex w-full items-center gap-2">
                    <img className="w-8" src={returnData.companyLogo} alt="" />
                    {returnData.operator && (
                      <span className="text-small text-neutral-800">
                        Operado por {returnData.operator} - Voo{' '}
                        {returnData.flight}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex w-full gap-4">
                      <div className="flex flex-col">
                        <span className="text-base text-primary">
                          <b className="font-semibold">{returnData.origin}</b>{' '}
                          {formatTime(returnData.originTime)?.replace('h', '')}
                        </span>
                        <span className="text-xs font-normal text-gray-700">
                          {formatCityAirport(returnData.origin, airports)}
                        </span>
                      </div>
                      <div className="h-full">
                        <div className="">
                          <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
                            {formatScale(
                              returnData.layovers?.length ?? 0,
                              returnData.connections?.length ?? 0,
                            )}
                            <hr className="mt-1 w-[60px] border-neutral-700" />
                          </span>
                          <div className="flex items-center justify-center gap-1">
                            {Array.from({
                              length: formatAirportScale(
                                0, // r2conexaoaeroporto,
                                0, // r2escalaorigem,
                                0, // r2escaladestino,
                                returnData.connections?.length ?? 0,
                                returnData.layovers?.length ?? 0,
                              ),
                            }).map((_, index) => (
                              <div
                                key={index}
                                className="z-0 -mt-[33px] h-2 w-2 rounded-full border border-solid !border-neutral-700 bg-white"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-base text-primary">
                          <b className="font-semibold">
                            {returnData.destination}
                          </b>{' '}
                          {formatTime(returnData.destinationTime)?.replace(
                            'h',
                            '',
                          )}
                        </span>
                        <span className="text-xs font-normal text-gray-700">
                          {formatCityAirport(returnData.destination, airports)}
                        </span>
                      </div>
                    </div>
                    {returnData?.baggage && (
                      <div className="mt-2 flex items-center gap-1 text-primary">
                        <BsSuitcase2Fill height={17} />
                        <span className="text-xs font-normal text-neutral-700">
                          Bagagem para despachar:{' '}
                          {formatBaggage(returnData?.baggage)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
