import { toaster } from '@system/utils/toaster';

export async function getCepData({ cep }) {
  const url = `https://viacep.com.br/ws/${cep}/json`;
  const options = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  } catch (error) {
    toaster('error', 'CNPJ não encontrado.');
  }
}
