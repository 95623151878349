import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { PiTrashSimpleBold } from 'react-icons/pi';
import { Button } from '@components/button';
import { InputText } from '@components/input';
import LoadingComponent from '@components/loading/Loading';
import { Modal } from '@components/modal';
import { Form } from '@components/new-components/form';
import { SelectV2 } from '@components/new-components/select-v2';
import {
  SELECT_OPTIONS_ADMIN,
  SELECT_OPTIONS_BUYER_ADMIN,
} from '@data/roles.data';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { ModalInviteErrorSendedPartialMessage } from './ModalInviteErrorSendedPartialMessage';
import loadingIcon from '../../../assets/loading-bullets.webm';
import { ModalInviteNewUserErrorMessage } from './ModalInviteNewUserErrorMessage';
import { ModalInviteNewUserSuccessMessage } from './ModalInviteNewUserSuccessMessage';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

const INITIAL_STATE = {
  firstName: '',
  familyName: '',
  email: '',
  agencyId: '',
  status: '',
};
export function ModalInviteNewUser({ open, onClose, agencies = [] }) {
  const { session } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState('initial');
  const [whoHasFailed, setWhoHasFailed] = useState([]);
  const {
    control,
    watch,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      form: [INITIAL_STATE],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form',
    rules: {
      required: 'Campo obrigatório',
    },
  });

  async function onSubmit(data) {
    AnalyticsService.trackEvent(
      events.USER_LIST_INVITE_MODAL_SEND_BUTTON_CLICKED,
    );

    setLoading(true);
    setWhoHasFailed([]);
    try {
      const form = {
        users: data?.form?.map((item) => ({
          firstName: item.firstName,
          familyName: item.familyName,
          email: item.email,
          agencyId: Number(item.agencyId),
          status: Number(item.status),
        })),
      };
      const res = await api.post('/users/invite', form);
      const errors = res.data?.filter((item) => item.error);
      if (errors.length === 0) {
        setState('success');
        setValue('form', [INITIAL_STATE]);
        AnalyticsService.trackEvent(
          events.USER_LIST_INVITE_MODAL_SUCCESS_PAGE_VIEWED,
        );

        return;
      }
      setState('partial');
      setWhoHasFailed(errors?.map((item) => item.email));

      AnalyticsService.trackEvent(
        events.USER_LIST_INVITE_MODAL_PARTIAL_PAGE_VIEWED,
      );
    } catch (error) {
      if (error?.message === 'USER_ALREADY_EXISTS') {
        setState('warning');
        return;
      }
      setState('error');

      AnalyticsService.trackEvent(
        events.USER_LIST_INVITE_MODAL_FAILED_PAGE_VIEWED,
      );
    } finally {
      setLoading(false);
    }
  }

  function addForm() {
    if (fields.length >= 3) {
      return;
    }
    append(INITIAL_STATE);

    AnalyticsService.trackEvent(
      events.USER_LIST_INVITE_MODAL_ADD_USER_BUTTON_CLICKED,
    );
  }

  function removeForm(index) {
    remove(index);

    AnalyticsService.trackEvent(
      events.USER_LIST_INVITE_MODAL_REMOVE_USER_BUTTON_CLICKED,
    );
  }

  function onCloseInitialState() {
    setValue('form', [INITIAL_STATE]);
    setState('initial');
    onClose();
  }

  function onTryAgain() {
    setState('initial');
  }

  function onRetryAgain() {
    setState('initial');
    const form = watch('form')?.filter((item) =>
      whoHasFailed.includes(item.email),
    );
    if (!form.length) {
      return;
    }
    setValue(
      'form',
      watch('form')?.filter((item) => whoHasFailed.includes(item.email)),
    );
  }

  const STATES = {
    success: <ModalInviteNewUserSuccessMessage onClose={onCloseInitialState} />,
    error: (
      <ModalInviteNewUserErrorMessage
        onClose={onCloseInitialState}
        onTryAgain={onTryAgain}
      />
    ),
    partial: (
      <ModalInviteErrorSendedPartialMessage
        onClose={onCloseInitialState}
        state="partial"
        onRetryAgain={onRetryAgain}
        whoHasFailed={whoHasFailed}
      />
    ),
    warning: (
      <ModalInviteNewUserErrorMessage
        onClose={onCloseInitialState}
        state="warning"
        onTryAgain={onTryAgain}
      />
    ),
    initial: (
      <>
        {loading ? (
          <LoadingComponent
            loadingText="<strong>Estamos enviando seus convites, <br> aguarde alguns instantes.</strong>"
            loadingIcon={loadingIcon}
          />
        ) : (
          <div className="w-[100%] text-sm text-neutral-800">
            <Modal.Header
              title="Convidar novo usuário"
              handleClose={onCloseInitialState}
              size="mb-4"
            />
            <div className="mt-4 w-[100%] text-sm text-neutral-800">
              <Form onSubmit={handleSubmit(onSubmit)} className="">
                <div className="flex flex-col gap-7">
                  {fields.map((_, index) => (
                    <div
                      className="flex flex-col gap-4"
                      key={`form-index-${index}`}
                    >
                      {index === 0 ? (
                        <div>
                          <p className="mb-[4px] text-[16px] font-semibold text-neutral-800">
                            Informações dos usuários
                          </p>
                          <span className="text-small">
                            Insira os dados dos usuários que você deseja
                            convidar para o book2B.
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center justify-between">
                          <p className="mb-[4px] text-[16px] font-semibold text-neutral-800">
                            Usuário convidado {index + 1}
                          </p>
                          <div
                            role="button"
                            className="text-md text-[#E41047]"
                            onClick={() => removeForm(index)}
                          >
                            <PiTrashSimpleBold size={18} />
                          </div>
                        </div>
                      )}
                      <div className="grid grid-cols-12 gap-4 border-x-0 border-b border-t-0 border-solid border-neutral-300 pb-6">
                        <Form.Group className="col-span-3">
                          <InputText
                            placeholder="Nome"
                            placeholderStyle="normal"
                            error={
                              errors?.form?.[index]?.firstName?.message ?? ''
                            }
                            {...register(`form[${index}].firstName`, {
                              required: {
                                message: 'Campo obrigatório',
                                value: true,
                              },
                              minLength: 3,
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="col-span-3">
                          <InputText
                            placeholder="Sobrenome"
                            placeholderStyle="normal"
                            error={
                              errors?.form?.[index]?.familyName?.message ?? ''
                            }
                            {...register(`form[${index}].familyName`, {
                              required: {
                                message: 'Campo obrigatório',
                                value: true,
                              },
                              minLength: 3,
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="col-span-6">
                          <InputText
                            placeholder="Email"
                            placeholderStyle="normal"
                            error={errors?.form?.[index]?.email?.message ?? ''}
                            {...register(`form[${index}].email`, {
                              required: {
                                message: 'Campo obrigatório',
                                value: true,
                              },
                              pattern: {
                                message: 'Email inválido',
                                value:
                                  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                              },
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="col-span-6">
                          <SelectV2
                            options={agencies}
                            placeholder="Empresa"
                            placeholderStyle="normal"
                            isError={true}
                            error={errors?.form?.[index]?.agencyId}
                            {...register(`form[${index}].agencyId`, {
                              required: {
                                message: 'Campo obrigatório',
                                value: true,
                              },
                            })}
                          />
                          <Form.Message
                            error={errors?.form?.[index]?.agencyId}
                          />
                        </Form.Group>
                        <Form.Group className="col-span-6">
                          <SelectV2
                            options={
                              session.role === Role.Administrador
                                ? SELECT_OPTIONS_ADMIN
                                : SELECT_OPTIONS_BUYER_ADMIN
                            }
                            placeholder="Selecione o perfil"
                            placeholderStyle="normal"
                            error={errors?.form?.[index]?.status}
                            isError={true}
                            {...register(`form[${index}].status`, {
                              required: {
                                message: 'Campo obrigatório',
                                value: true,
                              },
                            })}
                          />
                          <Form.Message error={errors?.form?.[index]?.status} />
                        </Form.Group>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-7">
                  <div
                    className="flex border-x-0 border-b border-t-0 border-solid border-neutral-300 py-6"
                    role="button"
                    onClick={() => addForm()}
                  >
                    <span
                      className={`text-md select-none font-semibold ${fields.length === 3 ? 'text-neutral-600' : 'text-[#E41047]'}`}
                    >
                      + Adicionar outro usuário
                    </span>
                  </div>
                  <div className="flex h-[48px] justify-center gap-4">
                    <Button
                      className="px-4"
                      onClick={onClose}
                      variant="ghost"
                      label="Fechar"
                      type="button"
                    />
                    <Button
                      className="px-4"
                      label="Enviar convite"
                      type="submit"
                      disabled={loading}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
      </>
    ),
  };

  return (
    <Modal open={open} handleClose={onCloseInitialState}>
      {STATES[state]}
    </Modal>
  );
}
