import { PiWarningCircle } from 'react-icons/pi';

import { Button } from '@components/button';
import { CiWarning } from 'react-icons/ci';

export function ModalInviteNewUserErrorMessage({
  onClose,
  onTryAgain,
  onRetryAgain,
  state = 'error',
}) {
  const stateProps = {
    partial: {
      icons: <CiWarning size={40} className="text-warning-500" />,
      title: (
        <p className="m-0 text-center text-2xl font-bold text-warning-500">
          Envio parcialmente concluído!
        </p>
      ),
      message:
        'Alguns convites foram enviados com sucesso, mas outros não. Acompanhe o status dos convites na lista de usuários.',
      action: (
        <Button
          className="px-4"
          onClick={onRetryAgain}
          label="Tentar novamente"
          type="button"
        />
      ),
    },
    warning: {
      icons: <CiWarning size={40} className="text-warning-500" />,
      title: (
        <p className="m-0 text-center text-2xl font-bold text-warning-500">
          E-mail(s) já cadastrado(s) ou convidado(s)
        </p>
      ),
      message:
        'Os e-mails adicionados já estão cadastrados ou foram previamente convidados para a Book2b. Verifique os endereços e tente novamente, caso necessário.',
      action: <></>,
    },
    error: {
      icons: <PiWarningCircle size={40} className="text-error-500" />,
      title: (
        <p className="m-0 text-center text-2xl font-bold text-error-500">
          Falha no envio dos convites!
        </p>
      ),
      message:
        'Algo deu errado e os convites não foram enviados. Por favor, revise os dados e tente novamente.',
      action: (
        <Button
          className="px-4"
          onClick={onTryAgain}
          label="Reenviar convites"
          type="button"
        />
      ),
    },
  };

  return (
    <div className="w-[100%] py-7">
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex flex-col gap-4">
          <div className="text-center">{stateProps[state].icons}</div>
          {stateProps[state].title}
          <p className="m-0 max-w-[580px] text-center">
            {stateProps[state].message}
          </p>
        </div>
        <div className="flex h-[48px] justify-center gap-4">
          <Button
            className="px-4"
            onClick={onClose}
            variant="ghost"
            label="Fechar"
            type="button"
          />
          {stateProps[state].action}
        </div>
      </div>
    </div>
  );
}
