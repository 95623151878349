import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function Rodoviario() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal unavailableModal">
          <AiOutlineClose
            className="closeModalTrash"
            onClick={() => setOpenModal(false)}
          />
          <h2>Obrigado!</h2>
          <span>Você será avisado quando estiver disponível.</span>
        </div>
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Rodoviário', link: PagePath.Rodoviario },
        ]}
      />

      <div className="contentAll msnAguarde">
        <span>
          Este produto ainda não está disponível. Aguarde, em breve teremos
          novidades!
        </span>
        <button onClick={() => setOpenModal(true)}>
          Avisar quando tiver disponível
        </button>
      </div>
    </>
  );
}
