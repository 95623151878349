import { Chips } from '@components/chips';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import api from '@services/api';
import { TYPE_COMPANY } from '@system/utils';
import { useEffect, useState } from 'react';
import { BiEditAlt, BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AdminEmpresas() {
  const [loading, setLoading] = useState(false);
  const [filterRazaoSocial, setFilterRazaoSocial] = useState('');
  const [filterNomeFantasia, setFilterNomeFantasia] = useState('');
  const [filterTipoEmpresa, setFilterTipoEmpresa] = useState('');
  const [orderBy, setOrderBy] = useState('asc');
  const [page, setPage] = useState(1);
  const [chips, setChips] = useState([]);
  const [limit, setLimit] = useState(10);
  const [sortedList, setSortedList] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({});
  useEffect(() => {
    getData();
  }, [page]);
  useEffect(() => {
    getData();
  }, [filters]);
  function editContractor(data) {
    navigate(PagePath.AdminEmpresasCadastrar, { state: data });
  }
  const getData = async () => {
    setLoading(true);
    let url = '/company?';
    Object.keys(filters).forEach((key) => {
      if (!!filters[key]) url = url + `${key}=${filters[key]}&`;
    });
    try {
      const { data } = await api.get(
        `${url}page=${page}&limit=${limit}&order=${orderBy}`,
      );
      setSortedList(sortedList.concat(data?.result));
      setTotal(data?.total);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSetNewFilters = async () => {
    let filters = {
      razaosocial: filterRazaoSocial || undefined,
      fantasia: filterNomeFantasia || undefined,
      tipo: filterTipoEmpresa || undefined,
    };
    setFilterRazaoSocial('');
    setFilterNomeFantasia('');
    setFilterTipoEmpresa('');
    const newChips = Object.keys(filters)
      .filter((key) => filters[key] !== undefined)
      .map((key, index) => {
        let label = '';
        switch (key) {
          case 'razaosocial':
            label = `${filters[key]}`;
            break;
          case 'fantasia':
            label = `${filters[key]}`;
            break;
          case 'tipo':
            label = `${filters[key]}`;
            break;
          default:
            break;
        }
        return { id: index + 1, label, key };
      });
    setChips(newChips);
    setSortedList([]);
    setFilters(filters);
    setPage(1);
  };
  const handleClearAllFilters = () => {
    setChips([]);
    setFilterRazaoSocial('');
    setFilterNomeFantasia('');
    setFilterTipoEmpresa('');
    setOrderBy('');
    setPage(1);
  };
  const handleRemoveFilter = (chipData) => {
    setSortedList([]);
    const tempChips = chips;
    let indexToRemove = tempChips.findIndex((chip) => chip.id == chipData.id);
    tempChips.splice(indexToRemove, 1);
    setChips(tempChips);
    const tempFilters = filters;
    delete tempFilters[chipData.key];
    setFilters({ ...tempFilters });
    setPage(1);
  };
  const navigate = useNavigate();
  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Sistemas - Empresas', link: PagePath.AdminEmpresas },
        ]}
        title="Empresas"
        button="Cadastrar nova empresa"
        buttonClick={() => navigate(PagePath.AdminEmpresasCadastrar)}
      />
      <div className="grid grid-cols-6 grid-rows-1 gap-4 rounded-md bg-white p-4">
        <div className="col-span-2">
          <Form.Group>
            <Form.Label>Razão social</Form.Label>
            <InputV2
              onChange={(event) => setFilterRazaoSocial(event.target.value)}
              placeholder="Digite a razão social"
            />
          </Form.Group>
        </div>
        <div className="col-span-2 col-start-3">
          <Form.Group>
            <Form.Label>Nome fantasia</Form.Label>
            <InputV2
              onChange={(event) => setFilterNomeFantasia(event.target.value)}
              placeholder="Digite o nome fantasia"
            />
          </Form.Group>
        </div>
        <div className="col-span-2 flex w-full items-end justify-between gap-3">
          <Form.Group className="w-full">
            <Form.Label>Tipo</Form.Label>
            <div className="flex items-center gap-3">
              <SelectV2
                placeholder="Selecione"
                onChange={(event) => setFilterTipoEmpresa(event.target.value)}
                options={TYPE_COMPANY}
              />
              <ButtonV2 iconOnly ghost onClick={handleSetNewFilters}>
                <ButtonV2.Icon icon={BiSearch} />
              </ButtonV2>
            </div>
          </Form.Group>
        </div>
      </div>
      <div className="mb-4 mt-4 flex w-full justify-end">
        <Form.Group className="w-[335px]">
          <Form.Label>Ordenar por</Form.Label>
          <SelectV2
            placeholder="Ordem alfabética"
            options={[
              { label: 'Ordem alfabética asc', value: 'asc' },
              { label: 'Ordem alfabética desc', value: 'desc' },
            ]}
            value={orderBy}
            onChange={(event) => setOrderBy(event.target.value)}
          />
        </Form.Group>
      </div>
      {chips.length > 0 && (
        <div className="mt-4 flex w-full items-center justify-between border-x-0 border-y border-solid border-neutral-400 px-1 py-4">
          <div className="flex items-center gap-1">
            {chips.map((chip) => (
              <Chips
                key={chip.id}
                label={chip.label}
                onRemove={() => {
                  handleRemoveFilter(chip);
                }}
              />
            ))}
          </div>
          <ButtonV2 link onClick={handleClearAllFilters}>
            <ButtonV2.Text>Apagar todos</ButtonV2.Text>
          </ButtonV2>
        </div>
      )}
      <div className="mt-4 flex flex-col items-center gap-5 rounded-md bg-white p-4">
        <div className="m-0 flex w-full">
          <h4 className="heading-2 m-0 text-primary">
            Lista de empresas cadastradas
          </h4>
        </div>
        <div className="m-0 w-full rounded-md border border-solid border-neutral-300 px-3 py-2.5">
          <table className="w-full border-collapse">
            <thead className="h-12 border-x-0 border-b border-t-0 border-solid border-primary !pb-3">
              <tr>
                <th
                  className="pl-3 text-body font-semibold text-primary"
                  scope="col"
                >
                  Nome Fantasia
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Razão Social
                </th>
                <th
                  className="!border-solid text-body font-semibold text-primary"
                  scope="col"
                >
                  Cidade/UF
                </th>
                <th
                  scope="col"
                  className="text-body font-semibold text-primary"
                >
                  Tipo
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody className="m-0 divide-y divide-solid divide-neutral-300 p-0">
              {sortedList.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => editContractor(data)}
                  className="h-[72px] hover:bg-secondary-100"
                >
                  <td className="pl-3 text-body text-neutral-800">
                    {data.nome_fantasia}
                  </td>
                  <td className="text-body text-neutral-800">
                    {data.razaosocial}
                  </td>
                  <td className="text-body text-neutral-800">
                    {data.cidade}/{data.uf}
                  </td>
                  <td className="text-body text-neutral-800">
                    {data.tipo_da_empresa}
                  </td>
                  <td>
                    <BiEditAlt className="text-2xl text-secondary" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {sortedList.length < total && (
          <ButtonV2
            link
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
          </ButtonV2>
        )}
      </div>
    </>
  );
}
