export function validatePassword(password) {
  const minLength = 8;
  const hasMinLength = password.length >= minLength;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  const isValid =
    hasMinLength && hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar;

  return {
    isValid,
    errors: {
      minLength: hasMinLength,
      lowerCase: hasLowerCase,
      upperCase: hasUpperCase,
      number: hasNumber,
      specialChar: hasSpecialChar,
    },
  };
}
