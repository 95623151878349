import { format } from 'date-fns';
import { useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { BiCalendar, BiUser } from 'react-icons/bi';
import { MdOutlinePlace } from 'react-icons/md';
import { TbPlane } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import { Button } from '@components/button';
import { CalendarType } from '@components/calendar-selector';
import { InputRadio } from '@components/input';
import {
  InputDatePicker,
  InputTextDropdown,
  InputTextDropdownItem,
  InputTextStepper,
  InputTextStepperItem,
} from '@components/input-custom';

import { useAereoReservation } from '@hooks/useAereo';
import { Container } from '@layout';
import api from '@services/api';
import { ORDER_BY_FLIGHTS } from '@system/enums';
import { formatDateWithoutTZ, formatToBRTz } from '@system/utils';
import { PagePath } from '../../../pages-config';

const TripType = {
  RoundTrip: 'round-trip',
  OneWay: 'one-way',
};
const DateFilter = {
  Interval: 'interval',
  Months: 'months',
};
const DateInputType = {
  Departure: 'departure',
  Return: 'return',
};

export default function ReservationForm() {
  const { closeForm, handleUpdateQueryType, params } = useAereoReservation();

  const navigate = useNavigate();
  const departureDateInputRef = useRef(null);
  const returnDateInputRef = useRef(null);

  function getInitialTripType() {
    return TripType.RoundTrip;
  }

  function getInitialDateFilter() {
    return params.months ? DateFilter.Months : DateFilter.Interval;
  }

  function getInitialDateInput() {
    return params.months ? DateInputType.Departure : DateInputType.Return;
  }

  function getInitialFormFields() {
    return {
      origin: decodeURIComponent(params.origin ?? ''),
      originIata: params.originIata ?? '',
      destination: decodeURIComponent(params.destination ?? ''),
      destinationIata: params.destinationIata ?? '',
      departureDate: params.departureDate ?? null,
      returnDate: params.returnDate ?? null,
      months: params?.months?.split(',') ?? [],
      adultPassengers: Number(params.adultPassengers ?? 1),
      childrenPassengers: Number(params.childrenPassengers ?? 0),
      babyPassengers: Number(params.babyPassengers ?? 0),
      orderBy: params.orderBy ?? ORDER_BY_FLIGHTS.RECOMMEND,
    };
  }

  function getInitialOriginAirport() {
    return decodeURIComponent(params.origin ?? '');
  }

  function getInitialDestinationAirport() {
    return decodeURIComponent(params.destination ?? '');
  }

  const [tripType, setTripType] = useState(getInitialTripType());
  const [dateFilter, setDateFilter] = useState(getInitialDateFilter());
  const [activeDateInput, setActiveDateInput] = useState(getInitialDateInput());
  const [formFields, setFormFields] = useState(getInitialFormFields());
  const [formErrors, setFormErrors] = useState({});

  const [airports, setAirports] = useState([]);
  const [originAirport, setOriginAirport] = useState(getInitialOriginAirport());
  const [destinationAirport, setDestinationAirport] = useState(
    getInitialDestinationAirport(),
  );

  const isIntervalFilterActive = dateFilter === DateFilter.Interval;

  const totalPassengers =
    formFields.adultPassengers +
    formFields.childrenPassengers +
    formFields.babyPassengers;

  const calendarType = params.months
    ? CalendarType.Yearly
    : CalendarType.Monthly;

  async function fetchAirports(e) {
    setAirports([]);

    const response = await api.get('/airports', {
      params: { search: e.target.value, limit: 5 },
    });

    setAirports(
      response.data.sort((a, b) =>
        a.name.toLowerCase().includes('todos')
          ? -1
          : b.name.toLowerCase().includes('todos')
            ? 1
            : 0,
      ),
    );

    return response.data;
  }

  function handleInputChange(e) {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  }

  function handleFieldChange(field, value) {
    setFormFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleTripTypeChange(e) {
    setTripType(e.target.value);

    if (e.target.value === TripType.OneWay) {
      handleFieldChange('returnDate', '');
    }
  }

  function handleLocationFieldChange(e) {
    handleInputChange(e);
    if (e.target.name === 'origin') {
      setOriginAirport('');
    }

    if (e.target.name === 'destination') {
      setDestinationAirport('');
    }
  }

  function checkSelectedAirport(type) {
    if (type === 'origin' && !originAirport) {
      handleFieldChange('origin', '');
    }

    if (type === 'destination' && !destinationAirport) {
      handleFieldChange('destination', '');
    }
  }

  function normalizeString(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  function highlightSearchText(text, search) {
    const normalizedText = normalizeString(text);
    const normalizedSearch = normalizeString(search);

    const escapedSearch = normalizedSearch.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    );
    const snippet = normalizedText.match(new RegExp(escapedSearch, 'i'))?.at(0);

    if (!snippet) return text;

    const index = normalizedText.toLowerCase().indexOf(snippet.toLowerCase());
    return (
      <>
        {text.slice(0, index)}
        <span className="text-secondary">
          {text.slice(index, index + snippet.length)}
        </span>
        {text.slice(index + snippet.length)}
      </>
    );
  }

  function renderAirportsDropdown(type) {
    return airports.map((airport) => {
      const search = formFields[type];
      const label = `${airport.name}, ${airport.city} (${airport.iata})`;
      const text = highlightSearchText(label, search);

      return (
        <InputTextDropdownItem
          key={airport.iata}
          onClick={() => handleAirportClick(type, airport, label)}
        >
          {text}
        </InputTextDropdownItem>
      );
    });
  }

  function handleAirportClick(type, airport, label) {
    const setAirport =
      type === 'origin' ? setOriginAirport : setDestinationAirport;
    const iataField = `${type}Iata`;

    setAirport(label);
    handleFieldChange(iataField, airport.iata);
    handleFieldChange(type, label);
  }

  function showDateInput(type) {
    const isTypeActive = activeDateInput === type;
    return isIntervalFilterActive || (!isIntervalFilterActive && isTypeActive);
  }

  function makeDateInputLabel(label) {
    return isIntervalFilterActive ? label : 'Por mês';
  }

  function makeDateInputValue(value) {
    const dateFormat = value ? formatDateWithoutTZ(value, 'dd/MM/yyyy') : '';
    const monthsFormat = formFields.months
      .map((m) => formatToBRTz(convertToMonthStartDate(m), 'MMMM/yyyy'))
      .join(', ');
    return isIntervalFilterActive ? dateFormat : monthsFormat;
  }

  function makeDateInputPlaceholder(placeholder) {
    return isIntervalFilterActive ? placeholder : 'Insira o mês ou meses';
  }

  function handleDatePickerSwitch(calendarType, inputType) {
    if (calendarType === CalendarType.Yearly) {
      setDateFilter(DateFilter.Months);
    } else {
      setDateFilter(DateFilter.Interval);
    }

    setActiveDateInput(inputType);
  }

  function handleDepartureDateChange(date) {
    const newReturnDate =
      date > formFields.returnDate ? null : formFields.returnDate;
    /**
     * @note This will ensure that returnDateInputRef is focused correctly
     */
    flushSync(() =>
      setFormFields((prev) => ({
        ...prev,
        departureDate: date,
        returnDate: newReturnDate,
      })),
    );

    returnDateInputRef.current?.focus();
    returnDateInputRef.current?.click();
  }

  function handleReturnDateClick(showCalendar) {
    if (formFields.departureDate || formFields.months.length >= 1) {
      return showCalendar();
    }
    departureDateInputRef.current?.focus();
    departureDateInputRef.current?.click();
  }

  function validateFormFields() {
    const errors = {};
    let isValid = true;

    if (!formFields.origin) {
      errors.origin = 'Preencha o campo de origem';
      isValid = false;
    }

    if (!formFields.destination) {
      errors.destination = 'Preencha o campo de destino';
      isValid = false;
    }

    if (!isIntervalFilterActive && !formFields.months.length) {
      errors.months = 'Preencha o campo de mês';
      isValid = false;
    }

    if (isIntervalFilterActive && !formFields.departureDate) {
      errors.departureDate = 'Preencha o campo de data de ida';
      isValid = false;
    }

    if (
      tripType === TripType.RoundTrip &&
      isIntervalFilterActive &&
      !formFields.returnDate
    ) {
      errors.returnDate = 'Preencha o campo de data de volta';
      isValid = false;
    }

    if (totalPassengers < 1) {
      errors.passengers = 'Pelo menos 1 passageiro é necessário';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  }

  function convertToMonthStartDate(date) {
    return typeof date === 'string' ? new Date(`${date}-01 00:00:00`) : date;
  }

  function normalizeDate(date, formatStr) {
    return typeof date === 'string' ? date : format(date, formatStr);
  }

  function makeQuery() {
    const isRoundTrip = tripType === TripType.RoundTrip;
    const departureDate = formFields.departureDate;

    const returnDate = isRoundTrip
      ? formFields.returnDate
      : formFields.departureDate;

    const query = new URLSearchParams({
      origin: encodeURIComponent(originAirport),
      originIata: formFields.originIata,
      destination: encodeURIComponent(destinationAirport),
      destinationIata: formFields.destinationIata,
      departureDate:
        departureDate && normalizeDate(departureDate, 'yyyy-MM-dd'),
      returnDate: returnDate && normalizeDate(returnDate, 'yyyy-MM-dd'),
      months: formFields.months.map((m) => normalizeDate(m, 'yyyy-MM')),
      adultPassengers: formFields.adultPassengers,
      childrenPassengers: formFields.childrenPassengers,
      babyPassengers: formFields.babyPassengers,
      orderBy: formFields.orderBy,
    });

    if (isIntervalFilterActive) {
      query.delete('months');
    } else {
      query.delete('departureDate');
      query.delete('returnDate');
    }

    return query;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!validateFormFields()) return;

    closeForm();
    handleUpdateQueryType('initial');

    const query = makeQuery();
    navigate(`${PagePath.AereoReservasResultados}?${query.toString()}`);
  }

  return (
    <Container className="rounded-none rounded-b-lg border-t-0">
      <div className="md:flex">
        <h2 className="mb-4 mr-6 text-heading-2 text-primary md:mb-0">
          Passagens aéreas
        </h2>
        <div className="flex space-x-6">
          <InputRadio
            label="Ida e volta"
            name="trip-type"
            value={TripType.RoundTrip}
            checked={tripType === TripType.RoundTrip}
            onChange={handleTripTypeChange}
          />
          <InputRadio
            label="Somente 1 trecho"
            name="trip-type"
            value={TripType.OneWay}
            checked={tripType === TripType.OneWay}
            onChange={handleTripTypeChange}
          />
        </div>
      </div>
      <div className="mt-5 md:mt-6">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2.5 lg:flex-row lg:space-x-2.5 lg:space-y-0">
            <InputTextDropdown
              name="origin"
              label="Origem"
              autoComplete="off"
              placeholder="Insira a origem"
              icon={MdOutlinePlace}
              dropdownTitle="Aeroportos"
              dropdownIcon={TbPlane}
              value={formFields.origin}
              noValidate
              error={formErrors.origin}
              onChange={handleLocationFieldChange}
              onDropdownAppears={fetchAirports}
              onDropdownDisappears={() => checkSelectedAirport('origin')}
            >
              {renderAirportsDropdown('origin')}
            </InputTextDropdown>
            <InputTextDropdown
              name="destination"
              label="Destino"
              autoComplete="off"
              placeholder="Insira o destino"
              icon={MdOutlinePlace}
              dropdownTitle="Aeroportos"
              dropdownIcon={TbPlane}
              value={formFields.destination}
              noValidate
              error={formErrors.destination}
              onChange={handleLocationFieldChange}
              onDropdownAppears={fetchAirports}
              onDropdownDisappears={() => checkSelectedAirport('destination')}
            >
              {renderAirportsDropdown('destination')}
            </InputTextDropdown>
          </div>
          <div className="mt-4 flex flex-col space-y-2.5 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
            {showDateInput(DateInputType.Departure) && (
              <InputDatePicker
                calendarType={calendarType}
                name="departureDate"
                label={makeDateInputLabel('Data da ida')}
                autoComplete="off"
                placeholder={makeDateInputPlaceholder('Insira a data da ida')}
                icon={BiCalendar}
                value={makeDateInputValue(formFields.departureDate)}
                noValidate
                error={formErrors.departureDate || formErrors.months}
                intervalStart={formFields.departureDate}
                changeOnly="start"
                multiCalendar
                onTypeSwitch={(type) =>
                  handleDatePickerSwitch(type, DateInputType.Departure)
                }
                selectedMonths={formFields.months.map((m) =>
                  convertToMonthStartDate(m),
                )}
                onDateChange={handleDepartureDateChange}
                onMonthsChange={(months) => handleFieldChange('months', months)}
                ref={departureDateInputRef}
              />
            )}
            {showDateInput(DateInputType.Return) && (
              <InputDatePicker
                className="mb-2 md:mb-0"
                name="returnDate"
                label={makeDateInputLabel('Data da volta')}
                autoComplete="off"
                placeholder={makeDateInputPlaceholder('Insira a data da volta')}
                icon={BiCalendar}
                value={makeDateInputValue(formFields.returnDate)}
                noValidate
                disabled={tripType === TripType.OneWay}
                error={formErrors.returnDate || formErrors.months}
                intervalStart={formFields.departureDate}
                intervalEnd={formFields.returnDate}
                changeOnly="end"
                multiCalendar
                onTypeSwitch={(type) =>
                  handleDatePickerSwitch(type, DateInputType.Return)
                }
                startCalendar={formFields.departureDate}
                selectedMonths={formFields.months}
                onClick={handleReturnDateClick}
                onDateChange={(date) => handleFieldChange('returnDate', date)}
                onMonthsChange={(months) => handleFieldChange('months', months)}
                ref={returnDateInputRef}
              />
            )}
            <InputTextStepper
              name="passengers"
              label="Passageiros"
              autoComplete="off"
              placeholder="Insira o n. de passageiros"
              icon={BiUser}
              dropdownTitle="Passageiros"
              value={totalPassengers}
              noValidate
              error={formErrors.passengers}
            >
              <InputTextStepperItem
                name="adultPassengers"
                label="Adultos"
                autoComplete="off"
                tip="12+"
                value={formFields.adultPassengers}
                onChange={handleInputChange}
                max={10}
              />
              <InputTextStepperItem
                name="childrenPassengers"
                label="Crianças"
                autoComplete="off"
                tip="2 a 11 anos"
                value={formFields.childrenPassengers}
                onChange={handleInputChange}
                max={10}
              />
              <InputTextStepperItem
                name="babyPassengers"
                label="Bebês"
                autoComplete="off"
                tip="0 a 23 meses"
                value={formFields.babyPassengers}
                onChange={handleInputChange}
                max={10}
              />
            </InputTextStepper>
            <Button
              type="submit"
              className="!mt-4 h-12 w-full md:!mt-7 lg:basis-1/2"
              label="Buscar"
            />
          </div>
        </form>
      </div>
    </Container>
  );
}
