import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingGif from '@assets/loading.gif';
import api from '@services/api';
import { ActivateAccountForm } from './components/ActivateAccountForm';
import { ErrorPage } from './components/ErrorPage';
import AnalyticsService from '@services/analytics/analytics';
import events from '@services/analytics/events';

export function ActivateAccount() {
  const [state, setState] = useState('loading');
  const { token } = useParams();
  const [userToActivate, setUserToActivate] = useState(null);

  useEffect(() => {
    async function activateAccount() {
      try {
        const { data } = await api.post('/users/validate-invite-token', {
          token,
        });
        setUserToActivate(data);
        setState('initial');

        AnalyticsService.trackEvent(events.USER_ACTIVATION_PAGE_VIEWED);
      } catch (error) {
        if (error?.statusCode === 410) {
          setState('invalidToken');

          AnalyticsService.trackEvent(
            events.USER_ACTIVATION_EXPIRED_MODAL_PAGE_VIEWED,
          );
          return;
        }
        setState('serverError');
      }
    }
    activateAccount();
  }, [token]);

  const render = () => {
    return {
      loading: (
        <div className="flex h-[100vh] w-[100%] items-center justify-center">
          <img src={LoadingGif} alt="loading" />
        </div>
      ),
      initial: <ActivateAccountForm user={userToActivate} />,
      invalidToken: (
        <ErrorPage
          title="Convite expirado"
          message="O prazo de 24 horas para ativar sua conta expirou. Entre em
              contato com o administrador para receber um novo convite."
          type="warning"
        />
      ),
      serverError: (
        <ErrorPage
          title="Error"
          message="Ocorreu um problema ao tentar ativar sua conta. Por favor, tente novamente mais tarde."
        />
      ),
    };
  };

  return render()[state];
}
