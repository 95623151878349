export async function getCnpjData({ cnpj }) {
  const cleanedCNPJ = cnpj.replace(/[^0-9]/g, '');
  if (cleanedCNPJ.length === 14) {
    const url = `https://publica.cnpj.ws/cnpj/${cleanedCNPJ}`;
    const options = {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    };
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching CNPJ data:', error);
    }
  }
}
