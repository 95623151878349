import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import AereoReservationsProvider from '@hooks/useAereo.jsx';
import { Dashboard, DashboardV2 } from '@layout';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from './pages-config';

const dashboardVersion = import.meta.env.VITE_DASHBOARD_VERSION;
const CurrentDashboard = dashboardVersion === 'v2' ? DashboardV2 : Dashboard;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, methods } = useAuthentication();

  useEffect(() => {
    const validate = async () => {
      const tokenIsValid = await methods.validateToken();
      if (!tokenIsValid) {
        if (
          window.location.href.replace(/\/$/, '') !== window.location.origin
        ) {
          navigate('/', { state: { from: window.location.href } });
        }
      } else {
        if (location.pathname === '/') {
          navigate(PagePath.Home);
        }
      }
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const accessTokenKey = localStorage.getItem('access_token');
    if (!accessTokenKey) return;

    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.fwSettings = { widget_id: 154000002373 };
      (function() {
        if (typeof window.FreshworksWidget !== 'function') {
          var n = function() { n.q.push(arguments) };
          n.q = [];
          window.FreshworksWidget = n;
          n('hide', 'launcher');
        }
      })();
    `;

    const script2 = document.createElement('script');
    script2.src = 'https://widget.freshworks.com/widgets/154000002373.js';
    script2.async = true;
    script2.defer = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);

      const widgetsElements = document.querySelectorAll('[id^="freshworks"]');
      widgetsElements.forEach((widget) => widget.remove());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * TODO: AereoReservationsProvider implementação provisório deverà ser removido
   * numa versão mais estruturada, em que as rotas @page.AereoReservas e @page.AereoReservasResultados
   * precisam compartilhar informacão entre si através do formulário de reserva.
   */
  return (
    session && (
      <AereoReservationsProvider>
        <CurrentDashboard>
          <Outlet />
        </CurrentDashboard>
      </AereoReservationsProvider>
    )
  );
}

export default App;
