import { useEffect } from 'react';
import { useAirports } from '@stores/airports';
import {
  formatCityAirportState,
  formatScale,
  formatTime,
  getAirportNameByIata,
} from '@system/utils';

export const RouteCard = ({ data, onClick }) => {
  const { airports, methods } = useAirports();
  useEffect(() => {
    methods.getAirports();
  }, []);

  return (
    <div
      onClick={onClick}
      className={`flex h-max w-full cursor-pointer flex-col justify-start rounded-md px-4 py-[14px] hover:bg-neutral-100`}
      style={{ border: '1px solid #343A41' }}
    >
      <div className="flex h-max w-full items-end justify-start">
        <div className="flex h-full w-max flex-col items-start">
          <div className="mb-[10px] flex items-center justify-start gap-2">
            <img width={31} src={data?.logo} alt={data?.nomeCia} />
            <span className="text-xs font-normal text-neutral-800">
              {data?.operadopor && `Operado por ${data?.operadopor} `}- Voo{' '}
              {data?.voo}
            </span>
          </div>
          <div className="flex flex-col items-start gap-[5px]">
            <div className="flex items-center gap-1">
              <span className="text-heading-3 text-primary">
                {data?.origem}
              </span>
              <span className="text-body text-neutral-800">
                {formatTime(data?.saida)?.replace('h', '')}
              </span>
            </div>
            <span className="text-small text-neutral-800">{data?.nome}</span>
            <span className="text-small font-semibold text-neutral-800">
              {data?.cidade}/{data?.uf}
            </span>
          </div>
        </div>

        <div className="m-0 ml-8 mr-7 flex h-24 w-[60px] flex-col items-center justify-center text-center text-xs font-normal text-neutral-700">
          <span className="flex flex-col items-center justify-center text-nowrap text-center text-xs">
            {formatScale(data?.escala, data?.conexao)}
            <hr className="mt-1 w-[60px] border-neutral-700" />
          </span>
          <div className="flex items-center justify-center gap-1">
            {Array.from({
              length: data?.escala + data?.conexao,
            }).map((_, index) => (
              <div
                key={index}
                className="z-0 -mt-[33px] h-2 w-2 rounded-full border border-solid !border-neutral-700 bg-white"
              />
            ))}
          </div>
        </div>

        <div className="flex h-full w-max flex-col justify-end gap-5">
          <div className="flex flex-col gap-[5px]">
            <div className="flex items-center gap-1">
              <span className="text-[14px] text-heading-3 font-semibold text-primary">
                {data?.destino}
              </span>
              <span className="flex gap-1 text-sm uppercase text-neutral-800">
                {formatTime(data?.chegada)?.replace('h', '') ??
                  formatTime(data?.chegada)}
              </span>
              <sup className={data?.outrodia == '1' ? '' : 'hidden'}>
                {' '}
                +{data?.outrodia}
              </sup>
            </div>
            <span className="text-small text-neutral-800">
              {getAirportNameByIata(data?.destino, airports)}
            </span>
            <span className="text-small font-semibold text-neutral-800">
              {formatCityAirportState(airports, data?.destino)}
            </span>
          </div>
        </div>
        <div className="w-max"></div>
      </div>
    </div>
  );
};
