import React, { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [data, setData] = useState({
    charterId: null,
    value: null,
    brandName: null,
    payerEmail: null,
    paymentMethod: null,
    bookingId: null,
    referenceId: null,
  });

  const setTotalValues = (
    installments,
    installmentValue,
    totalWithoutFee,
    totalWithFee,
    tax,
  ) => {
    setData((prevData) => ({
      ...prevData,
      installments,
      installmentValue,
      totalWithoutFee,
      totalWithFee,
      tax,
    }));
  };

  const updateData = (newData) => {
    setData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <CheckoutContext.Provider value={{ data, setTotalValues, updateData }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useData = () => useContext(CheckoutContext);
