import { Alert } from '@components/alert';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import api from '@services/api';
import { formatDocumentCPF } from '@system/utils';
import { formatPhones } from '@system/utils/formatPhones';
import {
  differenceInMonths,
  differenceInYears,
  parse,
  subHours,
} from 'date-fns';
import { useState } from 'react';
import { Modal } from '..';

export const ModalPassengerInfo = ({
  bookingId,
  item,
  departureDate,
  onClose = () => {},
  onUpdate = () => {},
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [updatableFields, setUpdatableFields] = useState({
    firstName: item.firstName,
    lastName: item.lastName,
    birthDate: item.birthDate,
  });

  const updateField = (field, value) => {
    setUpdatableFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleCloseModal = () => {
    onClose();
  };

  const getTipoPassageiroLabel = (type) => {
    switch (type) {
      case 'ADT':
        return 'adulto';
      case 'CHD':
        return 'criança';
      default:
        return 'bebê';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const d2 = parse(updatableFields.birthDate, 'dd/MM/yyyy', new Date());
    const d1 = new Date();

    const diffMonths = differenceInMonths(d1, d2);

    let type =
      diffMonths < 23
        ? 'INF'
        : diffMonths >= 23 && diffMonths < 133
          ? 'CHD'
          : 'ADT';

    const errors = {};

    if (differenceInYears(d1, d2) > 100) {
      errors.birthDate = {
        message: 'O limite de idade é 100 anos',
      };
    }

    if (type !== item.type) {
      const label = getTipoPassageiroLabel(item.type);
      errors.birthDate = {
        message: `Este passageiro precisa ser um tipo ${label}`,
      };
    }

    if (updatableFields.firstName.trim() === '') {
      errors.firstName = { message: `Preencha o campo de nome` };
    }
    if (updatableFields.lastName.trim() === '') {
      errors.lastName = { message: `Preencha o campo de último sobrenome` };
    }

    if (Object.keys(errors).length > 0) {
      console.log('errors', errors);
      setFormErrors(errors);
      return;
    }

    try {
      await api.patch(
        `/bookings/${bookingId}/travelers/${item.id}`,
        updatableFields,
      );
    } catch (err) {
      alert('Erro ao atualizar passageiro');
    } finally {
      onUpdate();
      onClose();
    }
  };

  const cannotUpdate = new Date() > subHours(new Date(departureDate), 48);

  return (
    <>
      <Modal open={true} className="w-8/12 max-w-7xl md:mx-auto large:w-9/12 ">
        <Modal.Header
          title={`Dados do passageiro`}
          handleClose={handleCloseModal}
        />
        {cannotUpdate && (
          <Alert type="warning">
            <div className="flex flex-col">
              <span className="text-small font-bold text-neutral-800">
                Os dados cadastrais não poderão ser alterados.
              </span>
              <span className="text-small text-neutral-800">
                Alterações só podem ser feitas até 48h antes da data/hora de
                partida.
              </span>
            </div>
          </Alert>
        )}
        <Form
          className="grid w-full grid-cols-1 gap-x-4 sm:grid-cols-12"
          onSubmit={handleSubmit}
        >
          <div className="col-span-12 grid w-full grid-cols-1 gap-4 lg:grid-cols-12">
            <Form.Group className="col-span-12 lg:col-span-6">
              <Form.Label>Nome</Form.Label>
              <InputV2
                value={updatableFields.firstName}
                onChange={(e) => updateField('firstName', e.target.value)}
                disabled={cannotUpdate}
                error={formErrors.firstName}
              />

              <Form.Message error={formErrors.firstName} />
            </Form.Group>
            <Form.Group className="col-span-12 lg:col-span-6">
              <Form.Label>Último sobrenome</Form.Label>
              <InputV2
                value={updatableFields.lastName}
                onChange={(e) => updateField('lastName', e.target.value)}
                disabled={cannotUpdate}
                error={formErrors.lastName}
              />
              <Form.Message error={formErrors.lastName} />
            </Form.Group>

            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>Sexo</Form.Label>
              <SelectV2
                label="Sexo"
                className="flex h-12 w-full items-center justify-between rounded-md border border-neutral-600 bg-transparent px-2 py-0  text-sm font-normal placeholder:italic"
                value={item.gender}
                disabled
                options={[
                  { label: 'Masculino', value: 'M' },
                  { label: 'Feminino', value: 'F' },
                ]}
              />
            </Form.Group>
            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>Data de Nascimento</Form.Label>
              <InputV2
                value={updatableFields.birthDate}
                onChange={(e) => updateField('birthDate', e.target.value)}
                disabled={cannotUpdate}
              />
              <Form.Message error={formErrors.birthDate} />
            </Form.Group>
            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>Tipo</Form.Label>
              <InputV2 value={item.type} disabled />
            </Form.Group>

            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>Número do CPF</Form.Label>
              <InputV2 value={formatDocumentCPF(item.cpf)} disabled />
            </Form.Group>

            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>Telefone</Form.Label>
              <InputV2 value={formatPhones(item.phone)} disabled />
            </Form.Group>

            <Form.Group className="col-span-12 lg:col-span-4">
              <Form.Label>E-mail</Form.Label>
              <InputV2 value={item.email} disabled />
            </Form.Group>

            <Form.Group className="col-span-12 lg:col-span-12">
              <Form.Label>Observações</Form.Label>
              <InputV2 value={item.note} disabled />
            </Form.Group>

            <Form.Group
              className={`col-span-12 flex flex-row items-center ${cannotUpdate ? 'justify-center' : 'justify-between'} lg:col-span-12`}
            >
              {!cannotUpdate ? (
                <>
                  <ButtonV2
                    size="large"
                    className="w-1/4"
                    ghost
                    onClick={handleCloseModal}
                  >
                    <ButtonV2.Text>Voltar</ButtonV2.Text>
                  </ButtonV2>
                  <ButtonV2 size="large" className="w-1/4" type="submit">
                    <ButtonV2.Text>Salvar</ButtonV2.Text>
                  </ButtonV2>
                </>
              ) : (
                <ButtonV2
                  size="large"
                  className="w-1/4"
                  ghost
                  onClick={handleCloseModal}
                >
                  <ButtonV2.Text>Fechar</ButtonV2.Text>
                </ButtonV2>
              )}
            </Form.Group>
          </div>
        </Form>
      </Modal>
    </>
  );
};
