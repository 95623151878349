import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@components/button';
import LoadingAnimation from '@components/LoadingAnimation';
import { ModalCheckoutConfirm } from '@components/modal/ModalCheckoutConfirm';
import { Form } from '@components/new-components/form';
import api from '@services/api';
import { useData } from '@stores/checkout-context';
import { CheckoutConfirmationBillingV2 } from '../confirmation/billing/confirmation-v2';
import { CheckoutConfirmationCreditCardV2 } from '../confirmation/credit-card/confirmation-v2';
import { PagePath } from '/src/pages-config';

export default function CheckoutConfirm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { data } = useData();

  if (data.bookingId === null) {
    return navigate(PagePath.CheckoutV2);
  }

  const handleAcceptTerms = () => {
    if (!acceptTerms) setShowError(false);
    setAcceptTerms(!acceptTerms);
  };

  async function createCheckout() {
    if (!acceptTerms) {
      setShowError(true);
      return;
    }
    setLoading(true);

    try {
      const token = localStorage.getItem('access_token');
      const {
        value,
        paymentMethod,
        totalWithFee,
        installments,
        brandName,
        payerEmail,
        bookingId,
        referenceId,
      } = data;
      const payload = {
        status: paymentMethod === 'credit-card' ? 'pending' : 'confirmed',
        booking_id: bookingId,
        reference_id: referenceId,
        reference_type: 'fine',
        value: totalWithFee ?? value,
        payment_method: paymentMethod,
        ...(installments && { installments }),
        ...(brandName && { card_brand: brandName.toLowerCase() }),
        ...(payerEmail && { payer_email: payerEmail }),
      };

      const response = await api.post(
        `/checkout`,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data) {
        setModalData(response.data);
      }
    } catch (error) {
      console.error('Erro ao criar o checkout:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className="flex w-full flex-col">
        <div className="flex w-full flex-col rounded-lg bg-white   sm:p-4">
          <div className=" flex w-full flex-col ">
            <span className="text-heading-2 text-primary">
              Os dados estão corretos?
            </span>
            <span className="mt-3 text-body font-normal">
              Confirme os dados e conclua a sua reserva. Caso precise alterar
              algum dado, retorne para a etapa necessária.
            </span>
          </div>
          {data.paymentMethod === 'billing' && (
            <CheckoutConfirmationBillingV2 />
          )}
          {data.paymentMethod === 'credit-card' && (
            <CheckoutConfirmationCreditCardV2 />
          )}
          <div className="mt-4">
            <span className="flex items-center gap-2 text-body font-normal">
              <input
                type="checkbox"
                className="size-5 "
                checked={acceptTerms}
                onChange={handleAcceptTerms}
              />
              <span className="text-neutral-800">
                Li e aceito os{' '}
                <Link
                  to="https://book2b-prod.s3.amazonaws.com/docs/Política+de+cancelamento.pdf"
                  target="_blank"
                >
                  Termos e Condições
                </Link>{' '}
                de compra.
              </span>
            </span>
          </div>
          {showError && (
            <div className="mt-4">
              <Form.Message
                error={{
                  message:
                    'Para prosseguir, leia e aceite os termos e condições de compra',
                }}
              />
            </div>
          )}
        </div>
        <div className="mt-4 flex w-full flex-col ">
          <div className="flex flex-row justify-between">
            <Button
              label="Voltar"
              type="button"
              variant="ghost"
              className="w-2/12"
              onClick={() => {
                navigate(-1);
              }}
            />
            <Button
              label="Confirmar"
              type="button"
              className="w-2/12"
              onClick={createCheckout}
            />
          </div>
        </div>
      </div>
      {modalData && (
        <ModalCheckoutConfirm
          modalData={modalData}
          onClose={() => setModalData(null)}
        />
      )}
    </>
  );
}
