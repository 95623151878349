import { forwardRef, useEffect, useRef, useState } from 'react';
import { Button } from '@components/button';
import { InputText } from '@components/input/InputText';
import { Stepper } from '@components/stepper';

const DropdownVisible = {
  Invisible: 'invisible',
  Visible: 'visible',
};

export const InputTextStepperItem = ({
  name,
  label,
  tip,
  value,
  min,
  max,
  invalid,
  disabled,
  onChange,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="space-x-1">
        <span className="text-heading-3">{label}</span>
        <span className="text-body text-neutral-700">({tip})</span>
      </div>
      <Stepper
        name={name}
        value={value}
        min={min}
        max={max}
        invalid={invalid}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

export const InputTextStepper = forwardRef(function InputTextStepper(
  { children, value, dropdownTitle, dropdownIcon: Icon, ...props },
  ref,
) {
  const dropdownRef = useRef(null);
  const [dropdownStatus, setDropdownStatus] = useState(
    DropdownVisible.Invisible,
  );
  const isDropdownVisible = dropdownStatus === DropdownVisible.Visible;
  const dropdownOpacityClass = isDropdownVisible
    ? 'visible opacity-100'
    : 'invisible opacity-0';

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isDropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setDropdownStatus(DropdownVisible.Invisible);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <div className="relative w-full">
      <InputText
        {...props}
        value={`${value} pessoa(s)`}
        onChange={() => {}}
        readOnly
        onClick={() => setDropdownStatus(DropdownVisible.Visible)}
        ref={ref}
      />
      <div
        className={`top-19 absolute left-0 z-10 w-full overflow-hidden rounded-md border border-solid border-neutral-400 bg-white p-0 shadow ${dropdownOpacityClass}`}
        aria-hidden={!isDropdownVisible}
        ref={dropdownRef}
      >
        <div className="bg-neutral-100 px-5 py-3.5 text-heading-3 text-neutral-800">
          {Icon && <Icon size={20} className="mr-2" />}
          <span>{dropdownTitle}</span>
        </div>
        <div className="space-x-0 space-y-5 border-0 border-b border-solid border-neutral-400 p-4">
          {children}
        </div>
        <div className="flex justify-end px-3 py-4">
          <Button
            variant="small"
            label="Aplicar"
            onClick={() => setDropdownStatus(DropdownVisible.Invisible)}
          />
        </div>
      </div>
    </div>
  );
});
