import { format } from 'date-fns';
import { produce } from 'immer';

import { Modal } from '@components/modal';
import { useBookings } from '@stores/bookings';
import { PassengerType } from '@system/enums';

import { toaster } from '@system/utils/toaster';
import { FormPassenger } from './form-passenger';

const MESSAGES = {
  WARNING:
    'É necessário cadastrar um adulto para cada bebê. 1 bebê por 1 adulto.',
  ERROR:
    'O CPF já foi cadastrado nesta reserva e não pode ser inserido novamente.',
};

export function ModalReservationCreatePassenger() {
  const {
    passengerType,
    tablePrice,
    traveler,
    travelers,
    seat,
    methods: { getSummaryPrices },
  } = useBookings();
  const { qtyAdults, qtyBabies } = getSummaryPrices();

  const handleClose = () => {
    useBookings.setState(
      produce((draft) => {
        draft.seat = null;
        draft.modal.createTravelerVisible = false;
        draft.passengerType = null;
        draft.traveler = null;
      }),
    );
  };

  const saveTraveler = (formData) => {
    const generateLocator = () => `AFB${format(new Date(), 'ddMMyyHHmmss')}`;
    const generateUniqueId = () => new Date().getTime();

    const getAvailableAdult = (travelers) => {
      return travelers.findIndex((t) => {
        return t.type === 'ADT' && t.children === null;
      });
    };

    const validateCPF = (cpf) => {
      const normalizedCPF = cpf.replace(/[._-]/g, '');
      return travelers?.some((t) => {
        return t.document.replace(/[._-]/g, '') === normalizedCPF;
      });
    };

    let locator = '';
    let value = 0;

    if (passengerType === PassengerType.PassengerCommon) {
      value = tablePrice[seat.category][formData.type.toLowerCase()];
      locator = generateLocator();
    }

    const isExceedingBabyLimit =
      passengerType === PassengerType.PassengerBaby &&
      !traveler &&
      qtyBabies + 1 > qtyAdults;

    if (isExceedingBabyLimit) {
      toaster('warning', MESSAGES.WARNING);
      return;
    }

    if (!traveler && validateCPF(formData.document)) {
      toaster('error', MESSAGES.ERROR);
      return;
    }

    if (!traveler) {
      useBookings.setState(
        produce((draft) => {
          const availableAdult = getAvailableAdult(draft.travelers);

          const newTraveler = {
            id: generateUniqueId(),
            locator,
            value,
            document: formData.document,
            name: formData.name,
            lastName: formData.lastName,
            birthdate: formData.birthdate,
            gender: formData.gender,
            type: formData.type,
            phone: formData.phone,
            email: formData.email,
            category: seat ? seat.category : null,
            note: formData.note,
            seat: seat ? [seat.column, seat.row] : null,
            tax: seat ? tablePrice[seat.category].tax : 0,
            ...(formData.type === 'ADT' && {
              children: null,
            }),
          };

          if (passengerType === PassengerType.PassengerBaby) {
            const type = formData.type.toLowerCase();
            const adt = draft.travelers[availableAdult];

            adt.children = newTraveler;
            adt.children.parentId = adt.id;
            adt.children.category = adt.category;
            adt.children.value = tablePrice[adt.category][type];
            adt.children.tax = tablePrice[adt.category].tax;
          }

          draft.travelers.push(newTraveler);
          draft.seat = null;
          draft.traveler = null;
          draft.modal.createTravelerVisible = false;
          draft.passengerType = null;
        }),
      );

      return;
    }

    useBookings.setState(
      produce((draft) => {
        let updatedTraveler = draft.travelers.find((t) => t.id === traveler.id);

        if (updatedTraveler) {
          Object.assign(updatedTraveler, formData);
        }

        draft.seat = null;
        draft.traveler = null;
        draft.modal.createTravelerVisible = false;
        draft.passengerType = null;
      }),
    );
  };

  return (
    <Modal open={true} className=" w-11/12 max-w-7xl md:mx-auto  large:w-9/12 ">
      <Modal.Header
        title={`Dados do ${passengerType === PassengerType.PassengerBaby ? 'bebê (0 a 23 meses)' : 'assento'}`}
        handleClose={handleClose}
      />
      <FormPassenger onSubmit={saveTraveler} />
    </Modal>
  );
}
