import BreadCrumbs from '@components/breadcrumbs';
import { ButtonV2 } from '@components/new-components/button-v2';

export default function PageHeader({
  title,
  paths,
  button,
  buttonClick,
  buttonGhost = false,
}) {
  return (
    <>
      <div className="flex flex-col gap-7">
        <BreadCrumbs paths={paths} />
        {(title || button) && (
          <div className="flex items-center justify-between pb-5">
            <h1 className="m-0 text-heading-1 text-primary">{title}</h1>
            {button && (
              <ButtonV2 size="large" onClick={buttonClick} ghost={buttonGhost}>
                <ButtonV2.Text>{button}</ButtonV2.Text>
              </ButtonV2>
            )}
          </div>
        )}
      </div>
    </>
  );
}
