import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';
import { Alert } from '@components/alert';
import { SummaryTravelersTotalizer } from '@components/checkout/summary-travelers-totalizer';
import { InputRadio } from '@components/input';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@services/api';
import { useBookings } from '@stores/bookings';
import { useCreditAllotment } from '@stores/credit-allotment';
import { capitalizeText, formatCurrency } from '@system/utils';
import { toaster } from '@system/utils/toaster';
yup.setLocale(pt);

const creditCardSchema = yup.object().shape({
  brand: yup.string().required().label('Bandeira'),
  installment: yup.string().required().label('Quantidade de parcelas'),
  payerEmail: yup
    .string()
    .email()
    .required()
    .label('E-mail do pagador')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'E-mail deve ser no formato exemplo@exemplo.com',
    ),
});
export const CheckoutCreditCard = ({ onSubmit }) => {
  const { methods: creditAllotmentMethods } = useCreditAllotment();
  const { booking, methods: bookingMethods, travelers } = useBookings();
  const [installment, setInstallment] = useState(null);
  const [cardBrands, setCardBrands] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [canSelectInstallments, setCanSelectInstallments] = useState(false);
  const { subtotal } = bookingMethods.getSummaryPrices();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(creditCardSchema),
  });

  const getCardBrands = async () => {
    try {
      const { data } = await api.get('/cards/brands');
      setCardBrands((data ?? []).filter((i) => i.status));
    } catch (er) {
      toaster(
        'error',
        er?.message ?? 'Não foi possível buscar as bandeiras de cartões',
      );
    }
  };

  const internalSubmit = (formData) => {
    const installmentInfo = installments?.find(
      (i) => i.value === Number(formData.installment),
    )?._original;
    const brandInfo = cardBrands?.find((i) => i.id === Number(formData.brand));
    onSubmit && onSubmit({ ...formData, installmentInfo, brandInfo });
  };

  const getInstallments = async (cardBrandId) => {
    setInstallments([]);
    try {
      const { data } = await api.get(`/cards/${cardBrandId}/installment-rates`);
      setInstallments(
        data?.installmentRates?.map((installment) => {
          const tax = Number(installment.interestRate) / 100;
          const result = Number(subtotal) / (1 - tax);
          const og = Math.pow(10, 2);
          const totalWithFee = Math.trunc(result * og) / og;
          const installmentValue = totalWithFee / installment.installmentNumber;
          return {
            value: installment.installmentNumber,
            label: `${installment.installmentNumber} ${installment.installmentNumber > 1 ? 'parcelas' : 'parcela'} de ${formatCurrency(installmentValue, true)} (juros de ${installment.interestRate.replace('.', ',')}%). Total a pagar: ${formatCurrency(totalWithFee, true)}`,
            _original: installment,
          };
        }),
      );
    } catch (err) {
      toaster('error', err?.message ?? 'Não foi possível buscar as parcelas');
    }
  };

  const handleCardBrandChange = async (cardBrandId) => {
    setCanSelectInstallments(true);
    setInstallment(null);
    setValue('installment', null);
    await getInstallments(cardBrandId);
  };

  const handleInstallmentsChange = async (event) => {
    useBookings.setState({
      installmentSelected:
        installments.find((i) => i.value === Number(event.target.value))
          ?._original ?? null,
    });

    setInstallment(event.target.value);
    setValue('installment', event.target.value, { shouldValidate: true });
  };

  useEffect(() => {
    creditAllotmentMethods.simulateUse({
      allotment: travelers.length,
    });
    const load = async () => {
      await getCardBrands();
      if (booking?.brandId) {
        setValue('brand', booking.brandId);
        await handleCardBrandChange(booking.brandId);
        setInstallment(Number(booking.installment));
        setValue('installment', booking.installment);
      } else {
        useBookings.setState({ installmentSelected: null });
      }
      if (booking?.payerEmail) setValue('payerEmail', booking.payerEmail);
    };
    load();
  }, []);

  return (
    <>
      <Form id="form-credit-card" onSubmit={handleSubmit(internalSubmit)}>
        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Cartão de Crédito cliente
          </div>
          <div className="mt-2 flex flex-col gap-y-4 pt-3">
            <Alert type="info">
              Ao escolher essa forma de pagamento, o valor total sofrerá um
              acréscimo de juros, que varia por bandeira e pela quantidade de
              parcelas escolhidas.
            </Alert>
            <Form.Group>
              <Form.Label>Bandeira do cartão de crédito</Form.Label>
              <div className="flex flex-row gap-x-6">
                {cardBrands.map((brand) => (
                  <InputRadio
                    label={capitalizeText(brand.name)}
                    key={brand.id}
                    value={brand.id}
                    {...register('brand')}
                    onChange={handleCardBrandChange.bind(this, brand.id)}
                  />
                ))}
              </div>
              <Form.Message error={errors.brand} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Quantidade de parcelas</Form.Label>
              <SelectV2
                options={installments}
                disabled={!canSelectInstallments}
                placeholder="Escolha a quantidade de parcelas"
                {...register('installment')}
                value={installment}
                onChange={handleInstallmentsChange}
              />
              <Form.Message error={errors.installment} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                E-mail do pagador em que o link para pagamento será enviado
              </Form.Label>
              <InputV2
                placeholder="Insira o e-mail do pagador"
                {...register('payerEmail')}
              />
              <Form.Message error={errors.payerEmail} />
            </Form.Group>
          </div>
          <SummaryTravelersTotalizer />
        </div>
      </Form>
    </>
  );
};
