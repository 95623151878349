import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { PagePath } from '@/pages-config';
import Logo from '@components/Logo';
import { useAuthentication } from '@stores/authentication';

export default function LoginLayout() {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const { methods } = useAuthentication();

  const navigate = useNavigate();

  async function getRefreshToken() {
    const accessTokenKey = 'access_token';
    const refreshTokenKey = 'refresh_token';
    const refreshToken = localStorage.getItem(refreshTokenKey);

    if (!refreshToken) {
      setIsCheckingAuth(false);
      return;
    }

    try {
      await methods.refreshToken();
      navigate(PagePath.Home);
    } catch {
      localStorage.removeItem(refreshTokenKey);
      localStorage.removeItem(accessTokenKey);
      setIsCheckingAuth(false);
    }
  }

  useEffect(() => {
    getRefreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCheckingAuth) return null;

  return (
    <div className="flex h-screen items-center justify-center gap-24 bg-background p-2">
      <div className="hidden h-[635px] w-[670px] rounded-[20px] bg-[url('/src/assets/bg-login.jpg')] bg-cover bg-center bg-no-repeat notebook:block" />
      <div className="w-[430px] p-2">
        <Logo />
        <Outlet />
      </div>
    </div>
  );
}
