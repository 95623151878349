import { FlightClassCategory } from '@system/enums';

export const convertFlightClassCategory = (value) => {
  const result = {
    [FlightClassCategory.Economy]: 'Econômica',
    [FlightClassCategory.Business]: 'Executiva',
    [FlightClassCategory.FirstClass]: 'Primeira Classe',
    [FlightClassCategory.Premium]: 'Premium',
  };
  return result[value] ?? '';
};
