import { Button } from '@components/button';
import { Modal } from '@components/modal';

export function ModalWithTwoButtons({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  title = 'Confirmar alterações',
  message = 'Tem certeza de que deseja confirmar as alterações?',
  leftButtonTitle = 'Fechar',
  rightButtonTitle = 'Confirmar alterações',
}) {
  return (
    <Modal size="medium" open={isOpen} handleClose={onClose}>
      <div className="py-7">
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="flex flex-col justify-center gap-4">
            <p className="m-0 text-center text-2xl font-semibold text-neutral-800">
              {title}
            </p>
            <p className="m-0 max-w-[385px] self-center text-center leading-6 text-neutral-800">
              {message}
            </p>
          </div>
          <div className="flex h-[48px] justify-center gap-4">
            <Button
              className="px-4"
              onClick={onClose}
              variant="ghost"
              label={leftButtonTitle}
              type="button"
            />
            <Button
              className="px-4"
              onClick={onConfirm}
              label={rightButtonTitle}
              disabled={isLoading}
              type="button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
