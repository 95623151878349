import { useState, useEffect } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { MdOutlineModeEdit } from 'react-icons/md';
import { PiCaretRightBold } from 'react-icons/pi';
import { useNavigate, useParams } from 'react-router-dom';
import api from '@services/api';
import Globals from '../components/Globals';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AereoFrotaEditar() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingModal] = useState(false);
  const [fabricanteModelo, setFabricanteModelo] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [fabricanteData, setFabricanteData] = useState([]);
  const [aeronave, setAeronave] = useState('');
  const [companhia, setCompanhia] = useState('');
  const [companhiaData, setCompanhiaData] = useState([]);
  const [fabricacao, setFabricacao] = useState('');
  const [ultimaRevisao, setUltimaRevisao] = useState('');
  const [aeroporto, setAeroporto] = useState('');
  const [angarPrincipal, setAngarPrincipal] = useState('');
  const [registro, setRegistro] = useState('');
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const endpoints = [
          {
            url: '/companies',
            setData: setCompanhiaData,
            setDefault: setCompanhia,
            defaultKey: 'id',
            noDataMessage: 'Nenhuma companhia encontrada!',
          },
          {
            url: '/manufacture',
            setData: setFabricanteData,
            setDefault: setFabricante,
            defaultKey: 'id',
            noDataMessage: 'Nenhum fabricante encontrado!',
          },
          {
            url: '/airports',
            setDefault: setAeroporto,
            defaultKey: 'iata',
            noDataMessage: 'Nenhum aeroporto encontrado!',
          },
        ];

        const responses = await Promise.all(
          endpoints.map((endpoint) => api.get(endpoint.url)),
        );

        responses.forEach((response, index) => {
          const { setData, setDefault, defaultKey, noDataMessage } =
            endpoints[index];

          if (response.data.length > 0) {
            if (setData) setData(response.data);
            if (setDefault) setDefault(response.data[0][defaultKey]);
          } else {
            setTypeAlert('alert-danger');
            settitleAlert(noDataMessage);
            setTimeout(() => setTypeAlert(''), 3000);
          }
        });

        const response = await api.get(`/fleet/${id}`, {
          params: { allData: 'true' },
        });

        if (response.data) {
          setFabricante(response.data.fabricante);
          setAeronave(response.data.aircraftName);
          setCompanhia(response.data.companhia);
          setFabricacao(response.data.manufacturing);
          setUltimaRevisao(response.data.lastInspection);
          setAeroporto(response.data.airport);
          setAngarPrincipal(response.data.mainHangar);
          setRegistro(response.data.registry);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Dados não encontrados!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      } catch (error) {
        alert(`Erro ao buscar dados: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchData();
  }, []);

  function closeModal() {
    setFabricanteModelo('');
    setTypeAlert('');
  }

  function validaFormFabricante(e) {
    e.preventDefault();
    setLoading(true);

    if (fabricanteModelo) {
      const value = {
        aircraftName: fabricanteModelo,
      };

      api
        .post('/manufacture', value)
        .then(() => {
          api
            .get('/manufacture')
            .then((response) => {
              setFabricanteData(response.data);
              setLoading(false);
              setOpenModal(false);
              setTypeAlert('alert-success');
              settitleAlert('Dados gravados com Sucesso!');
              setTimeout(() => closeModal(), 5000);
            })
            .catch((error) => alert(error));
        })
        .catch((error) => alert(error));
    }
  }

  function validaForm(e) {
    setLoadingBtn(true);
    e.preventDefault();

    // Prepara os dados para envio
    const payload = {
      fabricante,
      nomeAeronave: aeronave,
      companhia,
      fabricacao,
      ultimaRevisao,
      aeroporto,
      angarPrincipal,
      registro,
    };

    if (payload.fabricacao === '0000-00-00') {
      delete payload.fabricacao;
    }

    if (payload.ultimaRevisao === '0000-00-00') {
      delete payload.ultimaRevisao;
    }
    api
      .patch(`/fleet/${id}`, payload)
      .then((response) => {
        if (response.status === 200) {
          setLoadingBtn(false);
          navigate(PagePath.AereoFrotaAssentos.replace(':id', id));
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Erro na Gravação!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      })
      .catch((error) => {
        alert(`Erro ao atualizar dados: ${error.message}`);
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  }

  function changeFabricante(e) {
    if (e == 'new') {
      setOpenModal(true);
    } else {
      setFabricante(e);
    }
  }

  function editFabricante(id, fabricante) {
    for (let i = 0; i < fabricanteData.length; i = i + 1) {
      if (fabricanteData[i].id != id) {
        document.getElementById(
          'inputFabricante' + fabricanteData[i].id,
        ).style.display = 'none';
        document.getElementById(
          'nomeFabricante' + fabricanteData[i].id,
        ).style.display = 'block';
        document.getElementById(
          'btnEditFabricante' + fabricanteData[i].id,
        ).style.opacity = '0';
      }
    }
    document.getElementById('btnEditFabricante' + id).style.opacity = '1';
    document.getElementById('nomeFabricante' + id).style.display = 'none';
    const elem = document.getElementById('inputFabricante' + id);
    elem.style.display = 'block';
    if (elem.value == '') {
      elem.value = fabricante;
    }
    elem.focus();
  }

  function limpaFabricante() {
    for (let i = 0; i < fabricanteData.length; i = i + 1) {
      document.getElementById(
        'inputFabricante' + fabricanteData[i].id,
      ).style.display = 'none';
      document.getElementById(
        'nomeFabricante' + fabricanteData[i].id,
      ).style.display = 'block';
      document.getElementById(
        'btnEditFabricante' + fabricanteData[i].id,
      ).style.opacity = '0';
    }
  }

  function updateFabricante(idEidt) {
    if (document.getElementById('inputFabricante' + idEidt).value) {
      setLoading(true);
      setTimeout(function () {
        const value = {
          aircraftName: document.getElementById('inputFabricante' + idEidt)
            .value,
        };

        api
          .patch(`/manufacture/${idEidt}`, value)
          .then((response) => {
            if (response.status === 201) {
              api
                .get('/manufacture')
                .then((response) => {
                  setFabricanteData(response.data);
                  setLoading(false);
                  setOpenModal(false);
                  setTypeAlert('alert-success');
                  settitleAlert('Dados alterados com Sucesso!');
                  setTimeout(() => closeModal(), 5000);
                })
                .catch((error) => alert(error));
            } else {
              setTypeAlert('alert-danger');
              settitleAlert('Erro na Gravação!');
              setTimeout(() => setTypeAlert(''), 3000);
            }
          })
          .catch((error) => alert(error));
      }, 1000);
    }
  }

  return (
    <>
      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal">
          <GrClose className="closeModal" onClick={() => setOpenModal(false)} />
          <h5>Cadastro Fabricante/Modelo</h5>
          <form
            onSubmit={(e) => validaFormFabricante(e)}
            onFocus={() => limpaFabricante()}
          >
            <div className="row mb-3 mt-3">
              <div className="col-9 mr-0 pr-0">
                <input
                  type="text"
                  required
                  placeholder="Fabricante/Modelo"
                  value={fabricanteModelo}
                  onChange={(e) => setFabricanteModelo(e.target.value)}
                ></input>
              </div>
              <div className="col  ml-0 pl-0">
                <button type="submit">
                  <span className={loadingModal ? 'hide' : ''}>
                    <BsCheckLg />
                  </span>
                  <span className={loadingModal ? 'loader' : 'hide'}></span>
                </button>
              </div>
            </div>
          </form>

          <div className="listModal">
            <table className="table-hover table">
              <tbody>
                {fabricanteData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.id}</td>
                    <td
                      onMouseOver={() =>
                        editFabricante(data.id, data.aircraftName)
                      }
                    >
                      <input
                        type="text"
                        required
                        id={'inputFabricante' + data.id}
                        style={{ display: 'none' }}
                      ></input>
                      <span id={'nomeFabricante' + data.id}>
                        {data.aircraftName}
                      </span>
                    </td>
                    <td>
                      <MdOutlineModeEdit
                        className="icoEdit"
                        id={'btnEditFabricante' + data.id}
                        onClick={() => updateFabricante(data.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Frotas',
            link: PagePath.AereoFrota,
          },
          {
            label: 'Editar Aeronave',
            link: PagePath.AereoFrotaEditar,
          },
        ]}
        title="Aeronave"
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="row">
                <div className="col-4">
                  <label>Fabricante/Modelo</label>
                  <select
                    name="select"
                    value={fabricante}
                    onChange={(e) => changeFabricante(e.target.value)}
                  >
                    <option disabled></option>
                    <option value="new">❯ Cadastrar/Editar</option>
                    <option disabled>━━━━━━━━━━━━━━</option>
                    {fabricanteData.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.aircraftName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-5">
                  <label>Companhia Aérea</label>
                  <select
                    name="select"
                    value={companhia}
                    onChange={(e) => setCompanhia(e.target.value)}
                  >
                    {companhiaData.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-3">
                  <button type="submit">
                    <span className={loadingBtn ? 'hide' : ''}>
                      <PiCaretRightBold /> Avançar
                    </span>
                    <span className={loadingBtn ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
