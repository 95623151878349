import toast from 'react-hot-toast';
import { ToastMessage } from '@components/toast';

export const toaster = (type, message, options = {}) => {
  if (!['success', 'error', 'warning', 'info'].includes(type)) {
    return console.error('toast não mapeado');
  }

  return toast.custom(<ToastMessage type={type} message={message} />, options);
};
