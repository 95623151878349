import { useBookings } from '@stores/bookings';
import { formatCurrency } from '@system/utils';

export const CheckoutConfirmationBilling = () => {
  const { travelers } = useBookings();
  const total = (travelers ?? [])
    ?.map((t) => Number(t.value))
    .reduce((prev, cur) => prev + cur, 0);
  return (
    <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
      <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
        Formas de pagamento
      </div>
      <div className="mt-2 pt-3">
        <span className="text-body font-semibold text-neutral-800">
          Faturamento
        </span>
        <br />
        <span className="text-body font-normal text-neutral-800">
          Nosso financeiro vai entrar em contato e enviar todos os valores a
          serem pagos pela agência.
        </span>
      </div>
      <div className="mt-2 flex flex-col gap-y-4 pt-3">
        <div className="text-heading-3 font-semibold text-price">
          Valor total: {formatCurrency(total)}
        </div>
      </div>
    </div>
  );
};
