/**
 * Função para buscar o nome de um aeroporto pelo código IATA.
 * @param {string} iata - Código IATA do aeroporto.
 * @param {Array} airports - Array de objetos contendo os aeroportos disponíveis.
 * @returns {string|null} - Nome do aeroporto ou null se não encontrado.
 */
export function getAirportNameByIata(iata, airports) {
  const airport = airports.find((airport) => airport.iata === iata);

  return airport ? airport.nome : null;
}
