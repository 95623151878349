import { BookingStatus } from '@system/enums';
export const convertBookingStatusColor = (status) => {
  const converter = {
    [BookingStatus.Created]: 'bg-warning-100 text-warning-500',
    [BookingStatus.Confirmed]: 'bg-[#E6FBF4] text-[#007D6D]',
    [BookingStatus.Paid]: 'bg-[#E6FBF4] text-[#007D6D]',
    [BookingStatus.Expired]: 'bg-[#FFEBE8] text-[#A43424]',
    [BookingStatus.Canceled]: 'bg-[#FFEBE8] text-[#A43424]',
  };
  return converter[status] ?? '';
};
