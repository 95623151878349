import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchInputV2 } from '@components/search-input/search-input-v2';
import api from '@services/api';
import { PagePath } from '../../pages-config';

export function SearchReservationTopNavBar({ handleMenuClick }) {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const errorRef = useRef(null);
  const inputContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (errorRef.current && !errorRef.current.contains(event.target)) {
      setError('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchReservation = async () => {
    setError('');
    const params = {
      codBooking: code,
    };

    const { data } = await api.get('/bookings', {
      params,
    });

    if (!data || data.total === 0 || !data.result?.length) {
      setError(
        `Ops, não encontramos resultados para ${code}. Digite um número válido de reserva.`,
      );
    } else {
      const result = data.result[0];
      navigate(PagePath.DetalhesReserva.replace(':id', result.id));
      handleMenuClick && handleMenuClick();
    }
  };

  return (
    <SearchInputV2
      height="h-[40px]"
      placeholder="Buscar por código da reserva"
      handleSearch={searchReservation}
      onChange={(e) => setCode(e?.target?.value)}
      error={error}
      ref={errorRef}
      setError={setError}
    />
  );
}
