import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'm-0 mb-2 text-sm font-medium text-neutral-800',
});
export const FormLabel = ({ children, className, ...props }) => (
  <label className={styles({ class: className })} {...props}>
    {children}
  </label>
);
FormLabel.displayName = 'FormLabel';
