import { tv } from 'tailwind-variants';
import './Tag.scss';
const style = tv({
  base: 'tag-default',
  variants: {
    type: {
      info: 'tag-info',
      error: 'tag-error',
      warning: 'tag-warning',
      success: 'tag-success',
      soon: 'tag-soon',
    },
  },
});
export default function Tag({ value, type = 'info', className }) {
  return <div className={style({ type, className })}>{value}</div>;
}
