import { useEffect, useState } from 'react';
import { HeaderV2 } from '../HeaderV2';
import { Menu } from '../Menu';

export function DashboardV2({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (typeof window.FreshworksWidget === 'function') {
      window.FreshworksWidget('hide', 'launcher');
    }
  }, [window.FreshworksWidget]);

  function handleMenuClick() {
    setIsMenuOpen((prev) => !prev);
  }

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="relative shadow-header">
        <HeaderV2 isMenuOpen={isMenuOpen} handleMenuClick={handleMenuClick} />
        <Menu isMenuOpen={isMenuOpen} handleMenuClick={handleMenuClick} />
      </div>
      <div className="flex h-screen">
        <div className="no-scrollbar w-full overflow-auto p-5">{children}</div>
      </div>
    </div>
  );
}
