import { create } from 'zustand';
import api from '@services/api';
import { useAuthentication } from './authentication';

export const useCreditAllotment = create((set, get) => ({
  credit: {
    limit: 0,
    used: 0,
  },
  allotment: {
    limit: 0,
    used: 0,
  },
  insufficientCredit: false,
  insufficientAllotment: false,
  paymentMethodsAllowed: {
    invoicing: false,
    creditCard: false,
  },

  methods: {
    getLimits: async () => {
      const {
        agency: { id: agencyId },
      } = useAuthentication.getState().session ?? {};

      const { data } = await api.get(`/agencies/${agencyId}/limits`);

      set({
        ...data,
        insufficientAllotment:
          Number(data.allotment.used) >= Number(data.allotment.limit),
        insufficientCredit:
          Number(data.credit.used) >= Number(data.credit.limit),
      });
    },
    getPaymentMethods: async () => {
      const {
        agency: { id: agencyId },
      } = useAuthentication.getState().session ?? {};

      const { data } = await api.get(`/agencies/${agencyId}/payment-methods`);

      set({
        ...data,
        paymentMethodsAllowed: {
          invoicing:
            data?.filter((method) => {
              return method.paymentMethod === 'invoicing';
            }).length > 0,
          creditCard:
            data?.filter((method) => {
              return method.paymentMethod === 'client_credit_card';
            }).length > 0,
        },
      });
    },
    simulateUse: async ({ allotment: pAllotment = 0, credit: pCredit = 0 }) => {
      await get().methods.getLimits();

      const { allotment, credit } = get();
      let newAllotment = Number(allotment.used) + pAllotment;
      let newCredit = Number(credit.used) + pCredit;

      if (newAllotment >= allotment.limit)
        newAllotment = Number(allotment.limit);
      if (newCredit >= credit.limit) newCredit = Number(credit.limit);

      set({
        allotment: {
          ...allotment,
          used: newAllotment,
        },
        credit: {
          ...credit,
          used: newCredit,
        },
        insufficientAllotment: newAllotment >= allotment.limit,
        insufficientCredit: newCredit >= credit.limit,
      });
    },
  },
}));
