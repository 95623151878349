import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { Alert } from '@components/alert';
import { Button } from '@components/button';
import { useBookings } from '@stores/bookings';
import { PassengerType } from '@system/enums';
import { PagePath } from '../../pages-config';
import ReservationTravelersList from './reservation-passenger-list';
export const ReservationPassenger = () => {
  const {
    travelers,
    methods: { getSummaryPrices },
  } = useBookings();
  const [needAdults, setNeedAdults] = useState(false);
  const { qtyAdults, qtyBabies } = getSummaryPrices();
  const { state } = useLocation();
  const navigate = useNavigate();
  const confirmTravelers = () => {
    navigate(PagePath.AereoReservasPagamento, { state });
  };

  const validateCanAddBabies = () => {
    if (qtyBabies + 1 > qtyAdults) {
      setNeedAdults(true);
      return;
    } else {
      setNeedAdults(false);
    }
  };
  const addBaby = () => {
    validateCanAddBabies();
    if (needAdults) return;

    useBookings.setState(
      produce((draft) => {
        draft.seat = null;
        draft.modal.createTravelerVisible = true;
        draft.passengerType = PassengerType.PassengerBaby;
      }),
    );
  };

  useEffect(() => {
    validateCanAddBabies();
  }, [travelers]);

  return (
    <>
      <div className="sticky left-0 right-0 top-4 flex max-h-[calc(100vh-90px)] w-full  flex-col ">
        <div className="   w-full flex-col flex-wrap self-start overflow-y-auto  rounded-md border border-solid !border-neutral-300 bg-white p-4  ">
          <div className="  mb-4 border-0 border-b border-solid border-[#EBEBEB] pb-3 text-heading-3 text-primary">
            Passageiros a confirmar{' '}
            {travelers?.length > 0 ? `(${travelers.length})` : ''}
          </div>
          {travelers.length === 0 && (
            <div className=" text-body text-neutral-800">
              Nenhum passageiro adicionado.
            </div>
          )}
          {travelers.length > 0 && (
            <>
              <ReservationTravelersList />
              <div className="mt-4 cursor-pointer" onClick={addBaby}>
                <a className="w-full">Adicionar bebê</a>
              </div>
              {needAdults && (
                <div className="mt-2">
                  <Alert type="error">
                    Para adicionar mais um bebê, é necessário ter mais um
                    passageiro adulto.
                  </Alert>
                </div>
              )}
            </>
          )}
        </div>
        {travelers.length > 0 && (
          <div className="mt-4 w-full">
            <Alert fullWidth type="warning">
              A reserva fica disponível para pagamento até às 23:59 de hoje,{' '}
              {dayjs().format('DD/MM/YYYY')}.
            </Alert>
          </div>
        )}
        {travelers.length > 0 && (
          <>
            <div className=" mt-4 w-full">
              <Button
                className="w-full"
                variant="default"
                label="Continuar"
                onClick={confirmTravelers}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
