export function formatScale(totalScale, totalConnection) {
  if (totalScale == '1') {
    return totalScale + ' parada';
  } else if (totalScale > '1') {
    return totalScale + ' paradas';
  } else if (totalConnection == '1') {
    return totalConnection + ' parada';
  } else if (totalConnection > '1') {
    return totalConnection + ' paradas';
  } else {
    return 'Direto';
  }
}

export function formatAirportScale(
  airportConnection,
  originScale,
  destinationScale,
  totalConnection,
  totalScale,
) {
  if (totalConnection >= 1) {
    return totalConnection;
  } else if (totalScale >= 1) {
    return totalScale;
  } else if (airportConnection) {
    return airportConnection;
  } else if (originScale && destinationScale) {
    return originScale;
  } else {
    return 0;
  }
}

export function formatScaleOnlyStop(totalScale, totalConnection) {
  const total = totalScale + totalConnection;
  if (total > 0) {
    return `${total} parada${total > 1 ? 's' : ''}`;
  } else {
    return 'Direto';
  }
}
