import { useAuthentication } from '@stores/authentication';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PagePath } from '@/pages-config';
import LoadingAnimation from '@components/LoadingAnimation';

export default function GoogleCallback() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { methods } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();

  const loading = useRef(false);

  const handleGoogleLogin = async () => {
    if (!code || loading.current) return;

    loading.current = true;

    try {
      const userHasAuthenticated = await methods.signIn({}, 'google', code);
      if (!userHasAuthenticated) return;

      if (location.state?.from) {
        window.location = location.state?.from;
      } else {
        navigate(PagePath.Home);
      }
    } catch (error) {
      console.log(error);

      navigate('/');
    } finally {
      loading.current = false;
    }
  };

  useEffect(() => {
    handleGoogleLogin();
  }, []);

  return <div>{loading.current && <LoadingAnimation />}</div>;
}
