import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { pt } from 'yup-locale-pt';
import { Alert } from '@components/alert';
import { FineTotalizer } from '@components/checkout/fine';
import { InputRadio } from '@components/input';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '@services/api';
import { useData } from '@stores/checkout-context';
import { capitalizeText, formatCurrency } from '@system/utils';
import { toaster } from '@system/utils/toaster';
yup.setLocale(pt);

const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const creditCardSchema = yup.object({
  brand: yup.string().label('Bandeira').required(),
  installment: yup.string().label('Quantidade de parcelas').required(),
  payerEmail: yup
    .string()
    .label('E-mail do pagador')
    .email('E-mail inválido')
    .matches(emailPattern, 'E-mail deve ser no formato exemplo@exemplo.com')
    .required(),
});

export const CheckoutCreditCardV2 = ({ onSubmit }) => {
  const [installment, setInstallment] = useState(null);
  const [cardBrands, setCardBrands] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [canSelectInstallments, setCanSelectInstallments] = useState(false);
  const { state: params } = useLocation();
  const { setTotalValues, updateData } = useData();
  const previousEmailRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(creditCardSchema),
  });

  const payerEmail = watch('payerEmail');

  const getCardBrands = async () => {
    try {
      const { data } = await api.get('/cards/brands');
      setCardBrands((data ?? []).filter((i) => i.status));
    } catch (er) {
      toaster(
        'error',
        er?.message ?? 'Não foi possível buscar as bandeiras de cartões',
      );
    }
  };

  const internalSubmit = (formData) => {
    const installmentInfo = installments?.find(
      (i) => i.value === Number(formData.installment),
    )?._original;
    const brandInfo = cardBrands?.find((i) => i.id === Number(formData.brand));

    updateData({
      brandName: brandInfo?.name,
      payerEmail: formData.payerEmail,
    });

    onSubmit && onSubmit({ ...formData, installmentInfo, brandInfo });
  };

  const getInstallments = async (cardBrandId) => {
    setInstallments([]);

    try {
      const { data } = await api.get(`/cards/${cardBrandId}/installment-rates`);
      const totalWithoutFee = Number(params.value);

      const newInstallments = data?.installmentRates?.map((installment) => {
        const totalInstallments = installment.installmentNumber;
        const taxRate = parseFloat(installment.interestRate) / 100;
        const totalWithFee = calculateTotalWithFee(totalWithoutFee, taxRate);
        const installmentValue = totalWithFee / totalInstallments;

        return {
          value: totalInstallments,
          label: generateLabel(
            totalInstallments,
            installmentValue,
            installment.interestRate,
            totalWithFee,
          ),
          totalWithoutFee,
          totalWithFee,
          tax: `${installment.interestRate.replace('.', ',')}%`,
          installmentValue,
        };
      });

      setInstallments(newInstallments);
    } catch (err) {
      toaster('error', err?.message ?? 'Não foi possível buscar as parcelas');
    }
  };

  const calculateTotalWithFee = (amount, taxRate) => {
    const result = amount / (1 - taxRate);
    const factor = Math.pow(10, 2);
    return Math.round(result * factor) / factor;
  };

  const generateLabel = (
    totalInstallments,
    installmentValue,
    interestRate,
    totalWithFee,
  ) => {
    const installmentsLabel = totalInstallments > 1 ? 'parcelas' : 'parcela';
    const formattedInterestRate = interestRate.replace('.', ',');

    return `${totalInstallments} ${installmentsLabel} de ${formatCurrency(installmentValue, true)} (juros de ${formattedInterestRate}%). <b>Total a pagar: ${formatCurrency(totalWithFee, true)}</b>`;
  };

  const handleCardBrandChange = async (cardBrandId) => {
    setCanSelectInstallments(true);
    setInstallment(null);
    setValue('installment', null);
    const brandInfo = cardBrands.find((brand) => brand.id === cardBrandId);
    updateData({ brandName: brandInfo?.name });

    await getInstallments(cardBrandId);
  };

  const handleInstallmentsChange = (event) => {
    const selectedInstallment = installments.find(
      (i) => i.value === Number(event.target.value),
    );

    if (selectedInstallment) {
      setTotalValues(
        selectedInstallment.value,
        selectedInstallment.installmentValue,
        selectedInstallment.totalWithoutFee,
        selectedInstallment.totalWithFee,
        selectedInstallment.tax,
      );
    }

    setInstallment(event.target.value);
    setValue('installment', event.target.value, { shouldValidate: true });
  };

  useEffect(() => {
    if (payerEmail && payerEmail !== previousEmailRef.current) {
      updateData({ payerEmail });
      previousEmailRef.current = payerEmail;
    }
  }, [payerEmail, updateData]);

  useEffect(() => {
    const load = async () => {
      await getCardBrands();
    };
    load();
  }, []);

  return (
    <>
      <Form id="form-credit-card" onSubmit={handleSubmit(internalSubmit)}>
        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Cartão de crédito cliente
          </div>
          <div className="mt-2 flex flex-col gap-y-4 pt-3">
            <Alert type="info">
              Ao escolher essa forma de pagamento, o valor total{' '}
              <b>sofrerá um acréscimo de juros</b>, que varia por bandeira e
              pela quantidade de parcelas escolhidas.
            </Alert>
            <Form.Group>
              <Form.Label>Bandeira do cartão de crédito</Form.Label>
              <div className="flex flex-row gap-x-6">
                {cardBrands.map((brand) => (
                  <InputRadio
                    label={capitalizeText(brand.name)}
                    key={brand.id}
                    value={brand.id}
                    {...register('brand')}
                    onChange={handleCardBrandChange.bind(this, brand.id)}
                  />
                ))}
              </div>
              <Form.Message error={errors.brand} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Quantidade de parcelas</Form.Label>
              <SelectV2
                options={installments}
                disabled={!canSelectInstallments}
                placeholder="Escolha a quantidade de parcelas"
                {...register('installment')}
                value={installment}
                onChange={handleInstallmentsChange}
              />
              <Form.Message error={errors.installment} />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                E-mail do pagador em que o link para pagamento será enviado
              </Form.Label>
              <InputV2
                placeholder="Insira o e-mail do pagador"
                {...register('payerEmail')}
              />
              <Form.Message error={errors.payerEmail} />
            </Form.Group>
          </div>
          {installment && <FineTotalizer />}
        </div>
      </Form>
    </>
  );
};
