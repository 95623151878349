import { tv } from 'tailwind-variants';

const styles = tv({
  base: '',
});
export const FormBase = ({ children, className, ...rest }) => (
  <form noValidate className={styles({ class: className })} {...rest}>
    {children}
  </form>
);
