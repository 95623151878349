export const finesRefundAlertTexts = {
  refund: {
    review: {
      text: 'A análise do valor do reembolso está sendo feita pela equipe da ViagensPromo. Fique atento as atualizações.',
      type: 'warning',
    },
    pending: {
      text: 'Análise finalizada. O valor do reembolso foi aprovado.',
      type: 'success',
    },
    negotiate: {
      text: 'Análise finalizada. O valor do reembolso foi negociado e o valor foi alterado.',
      type: 'success',
    },
    canceled: {
      text: 'Análise finalizada. O valor do reembolso foi cancelado e o cliente está isento de receber o valor.',
      type: 'success',
    },
  },
  fines: {
    review: {
      text: 'A análise da multa de cancelamento está sendo feita pela equipe da ViagensPromo. Fique atento as atualizações.',
      type: 'warning',
    },
    pending: {
      text: 'Análise finalizada. O valor da multa de cancelamento foi aprovado.',
      type: 'success',
    },
    negotiate: {
      text: 'Análise finalizada. O valor da multa de cancelamento foi negociado e o valor foi alterado.',
      type: 'success',
    },
    canceled: {
      text: 'Análise finalizada. O valor da multa de cancelamento foi cancelado e o cliente está isento do pagamento.',
      type: 'success',
    },
  },
};

export const finesRefundModalTexts = {
  refund: {
    canceled: {
      head: 'Tem certeza que deseja cancelar o reembolso no valor de {$}?',
      label:
        'Cancelando esse reembolso, a agência não irá receber mais esse valor',
      button: 'Cancelar reembolso',
      status: 'canceled',
    },
    deal: {
      head: 'Insira o novo valor do reembolso',
      label:
        'Alterando o valor do reembolso, este será o novo valor que a agência irá receber',
      inputLabel: 'Novo valor do reembolso',
      button: 'Confirmar novo valor',
      status: 'pending',
    },
    approve: {
      head: 'Tem certeza que deseja aprovar o reembolso no valor de {$}?',
      label:
        'Aprovando o reembolso, este será o valor que a agência irá receber',
      button: 'Aprovar reembolso',
      status: 'pending',
    },
    inform: {
      head: 'Informar pagamento',
      button: 'Salvar',
      status: 'confirmed',
    },
  },
  fines: {
    canceled: {
      head: 'Tem certeza que deseja cancelar a multa no valor de {$}?',
      label:
        'Cancelando essa multa, a agência não terá necessidade de realizar este pagamento',
      button: 'Cancelar multa',
      status: 'canceled',
    },
    deal: {
      head: 'Insira o novo valor da multa',
      label:
        'Alterando o valor da multa, este será o novo valor que a agência deverá fazer o pagamento',
      inputLabel: 'Novo valor da multa',
      button: 'Confirmar novo valor',
      status: 'pending',
    },
    approve: {
      head: 'Tem certeza que deseja aprovar a multa no valor de {$}?',
      label:
        'Aprovando essa multa, este será o valor que a agência deverá fazer o pagamento',
      button: 'Aprovar multa',
      status: 'pending',
    },
  },
};

export const finesRefundPaymentMethodsOptions = [
  { label: 'Estorno de cartão de crédito', value: 'CartaodeCredito' },
  { label: 'PIX', value: 'PIX' },
  { label: 'Transferência', value: 'Transferencia' },
  { label: 'Crédito de faturamento', value: 'Faturamento' },
];

export const finesRefundPaymentMethods = {
  CartaodeCredito: 'Cartão de Crédito',
  Faturamento: 'Faturamento',
  PIX: 'Pix',
  Transferencia: 'Transferência',
};
