import { useEffect } from 'react';
import { Alert } from '@components/alert';
import { useBookings } from '@stores/bookings';
import { useCreditAllotment } from '@stores/credit-allotment';
import { formatCurrency } from '@system/utils';

export const CheckoutBilling = () => {
  const { travelers, methods: bookingMethods } = useBookings();
  const { insufficientCredit, methods: creditAllotmentMethods } =
    useCreditAllotment();
  const { total } = bookingMethods.getSummaryPrices();

  useEffect(() => {
    creditAllotmentMethods.simulateUse({
      allotment: travelers.length,
      credit: total,
    });
  }, []);

  return (
    <>
      <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
        <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
          Faturamento
        </div>
        <div className="mt-2 flex flex-col gap-y-4 pt-3">
          <div className="text-heading-3 font-semibold text-price">
            Valor total: {formatCurrency(total)}
          </div>
        </div>
      </div>
      {insufficientCredit && (
        <div className="mt-4">
          <Alert type="error">
            A agência não tem créditos suficiente para fazer essa reserva.
            <br /> Entre em contato com a ViagensPromo e solicite a aprovação de
            mais créditos.
          </Alert>
        </div>
      )}
    </>
  );
};
