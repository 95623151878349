import { Button } from '@components/button';
import { Modal } from '@components/modal';

export function ModalUpdateConfirmation({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}) {
  return (
    <Modal size="medium" open={isOpen} handleClose={onClose}>
      <div className="py-7">
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="flex flex-col justify-center gap-4">
            <p className="m-0 text-center text-2xl font-semibold text-neutral-800">
              Confirmar alterações nos dados do usuário
            </p>
            <p className="m-0 max-w-[385px] self-center text-center leading-6 text-neutral-800">
              Tem certeza de que deseja confirmar as alterações nos dados do
              usuário?
            </p>
          </div>
          <div className="flex h-[48px] justify-center gap-4">
            <Button
              className="px-4"
              onClick={onClose}
              variant="ghost"
              label="Fechar"
              type="button"
            />
            <Button
              className="px-4"
              onClick={onConfirm}
              label="Confirmar alterações"
              disabled={isLoading}
              type="button"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
