import { PaymentMethod } from '@system/enums';
import { CheckoutBillingV2 } from '../billing/billing-v2';
import { CheckoutCreditCardV2 } from '../credit-card/credit-card-v2';

export default function PaymentForm({ selectedMethod, onSubmit }) {
  return (
    <div className="mt-4">
      {selectedMethod === PaymentMethod.Faturamento && <CheckoutBillingV2 />}
      {selectedMethod === PaymentMethod['Cartão de Crédito'] && (
        <CheckoutCreditCardV2 onSubmit={onSubmit} />
      )}
    </div>
  );
}
