import Globals from '../components/Globals';
import { useState, useEffect } from 'react';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import LoadingAnimation from '../components/LoadingAnimation';
import { FiEdit } from 'react-icons/fi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { BiSolidEdit } from 'react-icons/bi';
import { PagePath } from '../pages-config';
import api from '@services/api';
import PageHeader from '@components/page-header';

export default function AdminServicosBordo() {
  const [endpoint] = useState(Globals.endPoint);
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [servicoBordo, setServicoBordo] = useState('');
  const [idData, setIdData] = useState('');
  const [buscaFiltro, setBuscaFiltro] = useState('');
  const [newData, setNewData] = useState(true);
  const [listData, seListData] = useState([]);
  const [listTotal, setListTotal] = useState([]);
  const [masterTitle] = useState('Serviço de Bordo');

  useEffect(() => {
    setLoading(true);
    let query =
      'SELECT * FROM `vpcharter_servico_bordo` ORDER BY `servico_bordo` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setListTotal(response.data.length);
        }
      })
      .catch((error) => alert(error));
    setLoading(false);
  }, []);

  function limpaForm() {
    setLoading(true);
    setNewData(true);
    setIdData('');
    setServicoBordo('');
    setTimeout(() => setLoading(false), 1000);
  }

  function validaForm(e) {
    setLoading(true);
    e.preventDefault();

    setTimeout(function () {
      if (!newData) {
        var query =
          "UPDATE `vpcharter_servico_bordo` SET `servico_bordo` = '" +
          servicoBordo.trim() +
          "' WHERE `vpcharter_servico_bordo`.`id` = '" +
          idData +
          "';";
        var fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              setTypeAlert('alert-success');
              settitleAlert('Sucesso!');
              setTxtAlert('Alterado com sucesso.');
              setTimeout(() => setTypeAlert(''), 3000);
              limpaForm();
              let query =
                'SELECT * FROM `vpcharter_servico_bordo` ORDER BY `servico_bordo` ';
              let fData = new FormData();
              fData.append('query', query);
              api
                .post(endpoint + '/query', fData)
                .then((response) => {
                  if (response.data.length > 0) {
                    limpaForm();
                    seListData(response.data);
                  }
                })
                .catch((error) => alert(error));
            }
          })
          .catch((error) => alert(error));
      } else {
        var query =
          "SELECT * FROM `vpcharter_servico_bordo` WHERE `id` = '" +
          idData +
          "' ";
        var fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.data.length > 0) {
              setTypeAlert('alert-danger');
              settitleAlert('Aeroporto cadastrado!');
              setTxtAlert('Por favor, verifique os dados.');
              setTimeout(() => setTypeAlert(''), 3000);
            } else {
              let query =
                "INSERT INTO `vpcharter_servico_bordo` (`id`, `servico_bordo`) VALUES (NULL, '" +
                servicoBordo +
                "') ";
              let fData = new FormData();
              fData.append('query', query);
              api
                .post(endpoint + '/query', fData)
                .then((response) => {
                  if (response.status === 201) {
                    setTypeAlert('alert-success');
                    settitleAlert('Sucesso!');
                    setTxtAlert('Cadastrado com sucesso.');
                    setTimeout(() => setTypeAlert(''), 3000);
                    limpaForm();
                    let query =
                      'SELECT * FROM `vpcharter_servico_bordo` ORDER BY `servico_bordo` ';
                    let fData = new FormData();
                    fData.append('query', query);
                    api
                      .post(endpoint + '/query', fData)
                      .then((response) => {
                        if (response.data.length > 0) {
                          seListData(response.data);
                        }
                      })
                      .catch((error) => alert(error));
                  } else {
                    setTypeAlert('alert-danger');
                    settitleAlert('Erro na Gravação!');
                    setTimeout(() => setTypeAlert(''), 3000);
                  }
                })
                .catch((error) => alert(error));
            }
          })
          .catch((error) => alert(error));
      }
      setLoading(false);
    }, 1000);
  }

  function editaData(idData, servicoBordo) {
    setLoading(true);
    limpaForm();
    setNewData(false);
    setIdData(idData);
    setServicoBordo(servicoBordo);
    setTimeout(() => setLoading(false), 1000);
  }

  function listSearch() {
    setLoading(true);
    setTypeAlert('');
    setIdData('');
    let query =
      "SELECT * FROM `vpcharter_servico_bordo` WHERE `servico_bordo` LIKE '%" +
      buscaFiltro +
      "%' ORDER BY `servico_bordo` ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListData(response.data);
          setLoading(false);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Dados não encontrados!');
          setTimeout(() => setTypeAlert(''), 3000);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }
  function changeBuscaFiltro(e) {
    setBuscaFiltro(e);
    setTypeAlert('');
    if (e.trim().length == 0) {
      setLoading(true);
      let query =
        'SELECT * FROM `vpcharter_servico_bordo` ORDER BY `servico_bordo` ';
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then((response) => {
          if (response.data.length > 0) {
            seListData(response.data);
            setListTotal(response.data.length);
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    }
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      listSearch();
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Serviços de Bordo',
            link: PagePath.AdminServicosBordo,
          },
        ]}
        title={masterTitle}
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="row">
                <div className="col-9">
                  <label>Característica</label>
                  <input
                    type="text"
                    value={servicoBordo}
                    onChange={(e) => setServicoBordo(e.target.value)}
                    required
                  />
                </div>

                <div className="col-3">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <span className={newData ? '' : 'hide'}>
                        <PiCaretRightBold /> Salvar
                      </span>
                      <span className={!newData ? '' : 'hide'}>
                        <FiEdit /> Alterar
                      </span>
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{masterTitle}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={buscaFiltro}
                onChange={(e) => changeBuscaFiltro(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={() => listSearch()} />
            </div>
            <div className="rightBtn">
              <div>{listData.length}</div>
              <div>de</div>
              <div className="listTotal">{listTotal}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={() => limpaForm()}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm">
          <table className="tblDefault">
            <thead>
              <tr>
                <th scope="col" className="tdMaster">
                  Nome
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {listData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => editaData(data.id, data.servico_bordo)}
                  className={idData == data.id ? 'trSelect' : ''}
                >
                  <td>{data.servico_bordo}</td>
                  <td>
                    <BiSolidEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
