import React, { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'relative flex flex-col',
});
export const FormGroup = ({ children, className, isError = false }) => {
  const [iconLeft, setIconLeft] = useState(null);
  const [iconRight, setIconRight] = useState(null);
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  useEffect(() => {
    setIconLeft(null);
    setIconRight(null);
    let foundField = false;
    React.Children.forEach(children, (child) => {
      if (child?.type?.displayName === 'InputIcon' && !foundField) {
        setIconLeft(child.props.icon);
      } else if (child?.type?.displayName === 'Input') {
        foundField = true;
      } else if (child?.type?.displayName === 'InputIcon' && foundField) {
        setIconRight(child.props.icon);
      }
    });
  }, [children]);
  const renderChildren = React.Children.map(children, (child) => {
    if (child?.type?.displayName === 'InputIcon') return undefined;
    if (child?.type?.displayName === 'Input') {
      const isPasswordField = child.props.type === 'password';
      return (
        <div className="relative">
          {iconLeft && (
            <div className="absolute left-4 top-1/2 -translate-y-1/2 transform text-[#343A3D]">
              {iconLeft}
            </div>
          )}
          {React.cloneElement(child, {
            isError: Boolean(isError),
            className: `${iconLeft && '!pl-10'} ${iconRight && '!pr-10'}`,
            type:
              isPasswordField && isPasswordVisible ? 'text' : child.props.type,
          })}
          {iconRight && (
            <div className="absolute right-4 top-1/2 flex h-5 w-5 -translate-y-1/2 transform items-center justify-center text-[#343A3D]">
              {iconRight}
            </div>
          )}
          {isPasswordField && (
            <div
              className="absolute right-4 top-1/2 flex h-5 w-5 -translate-y-1/2 transform cursor-pointer items-center justify-center"
              onClick={() => setPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <AiOutlineEyeInvisible color="#343A3D" />
              ) : (
                <AiOutlineEye color="#343A3D" />
              )}
            </div>
          )}
        </div>
      );
    }

    return child;
  });

  return <div className={styles({ class: className })}>{renderChildren}</div>;
};
