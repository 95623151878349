export function classNames(...args) {
  return args
    .flatMap((arg) => {
      if (typeof arg === 'string') {
        return arg;
      } else if (typeof arg === 'object' && arg !== null) {
        return Object.keys(arg).filter((key) => arg[key]);
      }
      return [];
    })
    .join(' ');
}
