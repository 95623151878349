import React from 'react';
import { Link } from 'react-router-dom';

export const MenuItem = ({
  children,
  active = false,
  submenu = false,
  title,
  subtitle = '',
  to,
  icon,
}) => {
  return (
    <Link
      className={[
        'home-menu-item',
        active ? 'active' : '',
        submenu ? 'submenu' : '',
      ].join(' ')}
      to={to}
      relative="path"
    >
      {icon}
      <div className={'home-menu-item-title'}>
        <span className={'text-start'}>{title}</span>
        <span className="h5 text-start">{subtitle}</span>
      </div>
    </Link>
  );
};
