import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckoutButtonGroup from '@components/checkout/buttons';
import PaymentMethodSelection from '@components/checkout/payment-method';
import PaymentForm from '@components/checkout/payment-method/payment-form';
import { useData } from '@stores/checkout-context';
import { useCreditAllotment } from '@stores/credit-allotment';
import { PaymentMethod } from '@system/enums';
import { PagePath } from '/src/pages-config';

export default function CheckoutPayment() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { updateData } = useData();
  const { insufficientCredit, paymentMethodsAllowed } = useCreditAllotment();

  const [paymentMethod, setPaymentMethod] = useState(
    PaymentMethod['Cartão de Crédito'],
  );

  useEffect(() => {
    if (!state?.bookingId || !state?.charterId || !state?.value) {
      alert('bookingId, charterId e value, são obrigatórios.');
      navigate(PagePath.Home, { replace: true });
      return;
    }

    updateData({
      bookingId: state.bookingId,
      charterId: state.charterId,
      value: state.value,
      referenceId: state.referenceId,
      paymentMethod: PaymentMethod['Cartão de Crédito'],
    });

    useCreditAllotment.getState().methods.getPaymentMethods();
  }, []);

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);
    updateData({ paymentMethod: selectedMethod });
  };

  const goToVPCard = () => {
    window.open('https://conteudo.viagenspromo.com/vp-pay', '_blank');
  };

  const onSubmit = () => {
    navigate(PagePath.CheckoutV2Confirmacao);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col rounded-lg bg-white sm:p-4">
        <div className="flex flex-col">
          <span className="text-heading-2 text-primary">
            Como será o pagamento?
          </span>
          <span className="mt-1.5 text-body font-normal">
            Escolha abaixo a forma de pagamento desejada.
          </span>
        </div>

        <PaymentMethodSelection
          selectedMethod={paymentMethod}
          onChange={handlePaymentMethodChange}
          goToVPCard={goToVPCard}
          paymentMethodsAllowed={paymentMethodsAllowed}
        />

        <PaymentForm selectedMethod={paymentMethod} onSubmit={onSubmit} />
      </div>

      <CheckoutButtonGroup
        onGoBack={goBack}
        onSubmit={onSubmit}
        selectedMethod={paymentMethod}
      />
    </div>
  );
}
