import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const BreadCrumbs = ({ paths }) => {
  return (
    <nav className="m-0 p-0">
      <ol className="m-0 flex items-center justify-start space-x-1 p-0 md:space-x-2 rtl:space-x-reverse">
        {paths.map((path, index) => (
          <li key={index} className="inline-flex items-center">
            {index !== 0 && (
              <div className="flex items-center">
                <BiChevronRight
                  className={
                    index != paths.length - 1 ? 'text-secondary' : 'text-black'
                  }
                />
              </div>
            )}
            {index === paths.length - 1 ? (
              <span className="text-sm font-medium text-neutral-800 md:ms-2">
                {path.label}
              </span>
            ) : (
              <Link
                to={path.link}
                className={`text-sm font-semibold text-secondary hover:text-secondary-900 ${index != 0 && 'md:ms-2'}`}
              >
                {path.label}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default BreadCrumbs;
