import { useEffect, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalReservationPayment } from '@components/modal/ModalReservationPayment';
import { ButtonV2 } from '@components/new-components/button-v2';
import Tag from '@components/Tag';
import { Container } from '@layout';
import { formatCurrency, formatDateWithoutTZ } from '@system/utils';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

const FinanceiroRecebiveisReservasEscolhidas = () => {
  const [bookings, setBookings] = useState([]);
  const [isModalReservationPaymentOpen, setIsModalReservationPaymentOpen] =
    useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');

  const { state } = useLocation();
  const navigate = useNavigate();
  const totalBooking = bookings
    ?.map((b) => b.totalAmount)
    ?.reduce((prev, cur) => prev + cur, 0);

  const totalFeeBooking = bookings
    ?.map(
      (b) =>
        b.totalAmount - b.totalAmount * (canAnticipatePayment(b) ? 0.9 : 1),
    )
    ?.reduce((prev, cur) => prev + cur, 0);

  function remove(bookingCode) {
    setBookings((prevState) =>
      prevState.filter((b) => b.bookingCode !== bookingCode),
    );
  }

  function canAnticipatePayment(booking) {
    return new Date() < new Date(booking.paymentEstimatedDate);
  }

  function onConfirmPayment() {
    if (!bookings.length) {
      return;
    }
    setIsModalReservationPaymentOpen(true);
  }

  useEffect(() => {
    setBookings(state.selecteds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selected]);

  return (
    <>
      <ModalReservationPayment
        onClose={() => setIsModalReservationPaymentOpen(false)}
        isOpen={isModalReservationPaymentOpen}
        bookings={bookings}
        setPaymentUrl={setPaymentUrl}
        paymentUrl={paymentUrl}
      />
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Financeiro },
          {
            label: 'Gestão de recebíveis de hotéis',
            link: PagePath.FinanceiroRecebiveis,
          },
          {
            label: 'Reservas para pagamento',
            link: PagePath.FinanceiroRecebiveisReservasEscolhidas,
          },
        ]}
        title="Reservas para pagamento"
      />
      <div className="flex flex-col gap-4">
        <Container className="border-box border-none bg-white p-5">
          <h5 className="!mx-0 mb-4 px-0 text-heading-2 text-primary">
            Reservas escolhidas
          </h5>
          <div className="border-box m-0 w-full rounded-md border border-solid border-neutral-300 bg-white p-4">
            <div className="w-full">
              <div className="col-12 table_list">
                <table className="tblDefault overflow-x-auto p-2 ">
                  <thead>
                    <tr className="text-primary">
                      <th scope="!pt-3 col w-[200px] text-sm">
                        Nro. da reserva
                      </th>
                      <th scope="!pt-3 col w-[200px] text-sm">
                        Data previsão pagto
                      </th>
                      <th scope="!pt-3 col text-sm">Data check-in</th>
                      <th scope="!pt-3 col text-sm">Valor atual</th>
                      <th scope="!pt-3 col text-sm">Valor a receber</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings?.map((booking) => (
                      <tr
                        key={`${booking.bookingCode}-${Math.random()}`}
                        className="!cursor-auto"
                      >
                        <td className="w-[200px] cursor-pointer text-body font-semibold text-secondary">
                          {booking.bookingCode}
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {formatDateWithoutTZ(
                            booking.paymentEstimatedDate,
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {formatDateWithoutTZ(
                            booking.checkinDate,
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="text-body  text-price">
                          {formatCurrency(booking.totalAmount, true)}
                        </td>
                        <td className="text-body font-semibold text-price">
                          {formatCurrency(
                            booking.totalAmount *
                              (canAnticipatePayment(booking) ? 0.9 : 1),
                            true,
                          )}
                        </td>
                        <td>
                          {canAnticipatePayment(booking) ? (
                            <Tag
                              type="warning"
                              value="Com taxa de antecipação"
                            />
                          ) : (
                            <span className="inline-block w-24"></span>
                          )}
                        </td>
                        <td>
                          <MdOutlineDelete
                            className="cursor-pointer fill-secondary"
                            onClick={() => remove(booking.bookingCode)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <h5 className="!mx-0 my-5 px-0 text-heading-2 text-primary">
            Valor a receber
          </h5>
          <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-4">
            <ul className="mb-0 list-none px-0">
              <li className="mb-4 text-body font-normal text-neutral-800">
                Valor das reservas:
                <span className="ml-0.5 text-body font-semibold text-price">
                  {formatCurrency(totalBooking, true)}
                </span>
              </li>

              <li className="pb-4 text-body font-normal text-neutral-800">
                Valor da taxa de antecipação:
                <span className="ml-0.5 text-body font-semibold text-price">
                  {formatCurrency(totalFeeBooking, true)}
                </span>
              </li>

              <li className="m-0 border-0 border-t border-solid border-neutral-600 pt-3 text-heading-3 font-semibold text-neutral-800">
                Valor total a receber:
                <span className="ml-0.5 font-semibold text-price">
                  {formatCurrency(totalBooking - totalFeeBooking, true)}
                </span>
              </li>
            </ul>
          </div>
        </Container>
        <div className="flex w-full flex-row justify-between">
          <ButtonV2
            ghost
            size="xlarge"
            type="button"
            onClick={() => navigate(-1)}
          >
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>

          <ButtonV2
            size="xlarge"
            disabled={!bookings.length}
            onClick={onConfirmPayment}
          >
            <ButtonV2.Text>Confirmar pagamento</ButtonV2.Text>
          </ButtonV2>
        </div>
      </div>
    </>
  );
};

export default FinanceiroRecebiveisReservasEscolhidas;
