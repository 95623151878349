import { useState } from 'react';
import { BiCalendar } from 'react-icons/bi';
import { format } from 'date-fns';
import { Button } from '@components/button';
import { InputDatePicker } from '@components/input-custom';
import { Modal } from '@components/modal';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import {
  finesRefundModalTexts,
  finesRefundPaymentMethodsOptions,
} from '@system/enums';
import { formatCurrency, getDateMinus12Months } from '@system/utils';

export default function ActionsDetailsModals({
  openModal,
  onClose,
  onAction,
  data,
}) {
  const { type = 'refund', action = 'cancel', value } = data;

  const text = finesRefundModalTexts?.[type]?.[action];

  let headText = text?.head ?? '';
  if (value) headText = headText?.replace('{$}', formatCurrency(value));

  const [formDate] = useState({
    startDate: new Date(),
    months: [],
  });
  const [date, setDate] = useState();
  const [newAmount, setNewAmount] = useState();
  const [paymentMethod, setPaymentMethod] = useState();

  function handleDepartureDateChange(date) {
    setDate(date);
  }

  function makeDateInputValue(value) {
    const dateFormat = value ? format(value, 'dd/MM/yyyy') : '';

    return dateFormat;
  }

  function onClickAction() {
    if (action === 'inform') {
      if (!date?.toISOString() || !paymentMethod) {
        return;
      }
      onAction({
        status: text.status,
        paymentDate: date?.toISOString(),
        paymentMethod,
      });
      return;
    }

    if (text?.inputLabel && !newAmount) {
      return;
    }
    onAction({ status: text.status, approvedAmount: newAmount });
  }

  if (!text) {
    return null;
  }

  return (
    <>
      <Modal
        open={openModal}
        handleClose={onClose}
        customPadding="p-14"
        className="w-7/12 max-w-7xl gap-0 text-neutral-800 md:mx-auto"
      >
        <div className="text-center">
          <h1 className="mb-3.5 text-heading-1 font-semibold text-neutral-800">
            {headText}
          </h1>
          <h4 className="mb-3.5 text-heading-4">{text?.label}</h4>
        </div>

        {/* Input de novo valor */}
        {text?.inputLabel && (
          <div className="w-80 py-3.5">
            <p className="m-0 pb-2 text-sm font-medium text-neutral-800">
              {text?.inputLabel}
            </p>
            <InputV2
              value={newAmount}
              onChange={(event) => setNewAmount(event.target.value)}
              type="number"
              placeholder="Insira o valor"
            />
          </div>
        )}

        {/* Inputs de informações */}
        {action == 'inform' && (
          <div className="flex gap-3.5 py-3.5">
            <div>
              <p className="m-0 pb-2 text-sm font-medium text-neutral-800">
                Data de pagamento do reembolso
              </p>
              <InputDatePicker
                name="startDate"
                autoComplete="off"
                placeholder="Insira a data"
                icon={BiCalendar}
                value={makeDateInputValue(date)}
                noValidate
                disableResume={true}
                oneMonthCalendar={true}
                startCalendar={getDateMinus12Months()}
                startDay={getDateMinus12Months()}
                monthStart={12}
                changeOnly="start"
                multiCalendar={false}
                selectedMonths={formDate.months}
                onDateChange={handleDepartureDateChange}
              />
            </div>
            <div className="w-64">
              <p className="m-0 pb-2 text-sm font-medium text-neutral-800">
                Método de pagamento
              </p>
              <SelectV2
                placeholder="Selecione"
                options={finesRefundPaymentMethodsOptions}
                value={paymentMethod}
                onChange={(event) => setPaymentMethod(event.target.value)}
              />
            </div>
          </div>
        )}

        <div className="mt-5 flex items-center gap-5">
          <Button variant="ghost" label="Fechar" onClick={onClose} />
          {text?.button && (
            <Button label={text?.button ?? ''} onClick={onClickAction} />
          )}
        </div>
      </Modal>
    </>
  );
}
