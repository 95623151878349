import { Alert } from '@components/alert';
import { useBookings } from '@stores/bookings';
import { convertFlightClassCategory } from '@system/converters/convertFlightClassCategory';
import { PassengerType } from '@system/enums';
import { formatBaggage, formatCurrency } from '@system/utils';

export const SeatDetail = ({ type = '' }) => {
  const { seat, passengerType, traveler, flightDetail, tablePrice } =
    useBookings();

  const getPriceSeat = () => {
    let _type = '';
    let value = 0;

    if (passengerType === PassengerType.PassengerCommon) {
      _type = type?.toLowerCase();
      const category = tablePrice[seat?.category];
      const price = Number(category?.[_type]);
      value = price === 0 || isNaN(price) ? 0 : price;
    }

    if (passengerType === PassengerType.PassengerBaby) {
      _type = 'inf';
      const category = tablePrice[traveler?.category];
      const price = Number(category?.[_type]);
      value = price === 0 || isNaN(price) ? 0 : price;
    }

    return value;
  };

  return (
    <div className="col-span-12  notebook:col-span-3 desktop:col-span-4">
      <div className="h-auto rounded-md border border-solid border-neutral-300 p-5">
        <div className="text-heading-3 font-semibold text-neutral-800">
          Dados do assento
        </div>
        <ul className="m-0 mt-4 list-none p-0">
          <li className="text-heading-4 text-neutral-800">Assento</li>
          <li className="text-heading-3 text-neutral-800">
            {passengerType === PassengerType.PassengerBaby
              ? 'Não possui assento'
              : seat
                ? `${[seat.column, seat.row].join('')}`
                : ''}
          </li>
        </ul>
        {passengerType === PassengerType.PassengerCommon && seat ? (
          <>
            <ul className="m-0 mt-2.5 list-none p-0">
              <li className="text-heading-4 text-neutral-800">Classe</li>
              <li className="text-heading-3 text-neutral-800">
                {convertFlightClassCategory(seat.category)}
              </li>
            </ul>
            {flightDetail && flightDetail.baggage && (
              <ul className="m-0 mt-2.5 list-none p-0">
                <li className="text-heading-4 text-neutral-800">
                  Bagagem despachada
                </li>
                <li className="text-heading-3 text-neutral-800">
                  {formatBaggage(flightDetail.baggage)}
                </li>
              </ul>
            )}
          </>
        ) : null}
        {!!type && (
          <>
            <div className="mt-4 border-0 border-t border-solid border-neutral-300" />
            <ul className="m-0 mt-4 list-none p-0">
              <li className="text-heading-4 text-neutral-800">Tipo</li>
              <li className="text-heading-3 text-neutral-800">{type}</li>
            </ul>
            <ul className="m-0 mt-2.5 list-none p-0">
              <li className="text-heading-4 text-neutral-800">Valor</li>
              <li className="text-heading-3 text-price">
                {formatCurrency(getPriceSeat(), true)}
              </li>
            </ul>
            {passengerType === PassengerType.PassengerCommon && traveler ? (
              <>
                <div className="mt-4 border-0 border-t border-solid border-neutral-300" />
                <ul className="m-0 mt-4 list-none p-0">
                  <li className="text-heading-4 text-neutral-800">
                    Localizador
                  </li>
                  <li className="text-heading-3 text-neutral-800">
                    {traveler?.locator}
                  </li>
                </ul>
              </>
            ) : null}
          </>
        )}
      </div>
      {passengerType === PassengerType.PassengerBaby ? (
        <div className="mt-4 flex flex-col gap-2">
          <Alert type="warning">
            O bebê poderá viajar somente no colo do adulto.
          </Alert>
          <Alert type="warning">
            Lembre-se de considerar a idade do passageiro no momento de terminar
            a viagem.
          </Alert>
        </div>
      ) : null}
    </div>
  );
};
