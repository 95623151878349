import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { InputText } from '@components/input';
import { InputDatePicker } from '@components/input-custom';
import { ModalFinancialBookingData } from '@components/modal/modal-external-bookings';
import { ButtonV2 } from '@components/new-components/button-v2';
import { InputCheckbox } from '@components/new-components/checkbox/input-checkbox';
import { Select } from '@components/select';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { formatDateWithoutTZ, getDateMinus12Months } from '@system/utils';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

const TableType = {
  available: 'available',
  requested: 'requested',
};
const OrderBy = {
  lastPayments: 'Últimos pagamentos',
  firstPayments: 'Primeiros pagamentos',
  lastCheckinDates: 'Últimas datas de check-in',
  firstCheckinDates: 'Primeiras datas de check-in',
};
const OrderByApiMap = {
  [OrderBy.lastPayments]: 'LastPaymentEstimatedDate',
  [OrderBy.firstPayments]: 'FirstPaymentEstimatedDate',
  [OrderBy.lastCheckinDates]: 'LastCheckinDate',
  [OrderBy.firstCheckinDates]: 'FirstCheckinDate',
};

/**
 * @param {URLSearchParams} searchParams
 */
function getSearch(searchParams) {
  const search = {};
  searchParams.forEach((value, key) => (search[key] = value));
  if (!search.paymentRequest) searchParams.paymentRequest = 0;
  return search;
}

export default function FinanceiroRecebiveis() {
  const navigate = useNavigate();
  const { session } = useAuthentication();

  if (session.role === Role.Administrador) {
    navigate(PagePath.FinanceiroRecebiveisAdmin);
  }

  const startPaymentDateInputRef = useRef(null);
  const endPaymentDateInputRef = useRef(null);
  const startCheckinInputRef = useRef(null);
  const endCheckinInputRef = useRef(null);

  const [searchParams] = useSearchParams();
  const [submit, setSubmit] = useState(false);

  const [availableBookings, setAvailableBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [activeTable, setActiveTable] = useState(TableType.available);
  const [orderBy, setOrderBy] = useState(OrderBy.lastPayments);
  const [page, setPage] = useState(1);

  const [hasFilter, setHasFilter] = useState(false);

  const filter = () => {
    const {
      startPaymentDate,
      endPaymentDate,
      startCheckin,
      endCheckin,
      bookingCode,
    } = getSearch(searchParams);
    return {
      startPaymentDate: startPaymentDate ?? null,
      endPaymentDate: endPaymentDate ?? null,
      startCheckin: startCheckin ?? null,
      endCheckin: endCheckin ?? null,
      bookingCode: bookingCode ?? '',
    };
  };

  const [formFields, setFormFields] = useState(() => {
    const {
      startPaymentDate,
      endPaymentDate,
      startCheckin,
      endCheckin,
      bookingCode,
      paymentRequest,
    } = getSearch(searchParams);
    return {
      startPaymentDate: startPaymentDate ?? null,
      endPaymentDate: endPaymentDate ?? null,
      startCheckin: startCheckin ?? null,
      endCheckin: endCheckin ?? null,
      bookingCode: bookingCode ?? '',
      paymentRequest: paymentRequest ?? 0,
    };
  });
  const [formErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalBookingId, setModalBookingId] = useState('');

  useEffect(() => {
    if (submit) {
      setSubmit(false);
      setAvailableBookings({});
      naviageFilter();
    }
  }, [submit, formFields]);

  useEffect(() => {
    if (formFields.paymentRequest == 1) {
      setActiveTable(TableType.requested);
    } else {
      setActiveTable(TableType.available);
    }
    const loadAvailableBookings = async () => {
      const data = await fetchAvailableBookings(getSearch(searchParams));
      setAvailableBookings(data);
    };
    loadAvailableBookings();

    setHasFilter(
      Object.keys(formFields).some((key) => {
        if (key === 'paymentRequest') {
          return false; // Ignora a chave 'name'
        }
        const value = formFields[key];
        return value !== null && value !== undefined && value !== ''; // Valida os outros atributos
      }),
    );
  }, [searchParams]);

  async function handleChangeTable(table) {
    if (table === activeTable) return;
    setPage(0);
    setOrderBy(OrderBy.lastPayments);
    if (table === TableType.available) {
      setFormFields((prev) => ({
        ...prev,
        paymentRequest: 0,
      }));
      setSubmit(true);
    } else {
      setFormFields((prev) => ({
        ...prev,
        paymentRequest: 1,
      }));
      setSubmit(true);
    }
  }

  async function handleChangeOrderBy(newOrder) {
    if (newOrder === orderBy) return;

    setOrderBy(newOrder);
    setPage(0);

    const data = await fetchAvailableBookings({
      ...formFields,
      page: 0,
      orderBy: newOrder,
    });
    setAvailableBookings(data);
  }

  async function handleLoadMore(newPage) {
    setPage(newPage);

    const data = await fetchAvailableBookings({
      ...formFields,
      page: newPage,
      orderBy,
    });
    data.result = [...availableBookings.result, ...data.result];

    setAvailableBookings(data);
  }

  function handleSelectBooking(bookingCode) {
    if (selectedBookings.includes(bookingCode)) {
      setSelectedBookings(
        selectedBookings.filter((code) => code !== bookingCode),
      );
    } else {
      setSelectedBookings([...selectedBookings, bookingCode]);
    }
  }

  function handleFieldChange(field, value) {
    setFormFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  function handleStartPaymentDateChange(date) {
    const newEndPaymentDate =
      date > formFields.endPaymentDate ? null : formFields.endPaymentDate;
    /**
     * @note This will ensure that endPaymentDateInputRef is focused correctly
     */
    flushSync(() =>
      setFormFields((prev) => ({
        ...prev,
        startPaymentDate: date,
        endPaymentDate: newEndPaymentDate,
      })),
    );

    endPaymentDateInputRef.current?.focus();
    endPaymentDateInputRef.current?.click();
  }

  function handleStartCheckinChange(date) {
    const newEndCheckin =
      date > formFields.endCheckin ? null : formFields.endCheckin;
    /**
     * @note This will ensure that endCheckinInputRef is focused correctly
     */
    flushSync(() =>
      setFormFields((prev) => ({
        ...prev,
        startCheckin: date,
        endCheckin: newEndCheckin,
      })),
    );

    endCheckinInputRef.current?.focus();
    endCheckinInputRef.current?.click();
  }

  function handleEndPaymentDateClick(showCalendar) {
    if (formFields.startPaymentDate) return showCalendar();
    startPaymentDateInputRef.current?.focus();
    startPaymentDateInputRef.current?.click();
  }

  function handleEndCheckinClick(showCalendar) {
    if (formFields.startCheckin) return showCalendar();
    startCheckinInputRef.current?.focus();
    startCheckinInputRef.current?.click();
  }

  async function fetchAvailableBookings({
    startPaymentDate,
    endPaymentDate,
    startCheckin,
    endCheckin,
    bookingCode,
    page,
    orderBy,
    paymentRequest,
  } = {}) {
    const response = await api.get('hotel-external-bookings', {
      params: {
        page: page ?? 0,
        orderBy: OrderByApiMap[orderBy ?? OrderBy.lastPayments],
        status: 'created',
        startDatePayment: startPaymentDate
          ? new Date(startPaymentDate)
          : undefined,
        endDatePayment: endPaymentDate ? new Date(endPaymentDate) : undefined,
        startDateCheckin: startCheckin ? new Date(startCheckin) : undefined,
        endDateCheckin: endCheckin ? new Date(endCheckin) : undefined,
        code: bookingCode,
        paymentRequest: paymentRequest ?? 0,
      },
    });
    return response.data.total > 0 ? response.data : [];
  }

  function canAnticipatePayment(booking) {
    return new Date() < new Date(booking.paymentEstimatedDate);
  }

  function formatPrice(price) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(price);
  }

  function makeDateInputValue(value) {
    return value ? formatDateWithoutTZ(value, 'dd/MM/yyyy') : '';
  }

  function makeStatusClass(v) {
    switch (v) {
      case 'PAID':
        return 'bg-success-100 text-success-500';
      case 'CANCEL':
        return 'bg-error-100 text-error-500';
      default:
        return 'bg-warning-100 text-warning-500';
    }
  }
  function makeStatusLabel(v) {
    switch (v) {
      case 'PAID':
        return 'Pagamento Confirmado';
      case 'CANCEL':
        return 'Pagamento Cancelado';
      default:
        return 'Em processamento';
    }
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (!validateFormFields()) return;
    setSubmit(true);
    naviageFilter();
  }

  function naviageFilter() {
    const query = makeQuery();
    navigate(`${PagePath.FinanceiroRecebiveis}?${query.toString()}`);
  }

  function validateFormFields() {
    return Object.values(formFields).length > 0;
  }

  function handleOpenModal(id) {
    setModalBookingId(id);
    setOpenModal(true);
  }

  function makeQuery() {
    const {
      startPaymentDate,
      endPaymentDate,
      startCheckin,
      endCheckin,
      bookingCode,
      paymentRequest,
    } = formFields;
    return new URLSearchParams({
      ...(startPaymentDate && {
        startPaymentDate: format(startPaymentDate, 'yyyy-MM-dd'),
      }),
      ...(endPaymentDate && {
        endPaymentDate: format(endPaymentDate, 'yyyy-MM-dd'),
      }),
      ...(startCheckin && { startCheckin: format(startCheckin, 'yyyy-MM-dd') }),
      ...(endCheckin && { endCheckin: format(endCheckin, 'yyyy-MM-dd') }),
      ...(bookingCode && { bookingCode }),
      ...(paymentRequest && { paymentRequest }),
    });
  }

  return (
    <>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Financeiro },
          {
            label: 'Gestão de recebíveis de hotéis',
            link: PagePath.FinanceiroRecebiveis,
          },
        ]}
        title="Gestão de recebíveis"
      />

      <Container className="mt-4 border-0 p-4">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex space-x-2.5">
              <div className="flex-grow">
                <label
                  className={`m-0 h-5 text-sm font-medium text-neutral-800`}
                >
                  Período de pagamento
                </label>
                <div className="flex flex-col space-y-2.5 lg:mt-4 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
                  <InputDatePicker
                    name="startPaymentDate"
                    autoComplete="off"
                    placeholder="De"
                    startCalendar={getDateMinus12Months()}
                    startDay={getDateMinus12Months()}
                    monthStart={12}
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.startPaymentDate)}
                    noValidate
                    error={formErrors.startPaymentDate}
                    intervalStart={formFields.startPaymentDate}
                    changeOnly="start"
                    multiCalendar={false}
                    onDateChange={handleStartPaymentDateChange}
                    ref={startPaymentDateInputRef}
                    disableResume={true}
                  />
                  <InputDatePicker
                    name="endPaymentDate"
                    autoComplete="off"
                    placeholder="Até"
                    startDay={getDateMinus12Months()}
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.endPaymentDate)}
                    noValidate
                    error={formErrors.endPaymentDate}
                    intervalStart={formFields.startPaymentDate}
                    intervalEnd={formFields.endPaymentDate}
                    changeOnly="end"
                    multiCalendar={false}
                    startCalendar={formFields.startPaymentDate}
                    onClick={handleEndPaymentDateClick}
                    onDateChange={(date) =>
                      handleFieldChange('endPaymentDate', date)
                    }
                    ref={endPaymentDateInputRef}
                    disableResume={true}
                  />
                </div>
              </div>
              <div className="flex-grow">
                <label
                  className={`m-0 h-5 text-sm font-medium text-neutral-800`}
                >
                  Período de check-in
                </label>
                <div className="flex flex-col space-y-2.5 lg:mt-4 lg:flex-row lg:items-start lg:space-x-2.5 lg:space-y-0">
                  <InputDatePicker
                    name="startCheckin"
                    autoComplete="off"
                    placeholder="De"
                    startCalendar={getDateMinus12Months()}
                    startDay={getDateMinus12Months()}
                    monthStart={12}
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.startCheckin)}
                    noValidate
                    error={formErrors.startCheckin}
                    intervalStart={formFields.startCheckin}
                    changeOnly="start"
                    multiCalendar={false}
                    onDateChange={handleStartCheckinChange}
                    ref={startCheckinInputRef}
                    disableResume={true}
                  />
                  <InputDatePicker
                    name="endCheckin"
                    autoComplete="off"
                    placeholder="Até"
                    startPosition="right"
                    startDay={getDateMinus12Months()}
                    icon={BiCalendar}
                    value={makeDateInputValue(formFields.endCheckin)}
                    noValidate
                    error={formErrors.endCheckin}
                    intervalStart={formFields.startCheckin}
                    intervalEnd={formFields.endCheckin}
                    changeOnly="end"
                    multiCalendar={false}
                    startCalendar={formFields.startCheckin}
                    onClick={handleEndCheckinClick}
                    onDateChange={(date) =>
                      handleFieldChange('endCheckin', date)
                    }
                    ref={endCheckinInputRef}
                    disableResume={true}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex space-x-2.5">
              <div className="basis-1/2">
                <InputText
                  label="Nro da reserva"
                  error={formErrors.bookingCode}
                  onChange={(e) =>
                    handleFieldChange('bookingCode', e.target.value)
                  }
                  placeholder="Digite o número da reserva"
                />
              </div>
              <div className="basis-1/2">
                <span
                  role="button"
                  onClick={handleSubmit}
                  className="group !mt-9 flex h-9 w-9 items-center justify-center rounded-full border border-solid border-secondary bg-white hover:bg-secondary"
                >
                  <BiSearch
                    size={18}
                    className="text-xs text-secondary transition-none group-hover:text-white"
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
      </Container>

      <div className="mt-4 flex justify-end">
        <div className="w-80">
          <Select
            label="Ordenar por"
            value={orderBy}
            options={Object.values(OrderBy).map((value) => ({ value }))}
            onChange={(v) => handleChangeOrderBy(v)}
          />
        </div>
      </div>
      {hasFilter && (
        <div className="mt-5 flex w-full flex-row items-center justify-between border-0 border-b border-t  border-solid border-neutral-400 py-2.5">
          <div>
            {Object.keys(filter())
              .filter((i) => filter()[i]?.length > 0)
              .map((i) => {
                let filterValue = '';
                if (i === 'bookingCode') {
                  filterValue = filter()[i];
                }
                if (i === 'clientName') {
                  filterValue = filter()[i];
                }
                if (i === 'startPaymentDate') {
                  filterValue = formatDateWithoutTZ(filter()[i], 'dd/MM/yyyy');
                  if (filter().endPaymentDate) {
                    filterValue = `${filterValue} - ${formatDateWithoutTZ(filter().endPaymentDate, 'dd/MM/yyyy')}`;
                  }
                }
                if (i === 'startCheckin') {
                  filterValue = formatDateWithoutTZ(filter()[i], 'dd/MM/yyyy');
                  if (filter().endCheckin) {
                    filterValue = `${filterValue} - ${formatDateWithoutTZ(filter().endCheckin, 'dd/MM/yyyy')}`;
                  }
                }
                if (filterValue == '') return;
                return (
                  <span
                    onClick={() => {
                      setFormFields((prev) => ({
                        ...prev,
                        [i]: '',
                      }));
                      if (i === 'startPaymentDate') {
                        setFormFields((prev) => ({
                          ...prev,
                          endPaymentDate: '',
                        }));
                      }
                      if (i === 'startCheckin') {
                        setFormFields((prev) => ({
                          ...prev,
                          endCheckin: '',
                        }));
                      }
                      setSubmit(true);
                    }}
                    key={i}
                    role="button"
                    className="!m mr-4 inline-block rounded-2xl border border-solid border-secondary bg-secondary-100 px-4 py-1.5 text-body font-semibold text-secondary"
                  >
                    {filterValue} <AiOutlineClose className="ml-2.5" />
                  </span>
                );
              })}
          </div>
        </div>
      )}
      <Container className="my-4 border-0">
        <h2 className="mb-5 text-heading-2 text-primary">Reservas</h2>
        <div className="rounded-md border border-solid border-neutral-400">
          <div className="flex w-fit px-3">
            <div
              role="button"
              aria-selected={activeTable === TableType.available}
              className="grow border-0 border-b-2 border-solid border-transparent px-5 py-4 text-center text-body font-semibold text-neutral-600 aria-selected:border-secondary aria-selected:text-secondary"
              onClick={() => handleChangeTable(TableType.available)}
            >
              Disponíveis para pagamento
            </div>
            <div
              role="button"
              aria-selected={activeTable === TableType.requested}
              className="grow border-0 border-b-2 border-solid border-transparent px-5 py-4 text-center text-body font-semibold text-neutral-600 aria-selected:border-secondary aria-selected:text-secondary"
              onClick={() => handleChangeTable(TableType.requested)}
            >
              Solicitadas
            </div>
          </div>
          <div className="border-0 border-b border-solid border-neutral-400 " />
          {activeTable === TableType.available && (
            <div className="m-2.5">
              <table className="w-full table-auto">
                <thead className="border-b border-solid border-primary text-body font-semibold text-primary">
                  <tr>
                    <th></th>
                    <th className="px-1 pb-5 pt-5">
                      Nro. da
                      <br />
                      reserva
                    </th>
                    <th className="px-1 pb-5 pt-5">
                      Data previsão
                      <br />
                      pgto
                    </th>
                    <th className="px-1 pb-5 pt-5">
                      Data
                      <br />
                      check-in
                    </th>
                    <th className="px-1 pb-5 pt-5">
                      Valor
                      <br />
                      atual
                    </th>
                    <th className="px-1 pb-5 pt-5">
                      Valor
                      <br />a receber
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-400">
                  {availableBookings.total > 0 ? (
                    availableBookings.result.map((booking) => (
                      <tr key={booking.bookingCode}>
                        <td className="px-1 py-6 text-center">
                          <InputCheckbox
                            value={booking.bookingCode}
                            checked={selectedBookings.includes(
                              booking.bookingCode,
                            )}
                            onChange={() =>
                              handleSelectBooking(booking.bookingCode)
                            }
                          />
                        </td>
                        <td className="px-1 py-6 text-body font-semibold text-secondary">
                          <span
                            className="cursor-pointer"
                            onClick={() => handleOpenModal(booking.bookingCode)}
                          >
                            {booking.bookingCode}
                          </span>
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {format(
                            booking.paymentEstimatedDate + 'T00:00:00',
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {format(
                            booking.checkinDate + 'T00:00:00',
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="px-1 py-6 text-body text-price">
                          {formatPrice(booking.totalAmount)}
                        </td>
                        <td className="px-1 py-6 text-body font-semibold text-price">
                          {formatPrice(
                            booking.totalAmount *
                              (canAnticipatePayment(booking) ? 0.9 : 1),
                          )}
                        </td>
                        <td>
                          {canAnticipatePayment(booking) ? (
                            <span className="inline-block w-24 bg-warning-100 px-2 py-1 text-small font-medium text-warning-500">
                              Com taxa de antecipação
                            </span>
                          ) : (
                            <span className="inline-block w-24"></span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="px-1 py-6 text-center"></td>
                      <td colSpan="6" className="py-4">
                        Nenhum resultado foi encontrado.
                      </td>
                    </tr>
                    // <span className="py-4">Nenhum resultado foi encontrado.</span>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {activeTable === TableType.requested && (
            <div className="m-2.5">
              <table className="w-full table-auto">
                <thead className="border-b border-solid border-primary text-body font-semibold text-primary">
                  <tr>
                    <th className="px-1 pb-5 pt-5">
                      Nro. da
                      <br />
                      reserva
                    </th>
                    <th className="px-1 pb-5 pt-5">Data do pgto</th>
                    <th className="px-1 pb-5 pt-5">Data check-in</th>
                    <th className="px-1 pb-5 pt-5">Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-400">
                  {availableBookings.total > 0 ? (
                    availableBookings.result.map((booking) => (
                      <tr key={booking.bookingCode}>
                        <td className="px-1 py-6 text-body font-semibold text-secondary">
                          <span
                            className="cursor-pointer"
                            onClick={() => handleOpenModal(booking.bookingCode)}
                          >
                            {booking.bookingCode}
                          </span>
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {format(
                            booking.paymentEstimatedDate + 'T00:00:00',
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="px-1 py-6 text-body text-neutral-800">
                          {format(
                            booking.checkinDate + 'T00:00:00',
                            'dd/MM/yyyy',
                          )}
                        </td>
                        <td className="px-1 py-6 text-body font-semibold text-price">
                          {formatPrice(booking.totalAmount)}
                        </td>
                        <td>
                          <span
                            className={`${makeStatusClass(booking.status)} inline-block rounded-sm px-2 py-1 text-small font-medium `}
                          >
                            {makeStatusLabel(booking.status)}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="px-1 py-6 text-center"></td>
                      <td colSpan="6" className="py-4">
                        Nenhum resultado foi encontrado.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {availableBookings.total > 0 &&
          availableBookings.page < availableBookings.totalPages && (
            <div className="py-7 text-center">
              <span
                role="button"
                className="text-body font-semibold text-secondary"
                onClick={() => handleLoadMore(page + 1)}
              >
                Ver mais resultados
              </span>
            </div>
          )}
      </Container>

      {openModal && (
        <ModalFinancialBookingData
          reservationId={modalBookingId}
          openModal={openModal}
          onClose={() => {
            setModalBookingId('');
            setOpenModal(false);
          }}
        />
      )}

      {availableBookings.total > 0 && (
        <div className="flex justify-end">
          <ButtonV2
            className="!px-9 !py-3"
            onClick={() => {
              navigate(PagePath.FinanceiroRecebiveisReservasEscolhidas, {
                state: {
                  selecteds:
                    availableBookings.result.filter((i) =>
                      selectedBookings.includes(i.bookingCode),
                    ) ?? [],
                },
              });
            }}
          >
            <ButtonV2.Text size="xlarge" className="!text-heading-3">
              Solicitar pagamento
            </ButtonV2.Text>
          </ButtonV2>
        </div>
      )}
    </>
  );
}
