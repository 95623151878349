import { InputDatePicker } from '@components/input-custom';
import LoadingAnimation from '@components/LoadingAnimation';
import { ButtonV2 } from '@components/new-components/button-v2';
import { Form } from '@components/new-components/form';
import { InputV2 } from '@components/new-components/input-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import {
  CancellationRequestStatus,
  CancellationRequestType,
} from '@system/enums';
import { classNames, formatCurrency } from '@system/utils';
import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { BiCalendar, BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { PagePath } from '../../pages-config';
import {
  convertCancellationRequestStatus,
  convertCancellationRequestType,
} from '../../system/converters';
import { convertCancellationRequestStatusColor } from '../../system/converters/convertCancellationRequestStatusColor';
import PageHeader from '@components/page-header';

const ORDER_BY_OPTIONS = [
  { value: 'desc', label: 'Últimas reservas feitas' },
  { value: 'asc', label: 'Primeiras reservas feitas' },
];
export const FinanceiroMultaReembolso = () => {
  const [loading, setLoading] = useState(false);
  const [cancellations, setCancellations] = useState([]);
  const [filter, setFilter] = useState({
    dateStart: '',
    dateEnd: '',
    bookingCode: '',
    status: '',
    agencyName: '',
    type: '',
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [orderby, setOrderby] = useState(ORDER_BY_OPTIONS[0].value);
  const { session } = useAuthentication();
  const isAdmin = session.role >= Role.Gestao;
  const navigate = useNavigate();
  const statusOptions = Object.keys(CancellationRequestStatus).map((key) => ({
    value: CancellationRequestStatus[key],
    label: convertCancellationRequestStatus(CancellationRequestStatus[key]),
  }));
  const typeOptions = Object.keys(CancellationRequestType).map((key) => ({
    value: CancellationRequestType[key],
    label: convertCancellationRequestType(CancellationRequestType[key]),
  }));

  const startDateInputRef = useRef(null);
  const returnDateInputRef = useRef(null);

  const searchParams = new URLSearchParams(location.search);

  const paramsObject = {};
  for (let [key, value] of searchParams.entries()) {
    paramsObject[key] = value;
  }

  const [formDate, setformData] = useState({
    startDate: paramsObject.startDate ?? null,
    months: paramsObject?.months?.split(',') ?? [],
  });

  function makeDateInputValue(value) {
    const dateFormat = value ? format(value, 'dd/MM/yyyy') : '';

    return dateFormat;
  }

  const filterSchema = yup.object().shape({
    dateStart: yup
      .string()
      .test(
        'dateStart-required-if-dateEnd',
        'Informe a data inicial',
        function (value) {
          const { dateEnd } = this.parent;
          return !dateEnd || (dateEnd && value);
        },
      )
      .label('Data inicial'),
    dateEnd: yup
      .string()
      .test(
        'dateEnd-required-if-dateStart',
        'Informe a data fim',
        function (value) {
          const { dateStart } = this.parent;
          return !dateStart || (dateStart && value);
        },
      )
      .label('Data fim'),
    bookingCode: yup.string().label('Código da reserva'),
    status: yup.string().label('Status'),
    agencyName: yup.string().label('Nome da agência'),
    type: yup.string().label('Tipo'),
  });
  const form = useForm({
    resolver: yupResolver(filterSchema),
  });

  const getCancellations = useCallback(async () => {
    setLoading(true);
    if (page === 1) setCancellations([]);
    try {
      const params = {
        limit: 10,
        page,
        sort: orderby,
        ...(filter?.bookingCode && { reservationNumber: filter?.bookingCode }),
        ...(filter?.status && { paymentStatus: filter?.status }),
        ...(filter?.agencyName && { companyName: filter?.agencyName }),
        ...(filter?.type && { type: filter?.type }),
      };

      if (filter?.period) {
        params.startDate = format(filter.period[0], 'yyyy-MM-dd');
        params.endDate = format(filter.period[1], 'yyyy-MM-dd');
      }

      const { data } = await api.get('/cancellation-requests', {
        params,
      });
      setTotalPage(data.totalPages);
      setCancellations((prev) =>
        page === 1 ? data.result : [...prev, ...data.result],
      );
    } catch (err) {
      setCancellations([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [filter, orderby, page]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const clearFilters = () => {
    setFilter({
      period: '',
      bookingCode: '',
      status: '',
      agencyName: '',
      type: '',
    });
    form.reset({
      dateStart: '',
      dateEnd: '',
      bookingCode: '',
      status: '',
      agencyName: '',
      type: '',
    });
  };
  const getDateMinus12Months = () => {
    const currentDate = new Date();
    return new Date(currentDate.setMonth(currentDate.getMonth() - 12));
  };

  function handleDepartureDateChange(date) {
    const newReturnDate = date > formDate.dateStart ? null : formDate.dateStart;
    /**
     * @note This will ensure that returnDateInputRef is focused correctly
     */
    setformData((prev) => ({
      ...prev,
      dateEnd: newReturnDate,
      dateStart: date,
    }));

    returnDateInputRef.current?.focus();
    returnDateInputRef.current?.click();
  }

  function handleFieldChange(field, value) {
    setformData((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  const onFilter = (formData) => {
    if (!formDate.dateStart && !formDate.dateEnd) {
      if (Object.keys(formData).every((i) => formData[i].length === 0)) {
        return;
      }
    }
    setFilter({
      period:
        formDate.dateStart && formDate.dateEnd
          ? [formDate.dateStart, formDate.dateEnd]
          : '',
      bookingCode: formData.bookingCode,
      status: formData.status,
      agencyName: formData.agencyName,
      type: formData.type,
    });
    setPage(1);
  };

  useEffect(() => {
    getCancellations();
  }, [orderby, page, filter, getCancellations]);

  return (
    <>
      {loading && <LoadingAnimation />}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Financeiro },
          {
            label: 'Multas e Reembolsos',
            link: PagePath.FinanceiroMultasReembolsos,
          },
        ]}
        title="Multas e reembolsos"
      />
      <div className="flex flex-col gap-4">
        <Container className="border-none">
          <Form
            onSubmit={form.handleSubmit(onFilter)}
            className="grid grid-cols-12 gap-2"
          >
            <Form.Group className="col-span-8">
              <Form.Label>Período data da reserva</Form.Label>
              <div className="border-box flex flex-row gap-2">
                <InputDatePicker
                  name="startDate"
                  autoComplete="off"
                  placeholder="De"
                  icon={BiCalendar}
                  value={makeDateInputValue(formDate.dateStart)}
                  noValidate
                  disableResume={true}
                  startCalendar={getDateMinus12Months()}
                  startDay={getDateMinus12Months()}
                  monthStart={12}
                  intervalStart={formDate.dateStart}
                  changeOnly="start"
                  multiCalendar={false}
                  selectedMonths={formDate.months}
                  onDateChange={handleDepartureDateChange}
                  onMonthsChange={(months) =>
                    handleFieldChange('months', months)
                  }
                  ref={startDateInputRef}
                />
                <InputDatePicker
                  name="returnDate"
                  autoComplete="off"
                  placeholder="Até"
                  icon={BiCalendar}
                  value={makeDateInputValue(formDate.dateEnd)}
                  noValidate
                  disableResume={true}
                  intervalStart={formDate.dateStart}
                  intervalEnd={formDate.dateEnd}
                  changeOnly="end"
                  multiCalendar={false}
                  startCalendar={formDate.dateStart}
                  startDay={formDate.dateStart}
                  selectedMonths={formDate.months}
                  onDateChange={(date) => handleFieldChange('dateEnd', date)}
                  ref={returnDateInputRef}
                />
              </div>
              <Form.Message error={form.formState.errors.dateStart} />
              <Form.Message error={form.formState.errors.dateEnd} />
            </Form.Group>
            <Form.Group className="col-span-4">
              <Form.Label>Nro da reserva</Form.Label>
              <InputV2
                {...form.register('bookingCode')}
                placeholder="Digite o número da reserva"
                placeholderStyle="normal"
              />
            </Form.Group>
            {isAdmin && (
              <Form.Group className="col-span-5">
                <Form.Label>Nome da empresa</Form.Label>
                <InputV2
                  {...form.register('agencyName')}
                  placeholder="Digite o nome da empresa"
                  placeholderStyle="normal"
                />
              </Form.Group>
            )}
            <Form.Group
              className={classNames('', {
                'col-span-5': !isAdmin,
                'col-span-3': isAdmin,
              })}
            >
              <Form.Label>Tipo</Form.Label>
              <SelectV2
                options={typeOptions}
                {...form.register('type')}
                value={form.watch('type')}
                placeholder="Todos"
                placeholderStyle="normal"
              />
            </Form.Group>

            <Form.Group
              className={classNames('', {
                'col-span-6': !isAdmin,
                'col-span-4': isAdmin,
              })}
            >
              <Form.Label>Status do pagamento</Form.Label>
              <div className="border-box flex flex-row gap-2">
                <SelectV2
                  options={statusOptions}
                  {...form.register('status')}
                  value={form.watch('status')}
                  placeholder="Todos"
                  placeholderStyle="normal"
                />
                <ButtonV2
                  iconOnly
                  ghost
                  className="m-0 !mb-1 mt-1 box-border size-9 !p-0"
                >
                  <BiSearch />
                </ButtonV2>
              </div>
            </Form.Group>
          </Form>
        </Container>
        <div className="flex w-full flex-row items-end justify-end">
          <Form.Group className="w-[300px]">
            <Form.Label>Ordenação</Form.Label>
            <SelectV2
              options={ORDER_BY_OPTIONS}
              value={orderby}
              onChange={(e) => {
                setOrderby(e.target.value);
                setPage(1);
              }}
              placeholder="Ordenar por"
            />
          </Form.Group>
        </div>
        {!!Object.keys(filter).filter((i) => filter[i]?.length > 0)?.length && (
          <div className="flex w-full flex-row items-center justify-between border-0 border-b border-t  border-solid border-neutral-400 py-2.5">
            <div>
              {Object.keys(filter)
                .filter((i) => filter[i]?.length > 0)
                .map((i) => {
                  let filterValue = '';
                  if (i === 'period')
                    filterValue = filter[i]
                      .map((d) => format(d, 'dd/MM/yyyy'))
                      .join(' - ');
                  else if (i === 'status')
                    filterValue = convertCancellationRequestStatus(filter[i]);
                  else if (i === 'type')
                    filterValue = convertCancellationRequestType(filter[i]);
                  else filterValue = filter[i];
                  return (
                    <span
                      onClick={() => {
                        setFilter((prev) => ({
                          ...prev,
                          [i]: '',
                        }));
                        if (i === 'period') {
                          form.resetField('dateStart');
                          form.resetField('dateEnd');
                        } else {
                          form.resetField(i);
                        }
                      }}
                      key={i}
                      role="button"
                      className="!m mr-4 inline-block rounded-2xl border border-solid border-secondary bg-secondary-100 px-4 py-1.5 text-body font-semibold text-secondary"
                    >
                      {filterValue} <AiOutlineClose className="ml-2.5" />
                    </span>
                  );
                })}
            </div>
            <ButtonV2 link onClick={clearFilters}>
              <ButtonV2.Text>Apagar todos</ButtonV2.Text>
            </ButtonV2>
          </div>
        )}
        <Container className="border-box border-none bg-white p-5">
          <h5 className="!mx-0 mb-6 px-0 text-heading-2 text-primary">
            Listagem de reservas
          </h5>
          <div className="border-box m-0 w-full rounded-md bg-white">
            <div className="w-full">
              <div className="col-12 table_list">
                <table className="tblDefault overflow-x-auto p-2 ">
                  <thead>
                    <tr>
                      <th scope="col" className="w-[100px] font-semibold">
                        Data da reserva
                      </th>
                      <th scope="col" className="font-semibold">
                        <div className="w-[100px]">Nro da reserva</div>
                      </th>
                      {isAdmin && (
                        <th scope="col" className="font-semibold">
                          Nome da empresa
                        </th>
                      )}
                      <th scope="col" className="font-semibold">
                        Tipo
                      </th>
                      <th scope="col" className="font-semibold">
                        Status do Pagto
                      </th>
                      <th scope="col" className="font-semibold">
                        Valor do Pagto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cancellations?.map((cancellation) => (
                      <tr
                        key={cancellation.id}
                        className="hover:!bg-secondary-100"
                        onClick={() => {
                          navigate(
                            PagePath.FinanceiroMultasReembolsosDetalhes.replace(
                              ':id',
                              cancellation.id,
                            ),
                          );
                        }}
                      >
                        <td>
                          {format(cancellation.requestDateTime, 'dd/MM/yyyy')}
                        </td>
                        <td>{cancellation?.booking?.code ?? ''}</td>
                        {isAdmin && (
                          <td>{cancellation?.booking?.agency?.legalName}</td>
                        )}
                        <td>
                          {convertCancellationRequestType(cancellation?.type)}
                        </td>
                        <td>
                          <div
                            className={`statusPgto font-medium ${convertCancellationRequestStatusColor(cancellation.status)}`}
                          >
                            {convertCancellationRequestStatus(
                              cancellation.status,
                            )}
                          </div>
                        </td>
                        <td className="text-body font-semibold text-price">
                          {formatCurrency(cancellation.amount, true)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
        {totalPage > 0 && totalPage !== page && (
          <div className="flex w-full justify-center">
            <ButtonV2 link loading={loading} onClick={loadMore}>
              <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
            </ButtonV2>
          </div>
        )}
      </div>
    </>
  );
};
