import { useState, useEffect } from 'react';
import { PiPlusCircleFill, PiCaretRightBold } from 'react-icons/pi';
import LoadingAnimation from '../components/LoadingAnimation';
import { FiEdit } from 'react-icons/fi';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { BiSolidEdit } from 'react-icons/bi';
import { PagePath } from '../pages-config';
import api from '@services/api';
import PageHeader from '@components/page-header';

export default function AdminAssentos() {
  const [alertType, setAlertType] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertText, setAlertText] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [totalList, setTotalList] = useState(0);
  const [feature, setFeature] = useState('');
  const [dataId, setDataId] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [isNewData, setIsNewData] = useState(true);
  const [masterTitle] = useState('Características dos Assentos');

  useEffect(() => {
    setLoading(true);
    api
      .get('/seat/features')
      .then((response) => {
        setDataList(response.data);
        setTotalList(response.data.length);
      })
      .catch((error) => alert(error))
      .finally(() => setLoading(false));
  }, []);

  function clearForm() {
    setLoading(true);
    setIsNewData(true);
    setDataId('');
    setFeature('');
    setTimeout(() => setLoading(false), 1000);
  }

  function validateForm(e) {
    e.preventDefault();
    setLoading(true);

    if (!isNewData) {
      api
        .patch(`/seat/features/${dataId}`, { feature: feature.trim() })
        .then(() => {
          setAlertType('alert-success');
          setAlertTitle('Success!');
          setAlertText('Alterado com sucesso.');
          setTimeout(() => setAlertType(''), 3000);
          clearForm();
          api
            .get('/seat/features')
            .then((response) => setDataList(response.data));
        })
        .catch((error) => alert(error));
    } else {
      api
        .post('/seat/features', { feature: feature.trim() })
        .then(() => {
          setAlertType('alert-success');
          setAlertTitle('Sucesso!');
          setAlertText('Cadastrado com sucesso.');
          setTimeout(() => setAlertType(''), 3000);
          clearForm();
          api
            .get('/seat/features')
            .then((response) => setDataList(response.data));
        })
        .catch((error) => alert(error));
    }
    setLoading(false);
  }

  function editData(id, feature) {
    clearForm();
    setIsNewData(false);
    setDataId(id);
    setFeature(feature);
  }

  function searchList() {
    setLoading(true);
    api
      .get(`/seat/features?filter=${searchFilter}`)
      .then((response) => {
        setDataList(response.data);
        setLoading(false);
      })
      .catch(() => {
        setAlertType('alert-danger');
        setAlertTitle('Dados não encontrados');
        setTimeout(() => setAlertType(''), 3000);
        setLoading(false);
      });
  }

  function handleSearchFilterChange(e) {
    setSearchFilter(e);
    if (e.trim().length === 0) {
      api.get('/seat/features').then((response) => {
        setDataList(response.data);
        setTotalList(response.data.length);
      });
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      searchList();
    }
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={alertType ? 'alert ' + alertType : 'hide'} role="alert">
        <h4 className={alertTitle ? '' : 'hide'}>{alertTitle}</h4>
        {alertText}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          { label: 'Aéreo - Assentos', link: PagePath.AdminAssentos },
        ]}
        title={masterTitle}
      />

      <div className="contentAll">
        <div className="row">
          <div className="col">
            <form onSubmit={(e) => validateForm(e)}>
              <div className="row">
                <div className="col-9">
                  <label>Característica</label>
                  <input
                    type="text"
                    value={feature}
                    onChange={(e) => setFeature(e.target.value)}
                    required
                  />
                </div>

                <div className="col-3">
                  <button type="submit">
                    <span className={loading ? 'hide' : ''}>
                      <span className={isNewData ? '' : 'hide'}>
                        <PiCaretRightBold /> Salvar
                      </span>
                      <span className={!isNewData ? '' : 'hide'}>
                        <FiEdit /> Alterar
                      </span>
                    </span>
                    <span className={loading ? 'loader' : 'hide'}></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="contentAll mt-3">
        <div className="row lineButtons">
          <div className="col pl-0">
            <h4>{masterTitle}</h4>
          </div>

          <div className="col btnTable">
            <div className="buscaFiltro">
              <input
                type="text"
                className="inputSearch"
                placeholder="Procurar por"
                value={searchFilter}
                onChange={(e) => handleSearchFilterChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <FaMagnifyingGlass onClick={() => searchList()} />
            </div>
            <div className="rightBtn">
              <div>{dataList.length}</div>
              <div>de</div>
              <div className="listTotal">{totalList}</div>
              <button
                type="button"
                className="btn btnNew"
                onClick={() => clearForm()}
              >
                Novo <PiPlusCircleFill />
              </button>
            </div>
          </div>
        </div>

        <div className="text-sm">
          <table className="tblDefault">
            <thead>
              <tr>
                <th scope="col" className="tdMaster">
                  Características
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => editData(data.id, data.feature)}
                  className={dataId === data.id ? 'trSelect' : ''}
                >
                  <td>{data.feature}</td>
                  <td>
                    <BiSolidEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
