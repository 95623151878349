export function getCityAirport(airports, iata) {
  const airport = airports.find((ap) => ap['iata'] === iata);
  return airport ? `${airport['cidade']}` : '';
}

export function formatAirportTableInformations(airports, iata) {
  const airport = airports.find((airport) => airport.iata === iata);

  if (airport) {
    return `(${iata}) - ${airport.nome} - ${airport.cidade} / ${airport.uf}`;
  }

  return null;
}
