import { useEffect } from 'react';
import AirplaneReservation from '@components/airplane-reservation';

import { ReservationPassenger } from '@components/reservation-passenger';
import { useCreditAllotment } from '@stores/credit-allotment';

export default function AereoReservaCheckout() {
  useEffect(() => {
    useCreditAllotment.getState().methods.getLimits();
  }, []);

  return (
    <div className="flex w-full flex-col rounded-lg bg-white sm:inline-flex  sm:p-4">
      <div className=" flex w-full flex-col ">
        <span className="text-heading-2 text-primary">Quem vai viajar?</span>
        <span className="text-body font-normal">
          Escolha o assento disponível na aeronave e preencha os dados do
          passageiro.
        </span>
      </div>
      <div className="relative mt-8 flex flex-row justify-between gap-4">
        <AirplaneReservation />
        <ReservationPassenger />
      </div>
    </div>
  );
}
