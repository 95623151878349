import { forwardRef } from 'react';
// import '@components/new-components/reset-new-components.scss';

import { tv } from 'tailwind-variants';

const inputStyles = tv({
  base: 'text-sm w-full placeholder-shown:!border-neutral-600 px-3 py-2 h-12 border border-solid rounded-md transition-all outline-neutral-800 focus:ring-inset focus:ring-neutral-800 placeholder:text-sm placeholder:font-normal placeholder:text-neutral-600 placeholder-shown:!placeholder:text-neutral-600 !not-placeholder-shown:border-neutral-800',
  variants: {
    disabled: {
      true: '!bg-neutral-300 !border-none placeholder:!text-neutral-700',
      false: '',
    },
    error: {
      true: '!border-error-900 placeholder-shown:!border-error-900',
      false: 'placeholder-shown:!border-neutral-600',
    },
    iconLeft: {
      true: 'pl-10',
      false: '',
    },
    iconRight: {
      true: 'pr-10',
      false: '',
    },
    placeholderStyle: {
      italic: 'placeholder:italic',
      normal: 'placeholder:not-italic',
    },
  },
  defaultVariants: {
    error: false,
    disabled: false,
  },
});

const Input = forwardRef(
  (
    {
      type,
      hasIconLeft,
      hasIconRight,
      hasError,
      isError,
      className,
      disabled,
      placeholderStyle = 'italic',
      onChange,
      ...props
    },
    ref,
  ) => {
    const inputClass = inputStyles({
      error: hasError,
      iconLeft: hasIconLeft,
      iconRight: hasIconRight,
      disabled: disabled,
      placeholderStyle,
      className,
    });
    return (
      <input
        ref={ref}
        onChange={onChange}
        type={type ?? 'text'}
        className={inputClass}
        disabled={disabled}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
