import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { IoAirplaneOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button } from '@components/button';
import { DropdownListRoutes } from '@components/dropdown-list-routes';
import { InputDate, InputRadio, InputText } from '@components/input';
import IntlCurrencyInput from '@components/intl-currency-input';
import { Modal } from '@components/modal';
import { Form } from '@components/new-components/form';
import PageHeader from '@components/page-header';
import { RouteCard } from '@components/route-card';
import { Select } from '@components/select';
import { Switch } from '@components/switch';
import { Tab } from '@components/tab';
import Tag from '@components/Tag';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { useAuthentication } from '@stores/authentication';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import { inputFormatterPercentage } from '../system/utils/inputFormatter';

export default function AereoSaidasCadastrar() {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const { session } = useAuthentication();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [canChange, setCanChange] = useState('');
  const [withCost, setWithCost] = useState('');
  const [changeCost, setChangeCost] = useState();
  const [limitDay, setLimitDay] = useState();

  const [canCancel, setCanCancel] = useState('');
  const [withRefund, setWithRefund] = useState('');
  const [withAditionalCost, setWithAditionalCost] = useState('');
  const [withDueDate, setWithDueDate] = useState('');
  const [cancelRefundPercentageAfter, setCancelRefundPercentageAfter] =
    useState();
  const [cancelLimitDay, setCancelLimitDay] = useState();
  const [cancelCostPercentage, setCancelCostPercentage] = useState();
  const [cancelRefundPercentage, setCancelRefundPercentage] = useState();

  const [dataBaseRotasOrigem, setDataBaseRotasOrigem] = useState([]);
  const { methods } = useAirports();

  async function getRoutesInformations() {
    setLoading(true);
    try {
      const { data } = await api.get('/charters/get/informations/routes/items');
      setDataBaseRotasOrigem(data);
    } catch (error) {
      toaster('error', 'Não foi possível carregar as rotas');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRoutesInformations();
    methods.getAirports();
  }, []);

  const today = new Date();

  const [departureOrigin, setDepartureOrigin] = useState('');
  const [returnOrigin, setReturnOrigin] = useState('');
  const [currentIndex, setIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState({
    opened: false,
    isOnlyOneExcerpt: false,
  });

  const handleChange = (newIndex) => setIndex(newIndex);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      quantity: 0,
      active: true,
    },
  });
  const {
    control: controlOnlyOneExcerpt,
    handleSubmit: handleSubmitOnlyOneExcerpt,
    setValue: setValueOnlyOneExcerpt,
  } = useForm({
    defaultValues: {
      quantityOnlyOneExcerpt: 0,
      activeOnlyOneExcerpt: true,
    },
  });

  const quantity = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => {
        return {
          value: index,
          label: index,
        };
      }),
    [],
  );

  const handleCancelLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCancelLimitDay(value);
  };

  const handleChangeCost = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setChangeCost(value / 100);
  };

  const handleLimitDay = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setLimitDay(value);
    return value;
  };

  const parsePercentage = (value) => Number(value?.replace('%', ''));

  const buildCancelRulesData = ({
    hasRefund,
    hasDueDate,
    withAditionalCost,
    cancelCostPercentage,
    cancelRefundPercentage,
    cancelLimitDay,
    cancelRefundPercentageAfter,
  }) => {
    const cancelRulesData = {
      cancelWithCost:
        withAditionalCost === 'Y' && !hasRefund && cancelCostPercentage,
      cancelWithRefund: hasRefund,
      refundWithDeadline: hasRefund && hasDueDate,
    };

    if (!hasRefund && cancelCostPercentage) {
      cancelRulesData.cancelCostPercentage =
        parsePercentage(cancelCostPercentage);
    }

    if (hasRefund && cancelRefundPercentage) {
      cancelRulesData.refundPercentage = parsePercentage(
        cancelRefundPercentage,
      );
    }

    if (hasRefund && hasDueDate && cancelLimitDay) {
      cancelRulesData.refundDaysLimit = cancelLimitDay;
    }

    if (hasRefund && hasDueDate && cancelRefundPercentageAfter) {
      cancelRulesData.refundPercentageDeadline = parsePercentage(
        cancelRefundPercentageAfter,
      );
    }

    return cancelRulesData;
  };

  function handleSubmitDepartureAndReturn(payload) {
    const selectedDepartureDate = dayjs(payload.departureDate);
    const selectedReturnDate = dayjs(payload.returnDate);

    if (selectedDepartureDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    } else if (new Date(selectedReturnDate) < selectedDepartureDate) {
      return toaster(
        'warning',
        'A data de volta não pode ser menor que a data de ida.',
      );
    }

    let errors = {};
    if (canChange == '' || (canChange == 'Y' && withCost == '')) {
      errors.change = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canChange == 'Y') {
        if (
          (withCost == 'Y' && !changeCost) ||
          (withCost == 'N' && (!changeCost || !limitDay))
        ) {
          errors.change = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (canCancel == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (canCancel == 'Y' && withRefund == '') {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else if (
      canCancel == 'Y' &&
      ((withRefund == 'Y' && withDueDate == '') ||
        (withRefund == 'N' && withAditionalCost == ''))
    ) {
      errors.cancel = {
        message: 'Campo obrigatório',
      };
    } else {
      if (canCancel == 'Y') {
        if (
          (withAditionalCost == 'Y' && !cancelCostPercentage) ||
          (withRefund == 'Y' &&
            withDueDate == 'Y' &&
            (!cancelRefundPercentage ||
              !cancelLimitDay ||
              !cancelRefundPercentageAfter)) ||
          (withRefund == 'Y' && withDueDate == 'N' && !cancelRefundPercentage)
        ) {
          errors.cancel = {
            message: 'Campo obrigatório',
          };
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    const createCharter = async () => {
      const toCreate = {
        outboundRouteId: payload.departureRoute.id_rota,
        outboundDate: selectedDepartureDate,
        returnRouteId: payload.returnRoute.id_rota,
        returnDate: selectedReturnDate,
        agencyId: session.agency.id,
        baggage: `${payload?.quantity}-${payload?.pound ?? ''}`,
        status: payload.active ? 1 : 0,
      };
      try {
        const response = await api.post('/charters', toCreate);

        if (response.status === 201) {
          const idfret = response.data.id;
          if (canChange === 'Y') {
            const changeRulesData = {
              changeWithCost: withCost === 'Y',
              changeCostValue: changeCost,
              changeLimitDays: limitDay,
            };
            await api.patch(`charters/${idfret}/change-rules`, changeRulesData);
          }

          if (canCancel === 'Y') {
            const cancelRulesData = buildCancelRulesData({
              hasRefund: withRefund === 'Y',
              hasDueDate: withDueDate === 'Y',
              withAditionalCost,
              cancelCostPercentage,
              cancelRefundPercentage,
              cancelLimitDay,
              cancelRefundPercentageAfter,
            });

            await api.patch(`charters/${idfret}/cancel-rules`, cancelRulesData);
          }

          toaster('success', 'Saída cadastrada com sucesso!');

          setTimeout(() => {
            navigate(PagePath.AereoSaidasPrecificar.replace(':id', idfret));
          }, 2000);
        }
      } catch (error) {
        console.error(`Erro ao cadastrar saída: ${error.message}`);
        alert(error.message);
      }
    };

    createCharter();
  }

  function submitOnlyOneExcerpt(payload) {
    const selectedExcerptDate = dayjs(payload.excerptDate).add('1', 'day');

    if (selectedExcerptDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    }

    const outboundDate = dayjs(payload.excerptDate);
    const createCharter = async () => {
      try {
        const toCreate = {
          outboundRouteId: payload.excerptRoute.id_rota,
          outboundDate,
          agencyId: session.agency.id,
          baggage: `${payload.quantityOnlyOneExcerpt}-${payload.poundOnlyOneExcerpt}`,
          status: payload.activeOnlyOneExcerpt ? 1 : 0,
        };

        const response = await api.post('/charters', toCreate);

        if (response.status === 201) {
          const idfret = response.data.id;
          if (canChange === 'Y') {
            const changeRulesData = {
              changeWithCost: withCost === 'Y',
              changeCostValue: changeCost,
              changeLimitDays: limitDay,
            };
            await api.patch(`charters/${idfret}/change-rules`, changeRulesData);
          }

          if (canCancel === 'Y') {
            const cancelRulesData = buildCancelRulesData({
              hasRefund: withRefund === 'Y',
              hasDueDate: withDueDate === 'Y',
              withAditionalCost,
              cancelCostPercentage,
              cancelRefundPercentage,
              cancelLimitDay,
              cancelRefundPercentageAfter,
            });
            await api.patch(`charters/${idfret}/cancel-rules`, cancelRulesData);
          }

          toaster('success', 'Saída cadastrada com sucesso!');

          setTimeout(() => {
            navigate(PagePath.AereoSaidasPrecificar.replace(':id', idfret));
          }, 2000);
        }
      } catch (error) {
        console.error(`Erro ao cadastrar saída: ${error.message}`);
        alert(error.message);
      }
    };

    createCharter();
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  const confirmInactivate = () => {
    if (modalOpen.isOnlyOneExcerpt) {
      setValueOnlyOneExcerpt('activeOnlyOneExcerpt', false);
    } else {
      setValue('active', false);
    }
    setModalOpen({
      opened: false,
      isOnlyOneExcerpt: modalOpen.isOnlyOneExcerpt,
    });
  };

  function handleChangeStatus(checked, isOnlyOne) {
    if (checked) {
      setModalOpen({ opened: true, isOnlyOneExcerpt: isOnlyOne });
    } else if (isOnlyOne) {
      return setValueOnlyOneExcerpt('activeOnlyOneExcerpt', true);
    } else {
      return setValue('active', true);
    }
  }

  return (
    <div>
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Saídas',
            link: PagePath.AereoSaidas,
          },
          {
            label: 'Cadastro de saídas',
            link: PagePath.AereoSaidasCadastrar,
          },
        ]}
        title="Cadastro de saídas"
      />
      <div className="flex flex-col gap-4">
        <div className="w-full rounded-md bg-white">
          <Tab value={currentIndex} onChange={handleChange}>
            <Tab.Heads>
              <Tab.Item label="Ida e volta" index={0} />
              <Tab.Item label="Somente 1 trecho" index={1} />
            </Tab.Heads>
            <Tab.ContentWrapper>
              <Tab.Content index={0}>
                <form
                  onSubmit={handleSubmit(handleSubmitDepartureAndReturn)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Rota de ida
                        </div>
                      }
                    />
                    <Controller
                      name="departureRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="departureDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de ida"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            type="date"
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          {' '}
                          <IoAirplaneOutline className="flip" /> Rota de volta
                        </div>
                      }
                    />
                    <Controller
                      name="returnRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={returnOrigin}
                            label="Rota"
                            onChange={(text) => setReturnOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="returnDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de volta"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantity"
                          control={control}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="pound"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-4 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <div className=" flex flex-col gap-5">
                      <span className="text-base font-semibold text-primary">
                        Regras de alteração
                      </span>
                      <div className="flex w-full gap-4">
                        <label className="m-0">Poderá alterar?</label>
                        <div className="flex gap-3">
                          <InputRadio
                            label="Sim"
                            checked={canChange == 'Y'}
                            onChange={() => setCanChange('Y')}
                          />
                          <InputRadio
                            label="Não"
                            checked={canChange == 'N'}
                            onChange={() => setCanChange('N')}
                          />
                        </div>
                      </div>
                      {canChange == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com custo adicional?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withCost == 'Y'}
                              onChange={() => setWithCost('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withCost == 'N'}
                              onChange={() => setWithCost('N')}
                            />
                          </div>
                        </div>
                      )}
                      {withCost == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label>Custo de</label>
                          <div className="w-[120px]">
                            <IntlCurrencyInput
                              currency="BRL"
                              config={currencyConfig}
                              placeholder="Valor em R$"
                              className="currency mt-1 text-sm"
                              error={!changeCost && errors?.change?.message}
                              value={changeCost}
                              onChange={handleChangeCost}
                            />
                          </div>
                        </div>
                      )}
                      {withCost == 'N' && (
                        <div className="flex w-full gap-4">
                          <label>Até</label>
                          <div className="w-[140px]">
                            <InputText
                              placeholder="Quantos dias?"
                              error={!limitDay && errors?.change?.message}
                              isErrorMessage={false}
                              className="mt-1"
                              value={limitDay}
                              onChange={handleLimitDay}
                            />
                          </div>
                          <div className="w-[360px]">
                            <label>
                              dias antes da data de partida. Antes com custo de
                            </label>
                          </div>
                          <div className="w-[150px]">
                            <IntlCurrencyInput
                              currency="BRL"
                              config={currencyConfig}
                              placeholder="Valor em R$"
                              className="currency mt-1 text-sm"
                              error={!changeCost && errors?.change?.message}
                              isErrorMessage={false}
                              value={changeCost}
                              onChange={handleChangeCost}
                            />
                          </div>
                        </div>
                      )}
                      {console.log(errors?.change?.message)}
                    </div>
                    {errors?.change && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.change} />
                      </div>
                    )}
                  </div>

                  <div className="flex w-full flex-col gap-4 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <div className="flex w-full flex-col gap-5">
                      <span className="text-base font-semibold text-primary">
                        Regras de cancelamento
                      </span>
                      <div className="flex w-full gap-4">
                        <label className="m-0">Poderá cancelar?</label>
                        <div className="flex gap-3">
                          <InputRadio
                            label="Sim"
                            checked={canCancel == 'Y'}
                            onChange={() => setCanCancel('Y')}
                          />
                          <InputRadio
                            label="Não"
                            checked={canCancel == 'N'}
                            onChange={() => setCanCancel('N')}
                          />
                        </div>
                      </div>
                      {canCancel == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com reembolso?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withRefund == 'Y'}
                              onChange={() => setWithRefund('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withRefund == 'N'}
                              onChange={() => setWithRefund('N')}
                            />
                          </div>
                        </div>
                      )}
                      {canCancel == 'Y' && withRefund == 'N' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com custo adicional?</label>
                          <div className="flex gap-2">
                            <InputRadio
                              label="Sim"
                              checked={withAditionalCost == 'Y'}
                              onChange={() => setWithAditionalCost('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withAditionalCost == 'N'}
                              onChange={() => setWithAditionalCost('N')}
                            />
                          </div>
                        </div>
                      )}
                      {canCancel == 'Y' &&
                        withRefund == 'N' &&
                        withAditionalCost == 'Y' && (
                          <div className="flex w-full gap-4">
                            <div className="w-[170px]">
                              <label>Com custo adicional de</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                value={cancelCostPercentage}
                                error={
                                  !cancelCostPercentage &&
                                  errors?.cancel?.message
                                }
                                isErrorMessage={false}
                                onChange={(event) => {
                                  const value = inputFormatterPercentage({
                                    value: event.target.value,
                                    isDelete:
                                      event.nativeEvent.inputType ===
                                      'deleteContentBackward',
                                  });
                                  setCancelCostPercentage(value);
                                }}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>do valor da tarifa.</label>
                            </div>
                          </div>
                        )}

                      {canCancel == 'Y' && withRefund == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com data limite?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withDueDate == 'Y'}
                              onChange={() => setWithDueDate('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withDueDate == 'N'}
                              onChange={() => setWithDueDate('N')}
                            />
                          </div>
                        </div>
                      )}

                      {canCancel == 'Y' &&
                        withRefund == 'Y' &&
                        withDueDate == 'N' && (
                          <div className="flex w-full gap-4">
                            <div className="w-[115px]">
                              <label>Reembolso de</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                error={
                                  !cancelRefundPercentage &&
                                  errors?.cancel?.message
                                }
                                isErrorMessage={false}
                                value={cancelRefundPercentage}
                                onChange={(event) => {
                                  const value = inputFormatterPercentage({
                                    value: event.target.value,
                                    isDelete:
                                      event.nativeEvent.inputType ===
                                      'deleteContentBackward',
                                  });
                                  setCancelRefundPercentage(value);
                                }}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>do valor da tarifa.</label>
                            </div>
                          </div>
                        )}

                      {canCancel == 'Y' &&
                        withRefund == 'Y' &&
                        withDueDate == 'Y' && (
                          <>
                            <div className="flex w-full gap-4">
                              <div className="w-[115px]">
                                <label>Reembolso de</label>
                              </div>
                              <div className="w-[90px]">
                                <InputText
                                  placeholder="Ex: 10%"
                                  className="mt-1"
                                  value={cancelRefundPercentage}
                                  error={
                                    !cancelRefundPercentage &&
                                    errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  onChange={(event) => {
                                    const value = inputFormatterPercentage({
                                      value: event.target.value,
                                      isDelete:
                                        event.nativeEvent.inputType ===
                                        'deleteContentBackward',
                                    });
                                    setCancelRefundPercentage(value);
                                  }}
                                />
                              </div>
                              <div className="w-[150px]">
                                <label>do valor da tarifa até </label>
                              </div>
                              <div className="w-[140px]">
                                <InputText
                                  placeholder="Quantos dias?"
                                  error={
                                    !cancelLimitDay && errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  className="mt-1"
                                  value={cancelLimitDay}
                                  onChange={handleCancelLimitDay}
                                />
                              </div>
                              <div className="w-[230px]">
                                <label>dias antes da data de partida. </label>
                              </div>
                            </div>
                            <div className="flex w-full gap-4">
                              <div className="w-[250px]">
                                <label>Após, será reembolsado soemente</label>
                              </div>
                              <div className="w-[90px]">
                                <InputText
                                  placeholder="Ex: 10%"
                                  className="mt-1"
                                  error={
                                    !cancelRefundPercentageAfter &&
                                    errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  value={cancelRefundPercentageAfter}
                                  onChange={(event) => {
                                    const value = inputFormatterPercentage({
                                      value: event.target.value,
                                      isDelete:
                                        event.nativeEvent.inputType ===
                                        'deleteContentBackward',
                                    });
                                    setCancelRefundPercentageAfter(value);
                                  }}
                                />
                              </div>
                              <div className="w-[150px]">
                                <label>da tarifa. </label>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    {errors?.cancel && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.cancel} />
                      </div>
                    )}
                  </div>

                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, false)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
              <Tab.Content index={1}>
                <form
                  onSubmit={handleSubmitOnlyOneExcerpt(submitOnlyOneExcerpt)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Trecho
                        </div>
                      }
                    />
                    <Controller
                      name="excerptRoute"
                      control={controlOnlyOneExcerpt}
                      render={({ field }) =>
                        field.value ? (
                          <>
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="excerptDate"
                        control={controlOnlyOneExcerpt}
                        render={({ field }) => (
                          <InputDate
                            label="Data do trecho"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantityOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="poundOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-4 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <div className=" flex flex-col gap-5">
                      <span className="text-base font-semibold text-primary">
                        Regras de alteração
                      </span>
                      <div className="flex w-full gap-4">
                        <label className="m-0">Poderá alterar?</label>
                        <div className="flex gap-3">
                          <InputRadio
                            label="Sim"
                            checked={canChange == 'Y'}
                            onChange={() => setCanChange('Y')}
                          />
                          <InputRadio
                            label="Não"
                            checked={canChange == 'N'}
                            onChange={() => setCanChange('N')}
                          />
                        </div>
                      </div>
                      {canChange == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com custo adicional?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withCost == 'Y'}
                              onChange={() => setWithCost('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withCost == 'N'}
                              onChange={() => setWithCost('N')}
                            />
                          </div>
                        </div>
                      )}
                      {withCost == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label>Custo de</label>
                          <div className="w-[120px]">
                            <IntlCurrencyInput
                              currency="BRL"
                              config={currencyConfig}
                              placeholder="Valor em R$"
                              className="currency mt-1 text-sm"
                              error={!changeCost && errors?.change?.message}
                              value={changeCost}
                              onChange={handleChangeCost}
                            />
                          </div>
                        </div>
                      )}
                      {withCost == 'N' && (
                        <div className="flex w-full gap-4">
                          <label>Até</label>
                          <div className="w-[140px]">
                            <InputText
                              placeholder="Quantos dias?"
                              error={!limitDay && errors?.change?.message}
                              isErrorMessage={false}
                              className="mt-1"
                              value={limitDay}
                              onChange={handleLimitDay}
                            />
                          </div>
                          <div className="w-[360px]">
                            <label>
                              dias antes da data de partida. Antes com custo de
                            </label>
                          </div>
                          <div className="w-[150px]">
                            <IntlCurrencyInput
                              currency="BRL"
                              config={currencyConfig}
                              placeholder="Valor em R$"
                              className="currency mt-1 text-sm"
                              error={!changeCost && errors?.change?.message}
                              isErrorMessage={false}
                              value={changeCost}
                              onChange={handleChangeCost}
                            />
                          </div>
                        </div>
                      )}
                      {console.log(errors?.change?.message)}
                    </div>
                    {errors?.change && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.change} />
                      </div>
                    )}
                  </div>

                  <div className="flex w-full flex-col gap-4 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <div className="flex w-full flex-col gap-5">
                      <span className="text-base font-semibold text-primary">
                        Regras de cancelamento
                      </span>
                      <div className="flex w-full gap-4">
                        <label className="m-0">Poderá cancelar?</label>
                        <div className="flex gap-3">
                          <InputRadio
                            label="Sim"
                            checked={canCancel == 'Y'}
                            onChange={() => setCanCancel('Y')}
                          />
                          <InputRadio
                            label="Não"
                            checked={canCancel == 'N'}
                            onChange={() => setCanCancel('N')}
                          />
                        </div>
                      </div>
                      {canCancel == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com reembolso?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withRefund == 'Y'}
                              onChange={() => setWithRefund('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withRefund == 'N'}
                              onChange={() => setWithRefund('N')}
                            />
                          </div>
                        </div>
                      )}
                      {canCancel == 'Y' && withRefund == 'N' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com custo adicional?</label>
                          <div className="flex gap-2">
                            <InputRadio
                              label="Sim"
                              checked={withAditionalCost == 'Y'}
                              onChange={() => setWithAditionalCost('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withAditionalCost == 'N'}
                              onChange={() => setWithAditionalCost('N')}
                            />
                          </div>
                        </div>
                      )}
                      {canCancel == 'Y' &&
                        withRefund == 'N' &&
                        withAditionalCost == 'Y' && (
                          <div className="flex w-full gap-4">
                            <div className="w-[170px]">
                              <label>Com custo adicional de</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                value={cancelCostPercentage}
                                error={
                                  !cancelCostPercentage &&
                                  errors?.cancel?.message
                                }
                                isErrorMessage={false}
                                onChange={(event) => {
                                  const value = inputFormatterPercentage({
                                    value: event.target.value,
                                    isDelete:
                                      event.nativeEvent.inputType ===
                                      'deleteContentBackward',
                                  });
                                  setCancelCostPercentage(value);
                                }}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>do valor da tarifa.</label>
                            </div>
                          </div>
                        )}

                      {canCancel == 'Y' && withRefund == 'Y' && (
                        <div className="flex w-full gap-4">
                          <label className="m-0">Com data limite?</label>
                          <div className="flex gap-3">
                            <InputRadio
                              label="Sim"
                              checked={withDueDate == 'Y'}
                              onChange={() => setWithDueDate('Y')}
                            />
                            <InputRadio
                              label="Não"
                              checked={withDueDate == 'N'}
                              onChange={() => setWithDueDate('N')}
                            />
                          </div>
                        </div>
                      )}

                      {canCancel == 'Y' &&
                        withRefund == 'Y' &&
                        withDueDate == 'N' && (
                          <div className="flex w-full gap-4">
                            <div className="w-[115px]">
                              <label>Reembolso de</label>
                            </div>
                            <div className="w-[90px]">
                              <InputText
                                placeholder="Ex: 10%"
                                className="mt-1"
                                error={
                                  !cancelRefundPercentage &&
                                  errors?.cancel?.message
                                }
                                isErrorMessage={false}
                                value={cancelRefundPercentage}
                                onChange={(event) => {
                                  const value = inputFormatterPercentage({
                                    value: event.target.value,
                                    isDelete:
                                      event.nativeEvent.inputType ===
                                      'deleteContentBackward',
                                  });
                                  setCancelRefundPercentage(value);
                                }}
                              />
                            </div>
                            <div className="w-[150px]">
                              <label>do valor da tarifa.</label>
                            </div>
                          </div>
                        )}

                      {canCancel == 'Y' &&
                        withRefund == 'Y' &&
                        withDueDate == 'Y' && (
                          <>
                            <div className="flex w-full gap-4">
                              <div className="w-[115px]">
                                <label>Reembolso de</label>
                              </div>
                              <div className="w-[90px]">
                                <InputText
                                  placeholder="Ex: 10%"
                                  className="mt-1"
                                  value={cancelRefundPercentage}
                                  error={
                                    !cancelRefundPercentage &&
                                    errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  onChange={(event) => {
                                    const value = inputFormatterPercentage({
                                      value: event.target.value,
                                      isDelete:
                                        event.nativeEvent.inputType ===
                                        'deleteContentBackward',
                                    });
                                    setCancelRefundPercentage(value);
                                  }}
                                />
                              </div>
                              <div className="w-[150px]">
                                <label>do valor da tarifa até </label>
                              </div>
                              <div className="w-[140px]">
                                <InputText
                                  placeholder="Quantos dias?"
                                  error={
                                    !cancelLimitDay && errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  className="mt-1"
                                  value={cancelLimitDay}
                                  onChange={handleCancelLimitDay}
                                />
                              </div>
                              <div className="w-[230px]">
                                <label>dias antes da data de partida. </label>
                              </div>
                            </div>
                            <div className="flex w-full gap-4">
                              <div className="w-[250px]">
                                <label>Após, será reembolsado soemente</label>
                              </div>
                              <div className="w-[90px]">
                                <InputText
                                  placeholder="Ex: 10%"
                                  className="mt-1"
                                  error={
                                    !cancelRefundPercentageAfter &&
                                    errors?.cancel?.message
                                  }
                                  isErrorMessage={false}
                                  value={cancelRefundPercentageAfter}
                                  onChange={(event) => {
                                    const value = inputFormatterPercentage({
                                      value: event.target.value,
                                      isDelete:
                                        event.nativeEvent.inputType ===
                                        'deleteContentBackward',
                                    });
                                    setCancelRefundPercentageAfter(value);
                                  }}
                                />
                              </div>
                              <div className="w-[150px]">
                                <label>da tarifa. </label>
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    {errors?.cancel && (
                      <div className="flex w-full gap-4">
                        <Form.Message error={errors?.cancel} />
                      </div>
                    )}
                  </div>

                  <Controller
                    name="activeOnlyOneExcerpt"
                    control={controlOnlyOneExcerpt}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, true)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
            </Tab.ContentWrapper>
          </Tab>
        </div>
      </div>
      <Modal open={modalOpen.opened}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar essa saída?
          </span>
          <p className="mb-[30px] mt-[15px] text-center text-heading-4 text-neutral-800">
            Ao inativar essa saída, ela será ocultada da listagem de
            disponibilidades de reservas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen((prev) => !prev)}
            />
            <Button label="Inativar saída" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
