/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { BiX } from 'react-icons/bi';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

const modalBodyStyle = tv({
  base: 'z-50 flex h-max flex-col items-center justify-center rounded-md bg-white shadow-md',
  variants: {
    size: {
      small: 'w-1/3',
      medium: 'w-1/2',
      big: 'w-3/4',
      default: 'w-2/3',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const Modal = forwardRef(
  (
    { open, handleClose, children, className, size, customPadding, customGap },
    ref,
  ) => {
    const handleContentClick = (event) => {
      event.stopPropagation();
    };

    return (
      <dialog
        ref={ref}
        open={open}
        className="z-30 transition-transform duration-300"
      >
        <div
          onClick={handleClose}
          className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-slate-600/30"
        >
          <div
            className={twMerge(
              modalBodyStyle({ className, size }),
              customPadding ? customPadding : 'px-[30px] py-[30px]',
              customGap ? customGap : 'gap-4',
            )}
            onClick={handleContentClick}
          >
            {children}
          </div>
        </div>
      </dialog>
    );
  },
);

Modal.Header = ({ title, handleClose, size = '' }) => {
  return (
    <div className="flex w-full justify-between border-x-0 border-b border-t-0 border-solid border-neutral-300">
      <h2
        className={`${size} text-heading-1 text-xl font-semibold text-primary`}
      >
        {title}
      </h2>
      <BiX
        size={27}
        onClick={handleClose}
        className="cursor-pointer hover:text-secondary"
      />
    </div>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  customPadding: PropTypes.string,
  customGap: PropTypes.string,
};

export { Modal };
