import { forwardRef, useId } from 'react';

const InputCheckbox = forwardRef(
  ({ label, className, disabled, checked, ...props }, ref) => {
    const id = useId();
    return (
      <div className="flex space-x-2.5">
        <input
          {...props}
          id={id}
          className={`size-5 accent-secondary ${className}`}
          ref={ref}
          type="checkbox"
          disabled={disabled}
          checked={checked}
        />
        {label && (
          <label
            className={`m-0 text-body ${props.checked ? 'font-semibold text-neutral-800' : 'font-normal text-neutral-800'}`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>
    );
  },
);

InputCheckbox.displayName = 'InputCheckbox';
export { InputCheckbox };
