import Globals from '../components/Globals';
import '../components/BoxCliente.css';
import { MdOutlineChair } from 'react-icons/md';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { useEffect, useState } from 'react';
import { TbAlertTriangle } from 'react-icons/tb';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ALLOTMENT_WARNING_THRESHOLD = 0.85;
const CREDIT_WARNING_THRESHOLD = 0.85;

function formatCurrency(e) {
  let f = Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(e);
  return f;
}

function formatPercente(v) {
  let res = v * 100;

  if (res > 100) {
    res = '100';
  }
  return res + '%';
}

function formatVencimento() {
  let now = new Date();
  let dia = ('' + now.getDate()).slice(-2);
  let mes = ('' + (now.getMonth() + 1)).slice(-2);
  let diaVencimento = Globals.userAllotmentVencimentoAgencia;
  if (Number(dia) > Number(diaVencimento)) {
    if (Number(mes) < 12) {
      mes = Number(mes) + 1;
    } else {
      mes = '1';
    }
  }

  if (Number(diaVencimento) < 10) {
    diaVencimento = '0' + diaVencimento;
  }
  if (Number(mes) < 10) {
    mes = '0' + mes;
  }
  return diaVencimento + '/' + mes;
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

function BoxCliente() {
  const percentOfUsedAllotment = clamp(
    Globals.userAllotmentUsadoAgencia / Globals.userAllotmentAgencia,
    0,
    1,
  );
  const percentOfUsedCredit = clamp(
    Globals.userCreditoUsadoAgencia / Globals.userCreditoAgencia,
    0,
    1,
  );

  const [showBox, setShowBox] = useState(true);
  const [allotmentsRemaining, setAllotmentsRemaining] = useState(0);
  const [creditsRemaining, setCreditsRemaining] = useState(0);
  const [showAllotmentWarning, setShowAllotmentWarning] = useState(false);
  const [showCreditWarning, setShowCreditWarning] = useState(false);

  useEffect(() => {
    const allotmentsRemaining =
      Globals.userAllotmentAgencia - Globals.userAllotmentUsadoAgencia;
    setAllotmentsRemaining(Math.max(allotmentsRemaining, 0));
    const creditsRemaining =
      Globals.userCreditoAgencia - Globals.userCreditoUsadoAgencia;
    setCreditsRemaining(Math.max(creditsRemaining, 0));
  }, []);

  useEffect(() => {
    setShowAllotmentWarning(
      percentOfUsedAllotment >= ALLOTMENT_WARNING_THRESHOLD,
    );
    setShowCreditWarning(percentOfUsedCredit >= CREDIT_WARNING_THRESHOLD);
  }, [percentOfUsedAllotment, percentOfUsedCredit]);

  const toggleBox = () => {
    setShowBox((oldValue) => !oldValue);
  };

  return (
    <div className="boxCliente row !m-0">
      <div className="toggleBox" onClick={toggleBox}>
        {showBox ? <PiCaretUpBold /> : <PiCaretDownBold />}
      </div>
      <div
        className={
          'agenciaCliente row ' + (showBox ? 'agenciaClientVisible' : '')
        }
      >
        <div className="col">
          <h5 className="m-0">{Globals.userNameAgencia}</h5>
        </div>
      </div>

      {showBox && (
        <div id="toggleBoxTable" className="allotment col">
          <div className="allotmentItem">
            <div>
              <MdOutlineChair className="allotmentIcon" />
            </div>
            <div className="allotmentItemContent">
              <div className="allotmentItemContentData">
                <span>Seu Allotment</span>
                <div>
                  {Globals.userAllotmentUsadoAgencia}/
                  {Globals.userAllotmentAgencia}
                  <small> (válidos até {formatVencimento()})</small>
                  {showAllotmentWarning && (
                    <span>
                      <TbAlertTriangle />
                    </span>
                  )}
                </div>
              </div>
              <div className="progressbar">
                <div
                  className="progressAllotment"
                  style={{ width: formatPercente(percentOfUsedAllotment) }}
                ></div>
              </div>
            </div>
          </div>

          <div className="allotmentItem">
            <div>
              <RiMoneyDollarCircleLine className="creditIcon" />
            </div>
            <div className="allotmentItemContent">
              <div className="allotmentItemContentData">
                <span>Seus Créditos</span>
                <div>
                  {formatCurrency(Globals.userCreditoUsadoAgencia)} /{' '}
                  {formatCurrency(Globals.userCreditoAgencia)}
                  {showCreditWarning && (
                    <span>
                      <TbAlertTriangle />
                    </span>
                  )}
                </div>
              </div>
              <div className="progressbar">
                <div
                  className="progressCredit"
                  style={{ width: formatPercente(percentOfUsedCredit) }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default BoxCliente;
